export enum UserDataActions {
  fetchAsyncUserTasks = '[UserData]: fetch async user tasks',
  fetchAsyncUserTasksSuccess = '[UserData]: fetch async user tasks success',
  fetchAsyncUserTasksFailure = '[UserData]: fetch async user tasks failure',
  updateAsyncUserTask = '[UserData]: update async user task',
  deleteAsyncUserTask = '[UserData]: delete async user task',
  deleteAsyncUserTaskSuccess = '[UserData]: delete async user task success',
  deleteAsyncUserTaskFailure = '[UserData]: delete async user task failure',
  fetchAllRolesActive = '[UserData]: fetch all roles active',
  fetchAllRolesActiveSuccess = '[UserData]: fetch all roles active success',
  fetchAllRolesActiveFailure = '[UserData]: fetch all roles active failure'
}
