import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TabsActions from '~/repositories/tabs/store/tabs.actions';
import { NewTabEntity, SharedWithGroup, TabOrder } from '~/repositories/tabs/store/tabs.models';
import * as TabsSelectors from '~/repositories/tabs/store/tabs.selectors';
import { WidgetConfigEntity } from '~/repositories/widgets/store/widgets.models';

@Injectable()
export class TabsFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(TabsSelectors.selectTabsLoaded));
  allTabs$ = this.store.pipe(select(TabsSelectors.selectAllTabs));
  emptyTabs$ = this.store.pipe(select(TabsSelectors.selectEmptyTabs));
  selectedTab$ = this.store.pipe(select(TabsSelectors.selectEntity));
  addTabLoading$ = this.store.pipe(select(TabsSelectors.selectAddTabLoading));
  updateOrderLoading$ = this.store.pipe(select(TabsSelectors.selectUpdateTabsOrderLoading));
  updateSharedWithLoading$ = this.store.pipe(select(TabsSelectors.selectUpdateTabSharedWithLoading));
  editModeEnabled$ = this.store.pipe(select(TabsSelectors.selectEditModeEnabled));
  tabTitleUpdateLoading$ = this.store.pipe(select(TabsSelectors.selectTabTitleUpdateLoading));
  removeTabLoading$ = this.store.pipe(select(TabsSelectors.selectRemoveTabLoading));
  moveWidgetLoading$ = this.store.pipe(select(TabsSelectors.selectMoveWidgetLoading));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(userId: string) {
    this.store.dispatch(TabsActions.initTabs({ userId }));
  }

  selectTab(tabId: string) {
    this.store.dispatch(TabsActions.selectTab({ tabId }));
  }

  saveTab(tab: NewTabEntity) {
    this.store.dispatch(TabsActions.saveTab({ tab }));
  }

  updateTabsOrder(tabsOrder: TabOrder[]) {
    this.store.dispatch(TabsActions.updateTabsOrder({ tabsOrder }));
  }

  updateTabSharedWith(groups: SharedWithGroup[], tabId: string) {
    this.store.dispatch(TabsActions.updateTabSharedWith({ groups, tabId }));
  }

  toggleEditMode(enabled: boolean) {
    this.store.dispatch(TabsActions.setEditMode({ enabled }));
  }

  updateTabTitle(title: string) {
    this.store.dispatch(TabsActions.updateTabTitle({ title }));
  }

  addNewWidget(widgetConfig: WidgetConfigEntity) {
    this.store.dispatch(TabsActions.addNewWidget({ widgetConfig }));
  }

  moveWidget(widgetId: string, tabId: string) {
    this.store.dispatch(TabsActions.moveWidget({ widgetId, tabId }));
  }

  removeWidgetFromTab(widgetId: string) {
    this.store.dispatch(TabsActions.removeWidgetFromTab({ widgetId }));
  }

  removeTab(tabId: string) {
    this.store.dispatch(TabsActions.removeTab({ tabId }));
  }
}
