import { Component, Input } from '@angular/core';
import { UrlSegment, UrlSerializer } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-tiered-menu-button',
  templateUrl: './tiered-menu-button.component.html',
  styleUrls: ['./tiered-menu-button.component.scss']
})
export class TieredMenuButtonComponent {
  @Input() label: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() buttonClass: string;
  @Input() textClass: string;

  @Input() set menuItems(items: MenuItem[]) {
    this.tieredMenuItems = items.map((item) => {
      if (item.routerLink) {
        const decodedUrl = this.decodeUrl(item.routerLink);
        return {
          ...item,
          routerLink: decodedUrl.segments,
          queryParams: decodedUrl.queryParams
        };
      } else {
        return item;
      }
    });
  }

  public tieredMenuItems: MenuItem[] = [];

  constructor(private urlSerializer: UrlSerializer) {}

  decodeUrl = (path: string) => {
    if (!path) return null;

    const urlTree = this.urlSerializer.parse(path);
    const segments = urlTree.root.children['primary'].segments.reduce((acc, curr: UrlSegment) => {
      acc.push(curr.path);
      return acc;
    }, []);
    const queryParams = urlTree.queryParams;
    return { segments: ['/', ...segments], queryParams };
  };
}
