import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';

import { ProgressBarComponent } from './voting/dialogs/voting-dialog/progress-bar/progress-bar.component';
import { VotingDialogComponent } from './voting/dialogs/voting-dialog/voting-dialog.component';
import { VotingResultDialogComponent } from './voting/dialogs/voting-result-dialog/voting-result-dialog.component';
import { VotingComponent } from './voting/voting.component';
import { VotingService } from './voting.service';

import { SharedModule } from '~/shared/shared.module';

@NgModule({
  providers: [VotingService],
  declarations: [VotingComponent, VotingDialogComponent, VotingResultDialogComponent, ProgressBarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,

    ButtonModule,
    SelectButtonModule,
    ProgressBarModule,
    MessagesModule,
    DialogModule,
    CardModule,
    TagModule,

    SharedModule
  ],
  exports: [VotingComponent]
})
export class VotingModule {}
