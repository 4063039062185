import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { HolidayService } from '~/repositories/holiday/services/holiday.service';
import * as HolidayActions from '~/repositories/holiday/store/holiday.actions';
import { IState } from '~/repositories/repositories.store';

@Injectable()
export class HolidayEffects {
  fetchHolidays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.fetchHolidays),
      mergeMap(({ year }) => {
        return this.holidayService.fetchHolidaysForYear(year).pipe(
          map((holidays) =>
            HolidayActions.fetchHolidaysSuccess({
              holidays
            })
          ),
          catchError((error) => of(HolidayActions.fetchHolidaysFailure({ error })))
        );
      })
    )
  );
  publishHoliday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.publishHoliday),
      mergeMap(({ holiday }) => {
        return this.holidayService.publishHoliday(holiday).pipe(
          map((holiday) =>
            HolidayActions.publishHolidaySuccess({
              holiday
            })
          ),
          tap(({ holiday }) => {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('layout.holiday.message.publishSuccess'),
              life: 3000
            });
          }),
          catchError((error) => of(HolidayActions.publishHolidayFailure({ error })))
        );
      })
    )
  );
  deleteHoliday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayActions.deleteHoliday),
      mergeMap(({ id }) => {
        return this.holidayService.deleteHoliday(id).pipe(
          map(() =>
            HolidayActions.deleteHolidaySuccess({
              id
            })
          ),
          tap(() => {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('layout.holiday.message.deleteSuccess'),
              life: 3000
            });
          }),
          catchError((error) => of(HolidayActions.deleteHolidayFailure({ error })))
        );
      })
    )
  );
  // collection of all failures that have to show a toast message
  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ...[
            HolidayActions.fetchHolidaysFailure,
            HolidayActions.publishHolidayFailure,
            HolidayActions.deleteHolidayFailure
          ]
        ),
        tap(({ error }) => {
          if (error.status == 401) {
            this.messageService.add({
              severity: 'error',
              detail: 'unauthorized!',
              life: 3000
            });
            this.router.navigate(['/error']).then(() => true);
          } else {
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant('layout.holiday.message.failed'),
              life: 3000
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private store$: Store<IState>,
    private readonly holidayService: HolidayService,
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {}
}
