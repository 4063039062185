import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakProfile } from 'keycloak-js';
import { MenuItem } from 'primeng/api';
import { count } from 'rxjs';

import { environment } from '../../../environments/environment';

import { navigateAndReload } from '~/core/suite-view/suite-view.helpers';
import { CalculationModule } from '~/repositories/calculation-module/service/calculation-module.service';
import { PrintListModule } from '~/repositories/print-list-module/service/print-list-module.service';
import { ThemeEntity } from '~/repositories/theme/store/theme.models';
import { VotingService } from '~/voting/voting.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() loaded: boolean;
  @Input() theme: ThemeEntity;
  @Input() title = '';
  @Input() user: KeycloakProfile;
  @Input() userHasRoles: boolean;
  @Input() allRolesActive: boolean;
  @Input() allRolesActiveLoading: boolean;
  @Input() editModeEnabled: boolean;
  @Input() mobileMenuItems: MenuItem[];
  @Input() starredMenuItems: MenuItem[];
  @Input() userMenuItems: MenuItem[];

  @Input() helpMenuItems: MenuItem[];
  @Input() menuOpen: boolean;
  @Input() countAnnouncements = 0;
  @Input() countMeetingPointers = 0;
  @Input() configurationMenuItems: MenuItem[];
  @Input() printListModule: PrintListModule;
  @Input() calculationModule: CalculationModule;
  @Input() exportsLength: number;

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() showAnnouncements: EventEmitter<void> = new EventEmitter<void>();
  @Output() showMeetingPointers: EventEmitter<void> = new EventEmitter<void>();
  @Output() triggerSearch: EventEmitter<string> = new EventEmitter();
  @Output() logout: EventEmitter<void> = new EventEmitter();

  @ViewChild('favouritesToggle') favouritesToggle: ElementRef<HTMLElement>;
  @ViewChild('configurationItemsToggle')
  configurationItemsToggle: ElementRef<HTMLElement>;
  isPageLoading = signal<boolean>(false);

  public favouritesVisible = false;
  public configurationItemsVisible = false;

  public voting$ = this.votingService.votingLetter$;

  ngOnInit() {
    window.addEventListener('message', this.handleIframeMessage, false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleIframeMessage, false);
  }

  handleIframeMessage = (event: MessageEvent): void => {
    if (event.data.type === 'LOADING_STATE') {
      this.isPageLoading.set(event.data.loading);
    }
  };

  get userIcon() {
    if (this.allRolesActiveLoading) {
      return null;
    }
    return this.environment.USERNAME_AS
      ? 'fa-solid fa-user-group'
      : this.allRolesActive
      ? 'fa-solid fa-user'
      : 'fa-solid fa-user-xmark';
  }

  constructor(
    private votingService: VotingService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  handleLogout() {
    this.logout.emit();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  toggleFavourites() {
    this.favouritesVisible = !this.favouritesVisible;
  }

  toggleConfigurationItems() {
    this.configurationItemsVisible = !this.configurationItemsVisible;
  }

  onShowAnnouncements() {
    this.showAnnouncements.emit();
  }

  onShowMeetingPointers() {
    this.showMeetingPointers.emit();
  }

  showVotingDialog() {
    this.votingService.votingDialogOpen = true;
  }

  navigateTo(uri: string[]) {
    navigateAndReload(uri, this.router, this.translateService);
  }

  buildLabel() {
    let label = '';
    if (this.user) {
      label =
        this.user.firstName +
        ' ' +
        this.user.lastName
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase())
          .join('');
    }
    /* if (this.environment.USERNAME_AS) {
      label += ' !! ' + this.environment.USERNAME_AS + ' !!';
    } */
    return label;
  }

  protected readonly count = count;
  protected readonly environment = environment;
}
