import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { HolidayEntity } from '~/repositories/holiday/store/holiday.models';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  constructor(private readonly http: HttpClient) {}

  fetchHolidaysForYear(year: number): Observable<HolidayEntity[]> {
    return this.http.get<HolidayEntity[]>(`${environment.BACKEND_URL}/holiday/year/${year}`);
  }

  publishHoliday(holiday: HolidayEntity): Observable<HolidayEntity> {
    return this.http.post<HolidayEntity>(`${environment.BACKEND_URL}/holiday/add`, holiday);
  }

  deleteHoliday(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.BACKEND_URL}/holiday/delete/${id}`);
  }
}
