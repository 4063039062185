<p-dialog
  [(visible)]="visible"
  header="{{ 'voting.vote.title' | translate }}"
  [style]="{ 'min-width': '40vw', 'max-width': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  contentStyleClass="p-0"
  (onHide)="onClose()">
  <div class="px-4">
    <p-messages [closable]="false" key="votes"></p-messages>
  </div>
  <form class="py-3 px-4" [formGroup]="votingForm">
    <div *ngIf="letter.totalVotingDuration > 0">
      <app-progress-bar [step]="progressBarStep()" (timeIsUp)="onFinishVoting()"></app-progress-bar>
    </div>
    <h2 class="w-full">
      {{ letter.formattedTitle }}
    </h2>
    <p-tag
      [value]="
        (letter.votingType === 'SECRET'
          ? 'voting.letter.votingtype.secret.display'
          : 'voting.letter.votingtype.public.display'
        ) | translate
      "
      styleClass="p-tag-lg bg-primary-100 text-primary-400"
      [icon]="letter.votingType === 'SECRET' ? 'fa-regular fa-lock-keyhole' : 'fa-regular fa-lock-keyhole-open'">
    </p-tag>
    <p>
      {{ letter.description }}
    </p>
    <div *ngIf="letter.yesno" class="w-full flex justify-content-center">
      <p-selectButton
        [options]="votingOptions() | removeEmpty"
        optionLabel="label"
        optionValue="value"
        class="p-selectbutton-with-spacing"
        styleClass="p-button-outlined gap-3 flex-wrap flex-column md:flex-row"
        [disabled]="submitVoteLoading"
        [ngModel]="votingFormForActiveMember.value.resultType"
        [ngModelOptions]="{ standalone: true }"
        (onChange)="selectVote($event)"></p-selectButton>
    </div>
    <div *ngIf="!letter.yesno" class="flex flex-column gap-3">
      <div class="candidates-to-select">
        {{ 'voting.result.candidatestoselect.max' | translate : { '0': letter.candidatesToSelect } }}
      </div>
      <div
        *ngFor="let candidate of letter.candidates"
        class="flex align-items-start md:align-items-center justify-content-between flex-column md:flex-row gap-3 w-full bg-white border-round line-height-2 shadow-1 p-3">
        <div>
          <strong>{{ candidate.name }}</strong>
          <div>
            <div *ngIf="candidate.informationAvailable">
              {{ candidate.information }}
            </div>
            <div *ngIf="candidate.commentsAvailable" class="text-xs text-gray-600">
              {{ candidate.comments }}
            </div>
          </div>
        </div>
        <div class="flex flex-wrap gap-3">
          <button
            pButton
            icon="fa-regular fa-thumbs-up"
            (click)="toggleCandidate(candidate.id, 'CANDIDATE_FOR')"
            [disabled]="
              (letter.candidates.length > 1 &&
                !votedForCandidate(candidate.id, 'CANDIDATE_FOR') &&
                hasVotedForMaxCandidates()) ||
              submitVoteLoading
            "
            [ngClass]="{
              'p-button-outlined': !votedForCandidate(candidate.id, 'CANDIDATE_FOR')
            }">
            {{ 'voting.result.type.for' | translate }}
          </button>
          <button
            *ngIf="letter.voteAgainstCandidates"
            pButton
            icon="fa-regular fa-thumbs-down"
            (click)="toggleCandidate(candidate.id, 'CANDIDATE_AGAINST')"
            [ngClass]="{
              'p-button-outlined': !votedForCandidate(candidate.id, 'CANDIDATE_AGAINST')
            }">
            {{ 'voting.result.type.against' | translate }}
          </button>
        </div>
      </div>

      <div class="w-full flex flex-column pt-3 gap-3">
        <button
          pButton
          *ngIf="!letter.voteAgainstCandidates"
          icon="fa-regular fa-thumbs-down"
          (click)="toggleVote('AGAINST')"
          [disabled]="submitVoteLoading"
          class="flex justify-content-center"
          [ngClass]="{
            'p-button-outlined': votingFormForActiveMember.get('resultType').value !== 'AGAINST'
          }">
          {{ 'voting.result.type.against' | translate }}
        </button>
        <button
          pButton
          icon="fa-regular fa-hand"
          (click)="toggleVote('ABSTENTION')"
          [disabled]="submitVoteLoading"
          class="flex justify-content-center"
          [ngClass]="{
            'p-button-outlined': votingFormForActiveMember.get('resultType').value !== 'ABSTENTION'
          }">
          {{ 'voting.abstent' | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="w-full flex justify-content-center align-items-center mb-3" *ngIf="letter?.councilMembers?.length > 1">
    <button
      pButton
      class="p-button-outlined"
      icon="fas fa-caret-left"
      (click)="prevMember()"
      [disabled]="activeMemberIndex === 0 || submitVoteLoading || submitVoteError"></button>
    <span class="px-3">
      {{ 'voting.vote.inthenameof' | translate }}&nbsp;<strong>{{ activeMember.name }}</strong>
    </span>
    <button
      pButton
      class="p-button-outlined"
      icon="fas fa-caret-right"
      (click)="nextMember()"
      [disabled]="
        activeMemberIndex === letter?.councilMembers?.length - 1 || submitVoteLoading || submitVoteError
      "></button>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-start">
      <button pButton type="button" (click)="submitVote()" [loading]="submitVoteLoading">
        {{ 'voting.vote.title' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template pTemplate="closeicon">
    <div
      class="p-dialog-header-icon"
      [title]="
        'dialog.button.close'
          | translate
            : {
                '0': 'voting.vote.title' | translate
              }
      ">
      <i class="text-lg fa-regular fa-xmark text-gray-800"></i>
    </div>
  </ng-template>
</p-dialog>
