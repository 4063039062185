import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, UrlSerializer } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SUITEVIEWPATH } from '../core.routing';

@Component({
  selector: 'app-suite-view',
  templateUrl: './suite-view.component.html',
  styleUrls: ['./suite-view.component.scss']
})
export class SuiteViewComponent implements OnInit, OnDestroy {
  iframeUrl = '';
  navigationEnd = signal<boolean>(false);
  isLoading = signal<boolean>(true);

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private urlSerializer: UrlSerializer) {}

  ngOnInit() {
    this.parseUrl(this.router.url);
    this.navigationEnd.set(true);

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLoading.set(true);
        this.parseUrl(event.url);
        this.navigationEnd.set(false);
      }
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.navigationEnd.set(true);
        }, 50);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  parseUrl(url: string) {
    const urlTree = this.urlSerializer.parse(url);
    urlTree.queryParams['jwt'] = environment.BEARER_TOKEN;
    this.iframeUrl = `${environment.OLD_BACKEND_URL}${this.urlSerializer.serialize(urlTree).replace(`/${SUITEVIEWPATH}`, '')}`;
  }

}
