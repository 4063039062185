import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SavedSearchQuery, SearchResultEntity, SharedWithGroup } from '../store/search.models';
import {
  IChartConfig,
  IColumn,
  IColumnOrder,
  IExports,
  IFetchNavigationListProps,
  IFetchNewPageProps,
  IFetchSearchResultProps,
  IFilter,
  ILiveSearch,
  ISavedSearchQuerySummary
} from '../store/search.types';

import { environment } from '~/core/core.module';
import { SEARCHTYPE } from '~/search/search/search.const';
import { mapSelectedFilters } from '~/search/search/search.helpers';
import { IExportResultsProps, IFilterItem, PaginatedItemsParams } from '~/search/search/search.types';

@Injectable()
export class SearchService {
  constructor(private readonly http: HttpClient) {}

  public getSearchResults({
    type,
    filterData,
    searchValue,
    columns,
    params,
    view,
    viewModeParams
  }: IFetchSearchResultProps): Observable<SearchResultEntity[]> {
    if (type === SEARCHTYPE.SIMPLE) {
      return this.http.post<SearchResultEntity[]>(`${environment.BACKEND_URL}/search/${type}`, {
        filters: mapSelectedFilters(type, filterData, searchValue),
        columns: columns.map((col) => col.code),
        sortColumn: params.sortColumn || undefined,
        sortOrder: params.sortOrder || undefined,
        page: params.currentPage,
        pageSize: params.currentItems,
        view,
        ...viewModeParams
      });
    }

    const parentOperator = filterData[0]?.selectedFilterBehaviour?.value || 'AND';

    return this.http.post<SearchResultEntity[]>(`${environment.BACKEND_URL}/search/${type}`, {
      filterGroup: {
        operator: parentOperator,
        ...mapSelectedFilters(type, filterData, searchValue, parentOperator)
      },
      columns: columns.map((col) => col.code),
      sortColumn: params.sortColumn,
      sortOrder: params.sortOrder,
      page: params.currentPage,
      pageSize: params.currentItems,
      view,
      ...viewModeParams
    });
  }

  public getNewPage({
    filterData,
    columns,
    type,
    params,
    module,
    searchValue,
    view,
    viewModeParams
  }: IFetchNewPageProps): Observable<SearchResultEntity[]> {
    if (type === SEARCHTYPE.SIMPLE) {
      return this.http.post<SearchResultEntity[]>(`${environment.BACKEND_URL}/search/${type}`, {
        filters: mapSelectedFilters(type, filterData, searchValue),
        columns: columns.map((col) => col.code),
        sortColumn: params.sortColumn,
        sortOrder: params.sortOrder,
        page: params.currentPage,
        pageSize: params.currentItems,
        module,
        view,
        ...viewModeParams
      });
    }

    const parentOperator = filterData[0]?.selectedFilterBehaviour?.value || 'AND';

    return this.http.post<SearchResultEntity[]>(`${environment.BACKEND_URL}/search/${type}`, {
      filterGroup: {
        operator: parentOperator,
        ...mapSelectedFilters(type, filterData, searchValue, parentOperator)
      },
      columns: columns.map((col) => col.code),
      module,
      page: params.currentPage,
      pageSize: params.currentItems,
      sortColumn: params.sortColumn,
      sortOrder: params.sortOrder,
      view,
      ...viewModeParams
    });
  }

  public getNavigationList({
    filterData,
    type,
    module,
    params,
    searchValue
  }: IFetchNavigationListProps): Observable<SearchResultEntity[]> {
    if (type === SEARCHTYPE.SIMPLE) {
      return this.http.post<SearchResultEntity[]>(`${environment.BACKEND_URL}/search/${type}`, {
        filters: mapSelectedFilters(type, filterData, searchValue),
        sortColumn: params.sortColumn,
        sortOrder: params.sortOrder,
        page: params.currentPage,
        pageSize: params.currentItems,
        module: module,
        view: 'NAVIGATOR'
      });
    }

    const parentOperator = filterData[0]?.selectedFilterBehaviour?.value || 'AND';

    return this.http.post<SearchResultEntity[]>(`${environment.BACKEND_URL}/search/${type}`, {
      filterGroup: {
        operator: parentOperator,
        ...mapSelectedFilters(type, filterData, searchValue, parentOperator)
      },
      module: module,
      page: params.currentPage,
      pageSize: params.currentItems,
      sortColumn: params.sortColumn,
      sortOrder: params.sortOrder,
      view: 'NAVIGATOR'
    });
  }

  public getLiveSearch(query: string, kccFilter: string): Observable<ILiveSearch> {
    const params: { q: string; kccSearchFilter?: string } = {
      q: query
    };

    if (kccFilter) {
      params.kccSearchFilter = kccFilter;
    }

    return this.http.get<ILiveSearch>(`${environment.BACKEND_URL}/search/live`, {
      params: params
    });
  }

  public getFilters(): Observable<IFilter[]> {
    return this.http.get<IFilter[]>(`${environment.BACKEND_URL}/search/metadata/filters`);
  }

  public getColumns(): Observable<IColumn[]> {
    return this.http.get<IColumn[]>(`${environment.BACKEND_URL}/search/metadata/columns`);
  }

  public getSelectedColumns(): Observable<IColumn[]> {
    return this.http.get<IColumn[]>(`${environment.BACKEND_URL}/search/metadata/selectedcolumns`);
  }

  public getAvailableExports(): Observable<IExports> {
    return this.http.get<IExports>(`${environment.BACKEND_URL}/search/metadata/exports`);
  }

  public updateColumnsOrder(columnsOrder: IColumnOrder[]): Observable<IColumn[]> {
    return this.http.patch<IColumn[]>(`${environment.BACKEND_URL}/search/metadata/selectedcolumns/order`, columnsOrder);
  }

  public getChartConfig(): Observable<IChartConfig> {
    return this.http.get<IChartConfig>(`${environment.BACKEND_URL}/search/metadata/chart-options`);
  }

  public saveQuery(
    id: string,
    name: string,
    filters: IFilterItem[],
    columns: IColumn[],
    params: PaginatedItemsParams,
    type: string,
    selectedViewMode: string,
    module: string,
    searchValue?: string,
    chartGroupByOption?: string,
    chartXAxisOption?: string,
    chartXAxisInterval?: string,
    chartType?: string
  ): Observable<ISavedSearchQuerySummary> {
    let body;
    if (type === SEARCHTYPE.SIMPLE) {
      body = {
        name,
        filters: mapSelectedFilters(type, filters, searchValue),
        columns: columns.map((col) => col.code),
        sortColumn: params.sortColumn,
        sortOrder: params.sortOrder,
        view: selectedViewMode,
        module,
        chartGroupByOption: chartGroupByOption,
        chartXAxisOption: chartXAxisOption,
        chartXAxisInterval: chartXAxisInterval,
        chartType: chartType
      };
    } else {
      const parentOperator = filters[0]?.selectedFilterBehaviour?.value || 'AND';

      body = {
        name,
        filterGroup: {
          operator: parentOperator,
          ...mapSelectedFilters(type, filters, searchValue, parentOperator)
        },
        columns: columns.map((col) => col.code),
        sortColumn: params.sortColumn,
        sortOrder: params.sortOrder,
        view: selectedViewMode,
        module,
        chartGroupByOption: chartGroupByOption,
        chartXAxisOption: chartXAxisOption,
        chartXAxisInterval: chartXAxisInterval,
        chartType: chartType
      };
    }

    if (id) {
      return this.http.post<ISavedSearchQuerySummary>(
        `${environment.BACKEND_URL}/search/savedquery/${type}/${id}`,
        body
      );
    }
    return this.http.post<ISavedSearchQuerySummary>(`${environment.BACKEND_URL}/search/savedquery/${type}`, body);
  }

  public fetchQueries(): Observable<ISavedSearchQuerySummary[]> {
    return this.http.get<ISavedSearchQuerySummary[]>(`${environment.BACKEND_URL}/search/savedquery`);
  }

  public fetchSavedQuery(query: ISavedSearchQuerySummary): Observable<SavedSearchQuery> {
    return this.http.get<SavedSearchQuery>(
      `${environment.BACKEND_URL}/search/savedquery/${query.extended ? SEARCHTYPE.EXTENDED : SEARCHTYPE.SIMPLE}/${
        query.id
      }`
    );
  }

  public removeQuery(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.BACKEND_URL}/search/savedquery/${id}`);
  }

  public getShareableGroups(): Observable<SharedWithGroup[]> {
    return this.http.get<SharedWithGroup[]>(`${environment.BACKEND_URL}/search/savedquery/shareable-groups`);
  }

  public getQuerySharedWith(queryId: string): Observable<SharedWithGroup[]> {
    return this.http.get<SharedWithGroup[]>(`${environment.BACKEND_URL}/search/savedquery/${queryId}/permissions`);
  }

  public updateQuerySharedWith(groups: SharedWithGroup[], queryId: string): Observable<void> {
    return this.http.post<void>(`${environment.BACKEND_URL}/search/savedquery/${queryId}/permissions`, groups);
  }

  public exportResults({
    filterData,
    columns,
    activeFilterTab,
    exportCode,
    module,
    searchValue
  }: IExportResultsProps): Observable<number> {
    if (activeFilterTab === SEARCHTYPE.SIMPLE) {
      return this.http.post<number>(
        `${environment.BACKEND_URL}/search/${activeFilterTab}/export`,
        {
          filters: mapSelectedFilters(activeFilterTab, filterData, searchValue),
          columns: columns.map((col) => col.code),
          module
        },
        {
          params: {
            code: exportCode
          }
        }
      );
    }

    const parentOperator = filterData[0]?.selectedFilterBehaviour?.value || 'AND';

    return this.http.post<number>(
      `${environment.BACKEND_URL}/search/${activeFilterTab}/export`,
      {
        filterGroup: {
          operator: parentOperator,
          ...mapSelectedFilters(activeFilterTab, filterData, searchValue, parentOperator)
        },
        columns: columns.map((col) => col.code),
        module
      },
      {
        params: {
          code: exportCode
        }
      }
    );
  }
}
