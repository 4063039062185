export enum SEARCHTYPE {
  SIMPLE = 'simple',
  EXTENDED = 'extended'
}

export enum FILTERITEMTYPE {
  GROUP = 'GROUP',
  FILTER = 'FILTER'
}

export enum VIEWMODES {
  LIST = 'LIST',
  MAP = 'MAP',
  CHART = 'CHART'
}

export enum RESULTTABS {
  KCC = 'KCC',
  EDECISION = 'EDECISION',
  CASES = 'CASES',
  CONTACTS = 'CONTACTS',
  AGENTS = 'AGENTS'
}

export const filterBehaviourOptions = [
  { label: 'EN', value: 'AND' },
  { label: 'OF', value: 'OR' }
];

export const inLastOptions = [
  { value: 'uren', key: 'HOURS' },
  { value: 'dagen', key: 'DAYS' },
  { value: 'weken', key: 'WEEKS' },
  { value: 'maanden', key: 'MONTHS' },
  { value: 'jaren', key: 'YEARS' }
];

export const sinceBeginningOfOptions = [
  { value: 'de dag', key: 'DAY' },
  { value: 'de week', key: 'WEEK' },
  { value: 'de maand', key: 'MONTH' },
  { value: 'het jaar', key: 'YEAR' }
];

export const chartIntervalOptions = [
  { label: 'WEEK', value: 'WEEK' },
  { label: 'MONTH', value: 'MONTH' },
  { label: 'YEAR', value: 'YEAR' }
];

export const VIEWMODESFORTAB = {
  KCC: [VIEWMODES.LIST],
  EDECISION: [VIEWMODES.LIST, VIEWMODES.CHART],
  CASES: [VIEWMODES.LIST, VIEWMODES.CHART, VIEWMODES.MAP],
  CONTACTS: [VIEWMODES.LIST, VIEWMODES.CHART],
  AGENTS: [VIEWMODES.LIST]
};
