import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from 'safe-pipe';

import { Pipes } from './pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [Pipes],
  providers: [Pipes],
  exports: [Pipes, SafePipeModule]
})
export class SharedModule {}
