import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrl: './walkthrough.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalkthroughComponent {
  @Input() visible: boolean;
  @Input() set steps(items: MenuItem[]) {
    this.items = items;
    this.activeIndex.set(0);
  }

  @Output() closeDialog = new EventEmitter();

  items: MenuItem[];
  activeIndex = signal<number>(0);

  setActiveIndex(index: number) {
    this.activeIndex.set(index);
  }
}
