import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import {
  WrittenQuestionEntity,
  WrittenQuestionsFilter
} from '~/repositories/written-questions/store/written-questions.models';

@Injectable()
export class WrittenQuestionsService {
  constructor(private readonly http: HttpClient) {}

  public fetchWrittenQuestions(filter: WrittenQuestionsFilter): Observable<WrittenQuestionEntity[]> {
    return this.http.post<WrittenQuestionEntity[]>(`${environment.BACKEND_URL}/writtenquestions/fetch`, filter);
  }

  public publishWrittenQuestions(writtenQuestions: WrittenQuestionEntity[]): Observable<WrittenQuestionEntity[]> {
    return this.http.post<WrittenQuestionEntity[]>(
      `${environment.BACKEND_URL}/writtenquestions/publish`,
      writtenQuestions
    );
  }
}
