import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, of, mergeMap, map } from 'rxjs';

import { ThemeService } from '../services/theme.service';

import * as ThemeActions from './theme.actions';
import { IConfigurationItem, IFavourite, IFooter, IHelpItem, ThemeEntity } from './theme.models';

@Injectable()
export class ThemeEffects {
  constructor(private readonly actions$: Actions, private readonly themeService: ThemeService) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeActions.initTheme),
      mergeMap(({ organisation }) => {
        return this.themeService.getThemeForOrganisation(organisation).pipe(
          map((theme: ThemeEntity) =>
            ThemeActions.loadThemeSuccess({
              theme: [
                {
                  id: 0,
                  ...theme
                }
              ]
            })
          ),
          catchError((error) => of(ThemeActions.loadThemeFailure({ error })))
        );
      })
    )
  );

  loadFavourites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeActions.loadFavourites),
      mergeMap(() => {
        return this.themeService.getFavourites().pipe(
          map((favourites: IFavourite[]) =>
            ThemeActions.loadFavouritesSuccess({
              favourites
            })
          ),
          catchError((error) => of(ThemeActions.loadFavouritesFailure({ error })))
        );
      })
    )
  );

  loadHelpInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeActions.loadHelpInformation),
      mergeMap(() => {
        return this.themeService.getHelpInformation().pipe(
          map((helpInformationItems: IHelpItem[]) =>
            ThemeActions.loadHelpInformationSuccess({
              helpInformationItems
            })
          ),
          catchError((error) => of(ThemeActions.loadHelpInformationFailure({ error })))
        );
      })
    )
  );

  loadFooterInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeActions.loadFooterInformation),
      mergeMap(() => {
        return this.themeService.getFooterInformation().pipe(
          map((footerInformation: IFooter) =>
            ThemeActions.loadFooterInformationSuccess({
              footerInformation
            })
          ),
          catchError((error) => of(ThemeActions.loadFooterInformationFailure({ error })))
        );
      })
    )
  );

  loadConfigurationItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ThemeActions.loadConfigurationItems),
      mergeMap(() => {
        return this.themeService.getConfigurationItems().pipe(
          map((configurationItems: IConfigurationItem[]) =>
            ThemeActions.loadConfigurationItemsSuccess({
              configurationItems
            })
          ),
          catchError((error) => of(ThemeActions.loadConfigurationItemsFailure({ error })))
        );
      })
    )
  );
}
