import { createFeatureSelector, createSelector } from '@ngrx/store';
import { chain, Dictionary } from 'lodash';
import moment from 'moment';

import { AsyncUserTask, UserTaskStatus, UserTaskType } from './userdata.models';
import { userDataAdapter, UserDataState } from './userdata.reducer';

import { IRepositoryState } from '~/repositories/repositories.store';

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');

const { selectAll } = userDataAdapter.getSelectors();

export const selectUserDataState = createSelector(REPOSITORY_SELECTOR, (state: IRepositoryState) => state.userData);

export const selectNewExportsLength = createSelector(selectUserDataState, (state: UserDataState): number => {
  return selectAll(state).filter((e) => !e.downloaded && e.status === UserTaskStatus.COMPLETED).length;
});

export const selectAllExportsLength = createSelector(selectUserDataState, (state: UserDataState): number => {
  return selectAll(state).length;
});

export const selectAllExports = createSelector(
  selectUserDataState,
  (state: UserDataState): Dictionary<AsyncUserTask[]> => {
    const exportsDictionary = chain(selectAll(state))
      .filter((result) => result.type === UserTaskType.SEARCH_EXPORT)
      .sort((a, b) => {
        const aDate = moment(a.created || new Date(), 'DD/MM/YYYY hh:mm:ss')
          .toDate()
          .getTime();
        const bDate = moment(b.created || new Date(), 'DD/MM/YYYY hh:mm:ss')
          .toDate()
          .getTime();
        return bDate - aDate;
      })
      .groupBy((item) => {
        const date = moment(item.created || new Date(), 'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD');
        return date;
      })
      .value();

    return exportsDictionary;
  }
);

export const selectResultsLoading = createSelector(selectUserDataState, (state: UserDataState) => state.loading);

export const selectAllRolesActiveLoading = createSelector(
  selectUserDataState,
  (state: UserDataState) => state.allRolesActiveLoading
);
export const selectAllRolesActive = createSelector(selectUserDataState, (state: UserDataState) => state.allRolesActive);
