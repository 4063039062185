export enum ArchiveActions {
  fetchArchiveTabs = '[ArchiveActions]: fetch archive tabs',
  fetchArchiveTabsSuccess = '[ArchiveActions]: fetch archive tabs success',
  fetchArchiveTabsFailure = '[ArchiveActions]: fetch archive tabs failure',
  setSelectedTab = '[ArchiveActions]: set selected tab',
  fetchArchiveData = '[ArchiveActions]: fetch archive data',
  fetchArchiveDataSuccess = '[ArchiveActions]: fetch archive data success',
  fetchArchiveDataFailure = '[ArchiveActions]: fetch archive data failure',
  sendToArchive = '[ArchiveActions]: send to archive',
  sendToArchiveSuccess = '[ArchiveActions]: send to archive success',
  sendToArchiveFailure = '[ArchiveActions]: send to archive failure'
}
