import { createAction, props } from '@ngrx/store';

import { HolidayActions } from '~/repositories/holiday/store/holiday.const';
import { HolidayEntity } from '~/repositories/holiday/store/holiday.models';

export const fetchHolidays = createAction(HolidayActions.fetchHolidays, props<{ year: number }>());
export const fetchHolidaysSuccess = createAction(
  HolidayActions.fetchHolidaysSuccess,
  props<{ holidays: HolidayEntity[] }>()
);
export const fetchHolidaysFailure = createAction(HolidayActions.fetchHolidaysFailure, props<{ error: any }>());
export const fetchAvailableYears = createAction(HolidayActions.fetchAvailableYears);
export const changeSelectedYear = createAction(HolidayActions.changeSelectedYear, props<{ year: { year: number } }>());

export const publishHoliday = createAction(HolidayActions.publishHoliday, props<{ holiday: HolidayEntity }>());
export const publishHolidaySuccess = createAction(
  HolidayActions.publishHolidaySuccess,
  props<{ holiday: HolidayEntity }>()
);

export const publishHolidayFailure = createAction(HolidayActions.publishHolidayFailure, props<{ error: any }>());

export const deleteHoliday = createAction(HolidayActions.deleteHoliday, props<{ id: string }>());
export const deleteHolidaySuccess = createAction(HolidayActions.deleteHolidaySuccess, props<{ id: string }>());

export const deleteHolidayFailure = createAction(HolidayActions.deleteHolidayFailure, props<{ error: any }>());
