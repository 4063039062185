import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { GenericWidgetDataEntity, IGenericWidgetDataState } from './generic-widget-data.types';

import { CardView } from '~/ui/ui.module';

export const adapter: EntityAdapter<GenericWidgetDataEntity<CardView>> = createEntityAdapter<
  GenericWidgetDataEntity<CardView>
>({
  selectId: (entity: GenericWidgetDataEntity<CardView>) => entity.id
});

export const cardViewAdapter: EntityAdapter<CardView> = createEntityAdapter<CardView>({
  selectId: (cardview) => cardview.id
});

export const initialState: IGenericWidgetDataState = adapter.getInitialState({}) as IGenericWidgetDataState;

export const initialItemState: GenericWidgetDataEntity<CardView> = {
  loaded: false,
  error: null,
  currentPage: 0,
  currentItems: 10
};
