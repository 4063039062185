import { Injectable, OnDestroy } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, of, withLatestFrom, mergeMap, map, Observable, takeUntil, Subject, tap } from 'rxjs';

import { GenericWidgetDataService } from '../services/generic-widget-data.service';

import { fetchGenericWidgetDataSuccess, loadGenericWidgetDataFailure } from './generic-widget-data.actions';
import { initialItemState } from './generic-widget-data.adapter';
import { GenericWidgetDataActions } from './generic-widget-data.const';
import { GenericWidgetDataEntity, GenericWidgetDataResponse, PaginatedItemsParams } from './generic-widget-data.types';

import { IError } from '~/core/global-error-handler.service';
import { IState } from '~/repositories/repositories.store';
import { CardView } from '~/ui/ui.module';

@Injectable()
export class GenericWidgetDataEffects implements OnDestroy {
  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private store$: Store<IState>,
    private genericWidgetDataService: GenericWidgetDataService,
    private readonly messageService: MessageService
  ) {}

  public getGenericWidgetData$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(GenericWidgetDataActions.getGenericWidgetData),
        withLatestFrom(this.store$),
        mergeMap(([action, state]) => {
          const {
            id,
            widgetTypeCode,
            params,
            refresh
          }: {
            id: string;
            widgetTypeCode: string;
            params: PaginatedItemsParams;
            refresh?: boolean;
          } = action;

          const currentWidgetData: GenericWidgetDataEntity<CardView> = state.repository.genericWidgetData.entities[id];

          const currentPage: number =
            params?.currentPage || (!refresh && currentWidgetData?.currentPage) || initialItemState.currentPage;

          const currentItems: number =
            params?.currentItems || (!refresh && currentWidgetData?.currentItems) || initialItemState.currentItems;

          // Uncomment to save paginated items in store
          /* const existing: GenericWidgetData =
            !refresh &&
            selectGenericWidgetDataByIdForPage(
              state.repository.genericWidgetData,
              {
                id,
                params,
              }
            );

          if (existing && existing.items.length > 0) {
            return of(
              loadGenericWidgetDataSuccess({
                item: existing,
              })
            );
          } */

          return this.genericWidgetDataService
            .getDataByType(widgetTypeCode, {
              ...params,
              currentPage,
              currentItems
            })
            .pipe(
              map((item: GenericWidgetDataResponse) => {
                return fetchGenericWidgetDataSuccess({
                  item: {
                    ...item,
                    id,
                    items:
                      item.items?.map((item, index) => {
                        return {
                          ...item,
                          id: (item.id as unknown as { value: number })?.value || item.id,
                          index: index + currentPage * currentItems
                        };
                      }) || []
                  }
                });
              }),
              catchError((error: any) =>
                of(
                  loadGenericWidgetDataFailure({
                    id,
                    error
                  })
                )
              )
            );
        }),
        takeUntil(this.destroyed$)
      )
  );

  getGenericWidgetDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GenericWidgetDataActions.getGenericWidgetDataFailed),
        tap(
          ({
            error
          }: {
            error: {
              error: IError;
            };
          }) => {
            this.messageService.add({
              severity: 'error',
              detail: error?.error?.message,
              life: 3000
            });
          }
        )
      ),
    { dispatch: false }
  );

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
