import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { ArchiveService } from '../services/archive.service';

import * as ArchiveActions from './archive.actions';
import { ArchiveDataResult, ArchiveTabDataEntity } from './archive.models';

@Injectable()
export class ArchiveEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly archiveService: ArchiveService,
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService
  ) {}

  fetchArchiveTabs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.fetchArchiveTabs),
      mergeMap(() => {
        return this.archiveService.fetchArchiveTabs().pipe(
          map((tabs: ArchiveTabDataEntity[]) => ArchiveActions.fetchArchiveTabsSuccess({ tabs })),
          catchError((error) => of(ArchiveActions.fetchArchiveTabsFailure({ error })))
        );
      })
    )
  );

  fetchArchiveData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.fetchArchiveData),
      mergeMap(({ module, filter }) => {
        return this.archiveService.fetchArchiveData(module, filter).pipe(
          map((result: ArchiveDataResult) => ArchiveActions.fetchArchiveDataSuccess({ result })),
          catchError((error) => of(ArchiveActions.fetchArchiveDataFailure({ error })))
        );
      })
    )
  );

  sendToArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchiveActions.sendToArchive),
      mergeMap(({ data }) => {
        return this.archiveService.sendToArchive(data).pipe(
          map((total) => ArchiveActions.sendToArchiveSuccess({ total })),
          catchError((error) => of(ArchiveActions.sendToArchiveFailure({ error })))
        );
      })
    )
  );

  sendToArchiveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(...[ArchiveActions.sendToArchiveSuccess]),
        tap(({ total }) => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('dav.archive.period.confirm.success', { 0: total }),
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );

  // collection of all failures that have to show a toast message
  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(...[ArchiveActions.sendToArchiveFailure]),
        tap(({ error }) => {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant('dav.archive.period.confirm.error') + ': ' + error.error.message,
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );
}
