import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IActionsAssigneesResponse, IGetActionAssigneesProps, IWidgetActionEmitProps } from '../store/widgets.types';

import { environment } from '~/core/core.module';
import { WidgetInfo } from '~/repositories/tabs/store/tabs.models';
import {
  FilterEntity,
  SearchResult,
  WidgetConfigEntity,
  WidgetOrder,
  WidgetTypeEntity
} from '~/repositories/widgets/store/widgets.models';

@Injectable()
export class WidgetsService {
  constructor(private readonly http: HttpClient) {}

  public getWidgetConfigById(id: string): Observable<WidgetConfigEntity> {
    return this.http.get<WidgetConfigEntity>(`${environment.BACKEND_URL}/dashboard/widgets/${id}`);
  }

  public addWidgetConfig(widgetConfig: WidgetConfigEntity): Observable<WidgetConfigEntity> {
    return this.http.post<WidgetConfigEntity>(`${environment.BACKEND_URL}/dashboard/widgets`, widgetConfig);
  }

  public updateWidgetConfig(widgetConfig: WidgetConfigEntity): Observable<WidgetConfigEntity> {
    return this.http.patch<WidgetConfigEntity>(`${environment.BACKEND_URL}/dashboard/widgets`, widgetConfig);
  }

  public moveWidget(widgetId: string, tabId: string): Observable<WidgetInfo> {
    return this.http.patch<WidgetInfo>(`${environment.BACKEND_URL}/dashboard/widgets/move`, { widgetId, tabId });
  }

  public getWidgetTypes() {
    return this.http.get<WidgetTypeEntity[]>(`${environment.BACKEND_URL}/dashboard/widgettypes`);
  }

  public getWidgetConfigFieldData(typeCode: string, configFieldCode: string, searchValue?: string) {
    return this.http.post<SearchResult[]>(`${environment.BACKEND_URL}/dashboard/widgetconfigfields`, {
      typeCode,
      configFieldCode,
      searchValue
    });
  }

  public getWidgetSearchFieldData(typeCode: string, searchFieldCode: string, searchValue?: string) {
    return this.http.post<SearchResult[]>(`${environment.BACKEND_URL}/dashboard/widgetsearchfields`, {
      typeCode,
      searchFieldCode,
      searchValue
    });
  }

  public updateWidgetsOrder(currentTabId: string, widgetsOrder: WidgetOrder[]): Observable<WidgetConfigEntity[]> {
    return this.http.patch<WidgetConfigEntity[]>(
      `${environment.BACKEND_URL}/dashboard/tabs/${currentTabId}/widgets/order`,
      widgetsOrder
    );
  }

  public removeWidget(widgetId: string): Observable<void> {
    return this.http.delete<void>(`${environment.BACKEND_URL}/dashboard/widgets/${widgetId}`);
  }

  public emitAction(action: IWidgetActionEmitProps) {
    return this.http.post<IWidgetActionEmitProps>(`${environment.BACKEND_URL}/dashboard/tasks`, {
      ...action
    });
  }

  public getActionAssignees(body: IGetActionAssigneesProps) {
    return this.http.post<IActionsAssigneesResponse>(`${environment.BACKEND_URL}/dashboard/tasks/assignees`, {
      ...body
    });
  }

  // temporary method to make previous/next work in the old Suite
  public getNavigationList(params: WidgetConfigEntity) {
    return this.http.post<string[]>(`${environment.BACKEND_URL}/dashboard/navigationlist`, {
      typeCode: params.widgetType,
      pageNumber: 0,
      pageSize: 9999,
      sortFieldCode: params.selectedSortField,
      sortDirectionCode: params.selectedSortDirection,
      fixedFilter: params.selectedFixedFilter,
      searchFilters: params?.selectedSearchFields?.reduce((acc: { [key: string]: string }, curr: FilterEntity) => {
        acc[curr.code] = curr.value;
        return acc;
      }, {}),
      configFields: params.selectedConfigFields?.reduce((acc: { [key: string]: string[] }, curr: FilterEntity) => {
        acc[curr.code] ? acc[curr.code].push(curr.value) : (acc[curr.code] = [curr.value]);
        return acc;
      }, {})
    });
  }
}
