import { createAction, props } from '@ngrx/store';

import { UserDataActions } from './userdata.const';
import { AsyncUserTask } from './userdata.models';

export const fetchAsyncUserTasks = createAction(UserDataActions.fetchAsyncUserTasks);

export const fetchAsyncUserTasksSuccess = createAction(
  UserDataActions.fetchAsyncUserTasksSuccess,
  props<{ data: AsyncUserTask[] }>()
);

export const fetchAsyncUserTasksFailure = createAction(
  UserDataActions.fetchAsyncUserTasksFailure,
  props<{ error: any }>()
);

export const updateAsyncUserTask = createAction(UserDataActions.updateAsyncUserTask, props<{ task: AsyncUserTask }>());

export const deleteAsyncUserTask = createAction(UserDataActions.deleteAsyncUserTask, props<{ id: string }>());

export const deleteAsyncUserTaskSuccess = createAction(
  UserDataActions.deleteAsyncUserTaskSuccess,
  props<{ id: string }>()
);

export const deleteAsyncUserTaskFailure = createAction(
  UserDataActions.deleteAsyncUserTaskFailure,
  props<{ error: any }>()
);

export const fetchAllRolesActive = createAction(UserDataActions.fetchAllRolesActive);

export const fetchAllRolesActiveSuccess = createAction(
  UserDataActions.fetchAllRolesActiveSuccess,
  props<{ allRolesActive: boolean }>()
);

export const fetchAllRolesActiveFailure = createAction(
  UserDataActions.fetchAllRolesActiveFailure,
  props<{ error: any }>()
);
