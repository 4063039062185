import { createAction, props } from '@ngrx/store';

import { NewTabEntity, SharedWithGroup, TabEntity, TabOrder, WidgetInfo } from './tabs.models';

import { WidgetConfigEntity } from '~/repositories/widgets/store/widgets.models';

export const initTabs = createAction('[Tabs Page] Init', props<{ userId: number | string }>());

export const loadTabsSuccess = createAction('[Tabs/API] Load Tabs Success', props<{ tabs: TabEntity[] }>());

export const loadTabsFailure = createAction('[Tabs/API] Load Tabs Failure', props<{ error: any }>());

export const selectTab = createAction('[Tabs/API] Select Tab', props<{ tabId: string }>());

export const selectTabSuccess = createAction('[Tabs/API] Select Tab Success', props<{ tabId: string }>());

export const selectTabFailure = createAction('[Tabs/API] Select Tab Failure', props<{ error: any }>());

export const saveTab = createAction('[Tabs/API] Save New Tab', props<{ tab: NewTabEntity }>());

export const saveTabSuccess = createAction('[Tabs/API] Save New Tab Success', props<{ tab: TabEntity }>());

export const saveTabFailure = createAction('[Tabs/API] Save New Tab Failure', props<{ error: any }>());

export const updateTabsOrder = createAction('[Tabs/API] Update Tabs Order', props<{ tabsOrder: TabOrder[] }>());

export const updateTabsOrderSuccess = createAction(
  '[Tabs/API] Update Tabs Order Success',
  props<{ tabs: TabEntity[] }>()
);

export const updateTabsOrderFailure = createAction('[Tabs/API] Update Tabs Order Failure', props<{ error: any }>());

export const updateTabSharedWith = createAction(
  '[/API] Update Tab SharedWith',
  props<{ groups: SharedWithGroup[]; tabId: string }>()
);

export const updateTabSharedWithSuccess = createAction(
  '[Tabs/API] Update Tab SharedWith Success',
  props<{ tab: TabEntity }>()
);

export const updateTabSharedWithFailure = createAction(
  '[Tabs/API] Update Tab SharedWith Failure',
  props<{ error: any }>()
);

export const setEditMode = createAction('[Tabs/API] Toggle Edit Mode', props<{ enabled: boolean }>());

export const updateTabTitle = createAction('[Tabs/API] Update Tab Title', props<{ title: string }>());

export const updateTabTitleSuccess = createAction('[Tabs/API] Update Tab Title Success', props<{ tab: TabEntity }>());

export const updateTabTitleFailure = createAction('[Tabs/API] Update Tab Title Failure', props<{ error: any }>());

export const addNewWidget = createAction('[Tabs/API] Add New Widget', props<{ widgetConfig: WidgetConfigEntity }>());

export const addNewWidgetSuccess = createAction(
  '[Tabs/API] Add New Widget success',
  props<{ widgetConfig: WidgetConfigEntity }>()
);

export const addNewWidgetFailure = createAction('[Tabs/API] Add New Widget failure', props<{ error: any }>());

export const moveWidget = createAction('[Tabs/API] Move Widget', props<{ widgetId: string; tabId: string }>());

export const moveWidgetSuccess = createAction(
  '[Tabs/API] Move Widget success',
  props<{ widgetInfo: WidgetInfo; tabId: string }>()
);

export const moveWidgetFailure = createAction('[Tabs/API] Move Widget failure', props<{ error: any }>());

export const removeWidgetFromTab = createAction('[Tabs/API] Remove Widget From Tab', props<{ widgetId: string }>());

export const removeWidgetFromTabSuccess = createAction(
  '[Tabs/API] Remove Widget From Tab success',
  props<{ widgetId: string }>()
);

export const removeWidgetFromTabFailure = createAction(
  '[Tabs/API] Remove Widget From Tab failure',
  props<{ error: any }>()
);

export const updateWidget = createAction('[Tabs/API] Update Widget', props<{ updatedWidget: WidgetConfigEntity }>());

export const removeTab = createAction('[Tabs/API] Remove Tab', props<{ tabId: string }>());

export const removeTabSuccess = createAction('[Tabs/API] Remove Tab Success', props<{ tabId: string }>());

export const removeTabFailure = createAction('[Tabs/API] Remove Tab Failure', props<{ error: any }>());
