import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-login-as-dialog',
  templateUrl: './login-as-dialog.component.html',
  styleUrl: './login-as-dialog.component.scss'
})
export class LoginAsDialogComponent {
  username = '';
  usernameInvalid = false;

  @Input() loginAsLoading$: Observable<boolean>;
  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() loginAs = new EventEmitter<string>();

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();
  onLoginAs() {
    if (this.username.length === 0) {
      this.usernameInvalid = true;
      return;
    }

    this.onClose();
    this.loginAs.emit(this.username);
  }

  onClose() {
    this.visibleChange.emit(false);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
