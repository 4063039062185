<div class="flex h-full justify-content-center align-items-center px-4 py-8 md:px-6 lg:px-8">
  <div class="flex flex-column justify-content-center align-items-center gap-7">
    <app-empty-state message="Page not found"></app-empty-state>
    <button
      [routerLink]="['/' | localize]"
      pbutton=""
      type="button"
      label="Go back to home page"
      class="p-button-outlined p-button">
      <span class="p-button-label">Go back to home page</span>
    </button>
  </div>
</div>
