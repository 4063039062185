<div
  *ngIf="totalRecords > 0"
  class="flex flex-1 flex-wrap-reverse align-items-center justify-content-center md:justify-content-end gap-2">
  <p-dropdown
    [(ngModel)]="rows"
    [options]="options"
    optionLabel="label"
    optionValue="value"
    (onChange)="handlechangeRows($event)"
    appendTo="body"></p-dropdown>
  <span [ngClass]="{ 'md:mr-6': rows < totalRecords }" class="md:ml-3">{{ 'results.per.page' | translate }}</span>
  <p-paginator
    [rows]="rows"
    [first]="first || page * rows"
    [alwaysShow]="alwaysShow"
    [showFirstLastIcon]="showFirstLastIcon"
    [totalRecords]="totalRecords"
    (onPageChange)="handleChangePage($event)"
    styleClass="p-0">
    <ng-template pTemplate="firstpagelinkicon">
      <div class="p-paginator-icon" [title]="'common.first.page' | translate">
        <i class="fa-regular fa-chevrons-left"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="previouspagelinkicon">
      <div class="p-paginator-icon" [title]="'common.prev.page' | translate">
        <i class="fa-regular fa-chevron-left"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="nextpagelinkicon">
      <div class="p-paginator-icon" [title]="'common.next.page' | translate">
        <i class="fa-regular fa-chevron-right"></i>
      </div>
    </ng-template>
    <ng-template pTemplate="lastpagelinkicon">
      <div class="p-paginator-icon" [title]="'common.last.page' | translate">
        <i class="fa-regular fa-chevrons-right"></i>
      </div>
    </ng-template>
  </p-paginator>
</div>
