import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { PaginatorState } from 'primeng/paginator';

export const PaginatorOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
];

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrl: './table-paginator.component.scss'
})
export class TablePaginatorComponent {
  @Input() rows: number;
  @Input() page: number = 0;
  @Input() first: number;
  @Input() totalRecords: number;
  @Input() alwaysShow: boolean;
  @Input() showFirstLastIcon: boolean;

  @Output() changePage = new EventEmitter<PaginatorState>();
  @Output() changeRows = new EventEmitter<number>();

  options = PaginatorOptions;

  handlechangeRows = ({ value }: DropdownChangeEvent) => {
    this.page = 0;
    this.changeRows.emit(value);
  };

  handleChangePage = (data: PaginatorState) => {
    this.changePage.emit(data);
  };
}
