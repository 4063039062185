import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';

import * as AnnouncementsActions from './announcement.actions';

import { AnnouncementService } from '~/repositories/announcements/services/announcement.service';
import { AnnouncementEntity } from '~/repositories/announcements/store/announcement.models';
import { IState } from '~/repositories/repositories.store';

@Injectable()
export class AnnouncementEffects {
  constructor(
    private readonly actions$: Actions,
    private store$: Store<IState>,
    private readonly announcementService: AnnouncementService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementsActions.initAnnouncements),
      mergeMap(() => {
        return this.announcementService.getAnnouncements().pipe(
          map((announcements: AnnouncementEntity[]) =>
            AnnouncementsActions.loadAnnouncementsSuccess({ announcements })
          ),
          catchError((error) => of(AnnouncementsActions.loadAnnouncementsFailure({ error })))
        );
      })
    )
  );

  markAnnouncementsViewed = createEffect(() =>
    this.actions$.pipe(
      ofType(AnnouncementsActions.markAnnouncementsViewed),
      mergeMap(({ announcementIds }) =>
        this.announcementService.markAnnouncementsViewed(announcementIds).pipe(
          map(() =>
            AnnouncementsActions.markAnnouncementsViewedSuccess({
              announcementIds
            })
          ),
          catchError((error) => of(AnnouncementsActions.markAnnouncementsViewedFailure({ error })))
        )
      )
    )
  );
}
