<p-dialog
  *ngIf="items as steps"
  [header]="steps[activeIndex()].title"
  [visible]="visible"
  [style]="{ width: '1260px' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [maximizable]="true"
  (visibleChange)="setActiveIndex(0); closeDialog.emit()">
  <div
    *ngIf="steps[activeIndex()]['description'] as description"
    [ngClass]="{
      'pb-2': steps[activeIndex()]['image']
    }"
    [innerHTML]="description"></div>
  <p-image
    *ngIf="steps[activeIndex()]['image'] as image"
    [src]="image | secureResourceUrl"
    [alt]="steps[activeIndex()].title"
    width="100%"
    height="auto"
    imageClass="border-round-lg"></p-image>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between flex-wrap gap-2">
      <div class="flex">
        <button
          pButton
          *ngIf="activeIndex() < steps.length - 1"
          [label]="(activeIndex() === 0 ? 'walkthrough.show.all' : 'walkthrough.next') | translate"
          (click)="setActiveIndex(activeIndex() + 1)"></button>
        <button
          pButton
          [label]="(activeIndex() === steps.length - 1 ? 'walkthrough.skip' : 'walkthrough.skip.now') | translate"
          [ngClass]="{
            'p-button-outlined': activeIndex() < steps.length - 1
          }"
          (click)="setActiveIndex(0); closeDialog.emit()"></button>
      </div>
      <p-steps
        class="hidden lg:flex align-items-center flex-auto"
        styleClass="w-full"
        [style.maxWidth.px]="(steps.length * 70).toString()"
        [model]="steps"
        [readonly]="false"
        [activeIndex]="activeIndex()"
        (activeIndexChange)="setActiveIndex($event)"></p-steps>
    </div>
  </ng-template>
</p-dialog>
