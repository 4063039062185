<p-overlay
  [appendTo]="button"
  target="@parent"
  [visible]="visible"
  direction="bottom-start"
  styleClass="favourites-overlay pointer-events-none"
  contentStyleClass="surface-overlay shadow-2 border-round overflow-hidden"
  (onHide)="hideOverlay()"
  [autoZIndex]="true"
  [baseZIndex]="6000"
  role="menu">
  <ng-template pTemplate="content"
    ><div *ngFor="let item of menuItems; let i = index" role="menuitem" [title]="item.label | translate">
      <div
        class="flex justify-content-between align-items-center py-2 px-3 bg-gray-100 border-bottom-1 border-gray-300"
        [ngClass]="{
          'border-top-1': i !== 0,
        }">
        <span class="text-base text-left font-medium line-height-3 text-gray-800 mr-3">{{
          item.label | translate
        }}</span>
        <a
          (click)="navigateToSuiteView(item.url)"
          role="link"
          class="pointer-events-auto white-space-nowrap text-sm text-left font-medium line-height-2 text-blue-400 hover:text-blue-700 no-underline flex align-items-center"
          ><i class="pointer-events-auto fa-solid fa-star text-blue-400 mr-1"></i>{{ 'favourites.all' | translate }}</a
        >
      </div>
      <a
        *ngFor="let el of item.items"
        (click)="navigateToSuiteView(el.url)"
        role="link"
        class="pointer-events-auto flex py-2 px-3 hover:bg-gray-100 text-sm text-left font-medium line-height-2 text-gray-700 no-underline">
        {{ el.label | translate }}
      </a>
    </div>
    <a
      (click)="navigateToSuiteView('objecttypes')"
      class="py-2 px-3 bg-gray-100 border-top-1 border-gray-300 pointer-events-auto text-sm text-left font-medium line-height-2 text-blue-400 hover:text-blue-700 no-underline flex align-items-center"
      ><i class="fa-solid fa-rectangle-list text-blue-400 mr-1"></i>{{ 'forms.all' | translate }}</a
    >
  </ng-template>
</p-overlay>
