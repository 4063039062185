import { Router, RoutesRecognized } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { filter, firstValueFrom, map, switchMap, take, throwError } from 'rxjs';

import { RealmService } from './realm.service';

import { environment } from '~/core/core.module';
import { rxStompConfig } from '~/core/websockets/rx-stomp.config';

export function KeycloakInitializer(
  keycloak: KeycloakService,
  realmService: RealmService,
  router: Router
): () => Promise<unknown> {
  keycloak.keycloakEvents$.subscribe({
    next(event) {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        //console.log('ACCESS TOKEN BEFORE UPDATE :');
        //console.log(keycloak.getKeycloakInstance().token);
        keycloak.updateToken(20);
      }
      if (event.type == KeycloakEventType.OnAuthRefreshSuccess) {
        //console.log('ACCESS TOKEN AFTER UPDATE :');
        //console.log(keycloak.getKeycloakInstance().token);
        environment.BEARER_TOKEN = keycloak.getKeycloakInstance().token;
        rxStompConfig.connectHeaders['login'] = environment.USERNAME;
        rxStompConfig.connectHeaders['passcode'] = environment.BEARER_TOKEN;
      }
    }
  });

  return () =>
    firstValueFrom(
      realmService.getConfig().pipe(
        switchMap<any, any>((config) => {
          if (!config) {
            return throwError(() => new Error('Keycloak initialization failed'));
          }

          return router.events.pipe(
            filter((event) => event instanceof RoutesRecognized),
            take(1),
            map(() => {
              return keycloak.init({
                config: {
                  url: config?.url,
                  realm: config?.realm,
                  clientId: config?.client
                },
                initOptions: {
                  enableLogging: true,
                  checkLoginIframe: false
                }
              });
            })
          );
        })
      )
    );
}
