import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { tap, Observable, throwError } from 'rxjs';

import { RealmService } from './realm.service';

import { environment } from '~/core/core.module';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateService {
  constructor(private http: HttpClient) {}

  private static buildImpersonateRequestBody(
    username: string,
    keycloakClientId: string,
    currentUserAccessToken: string
  ): URLSearchParams {
    const body = new URLSearchParams();
    body.set('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
    //body.set('requested_token_type', 'urn:ietf:params:oauth:token-type:access_token'); // only request access token
    body.set('subject_token', currentUserAccessToken);
    body.set('client_id', keycloakClientId);
    body.set('audience', keycloakClientId);
    body.set('requested_subject', username);
    body.set('scope', 'openid');

    return body;
  }

  private static getOptions() {
    return {
      headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    };
  }

  public impersonate(username: string, keycloak: KeycloakService, realmService: RealmService): Observable<Object> {
    const config = realmService.current;
    if (!config) {
      return throwError(() => new Error('Keycloak initialization failed'));
    }

    const body = ImpersonateService.buildImpersonateRequestBody(
      username,
      config?.client,
      keycloak.getKeycloakInstance().token
    );

    return this.http
      .post<never>(
        config?.url + '/realms/' + config?.realm + '/protocol/openid-connect/token',
        body.toString(),
        ImpersonateService.getOptions()
      )
      .pipe(
        tap((response) => {
          //console.log(JSON.stringify(response, null, 2));
          //console.log('NEW token : ' + response['access_token']);
          //console.log('OLD token : ' + keycloak.getKeycloakInstance().token);

          environment.USERNAME_AS = username.toLowerCase();
          environment.USERNAME_AS_BEARER_TOKEN = response['access_token'];

          //console.log(JSON.stringify(environment, null, 2));
        })
      );
  }
}
