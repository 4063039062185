<p-skeleton *ngIf="!loaded; else header" width="100%" height="3.75rem" styleClass="fixed"></p-skeleton>

<ng-template #header>
  <header>
    <nav
      class="fixed navbar theme-background-color flex justify-content-between align-items-center px-3 lg:px-5 w-full z-4"
      aria-label="Main menu">
      <!-- Navbar overlay when editMode is active in dashboard -->
      <div
        *ngIf="editModeEnabled"
        class="absolute w-full h-full left-0 p-component-overlay p-sidebar-mask p-component-overlay-enter z-1"></div>
      <div class="flex align-items-center">
        <a
          *ngIf="theme?.logo"
          [routerLink]="['/']"
          class="navbar-icon flex align-items-center h-2-5rem max-w-5rem mr-2 cursor-pointer"
          title="home">
          <img [src]="'data:image/png;base64,' + theme?.logo" class="w-full h-auto max-h-full"
        /></a>
        <span
          *ngIf="userHasRoles"
          class="p-buttonset p-buttonset-nav hidden md:flex"
          [ngClass]="{
            'ml-4': theme?.logo
          }">
          <a
            class="p-button theme-alternating-color theme-border-color theme-text-color"
            [routerLink]="['suiteview', 'objecttypes']"
            [title]="'new.route' | translate">
            {{ 'new.route' | translate }}
          </a>
          <button
            #favouritesToggle
            class="theme-alternating-color theme-border-color theme-text-color"
            pButton
            type="button"
            icon="fa-solid fa-star"
            (click)="toggleFavourites()"
            [title]="'common.favourites' | translate">
            <app-favourites
              [visible]="favouritesVisible"
              [menuItems]="starredMenuItems"
              [button]="favouritesToggle"
              (hide)="toggleFavourites()"></app-favourites>
          </button>
        </span>
        <a
          [routerLink]="['suiteview', 'calendar', 'show']"
          class="theme-text-color no-underline mx-4 cursor-pointer hidden md:block"
          [title]="'calendar.route' | translate"
          >{{ 'calendar.route' | translate }}</a
        >
      </div>
      <!-- Desktop menu items -->
      <div class="hidden lg:flex align-items-center h-full">
        <i class="fa-regular fa-arrows-rotate theme-text-color fa-spin mr-5 text-xl" *ngIf="isPageLoading()"></i>

        <ng-container [ngTemplateOutlet]="searchBar"></ng-container>

        <p-button
          styleClass="p-button-navbar-icon"
          *ngIf="countMeetingPointers > 0"
          (click)="onShowMeetingPointers()"
          [title]="countMeetingPointers + ' ' + ('current.meetings' | translate)"
          type="button"
          aria-haspopup="menu">
          <span class="p-overlay-badge">
            <i class="fa-solid fa-calendar-check theme-text-color"></i>
            <p-badge
              *ngIf="countMeetingPointers > 0"
              [value]="countMeetingPointers + ''"
              severity="danger"
              styleClass="theme-alternating-color theme-text-color" />
          </span>
        </p-button>

        <p-button
          styleClass="p-button-navbar-icon"
          *ngIf="countAnnouncements > 0"
          (click)="onShowAnnouncements()"
          [title]="countAnnouncements + ' ' + ('information.announcements.title' | translate)">
          <span class="p-overlay-badge">
            <i class="fa-solid fa-bell theme-text-color"></i>
            <p-badge
              *ngIf="countAnnouncements > 0"
              [value]="countAnnouncements + ''"
              severity="danger"
              styleClass="theme-alternating-color theme-text-color" />
          </span>
        </p-button>

        <p-button
          styleClass="p-button-navbar-icon overflow-visible"
          *ngIf="printListModule"
          [routerLink]="['suiteview', 'print']"
          [pTooltip]="printListModule.tooltip"
          [escape]="false"
          tooltipPosition="bottom"
          tooltipZIndex="5"
          [title]="'information.printlist.title' | translate">
          <span class="p-overlay-badge">
            <i class="fa-solid fa-mailbox theme-text-color"></i>
            <p-badge
              *ngIf="printListModule.urgent || printListModule.counter > 0"
              [value]="printListModule.urgent ? '!' : printListModule.counter + ''"
              severity="danger"
              styleClass="theme-alternating-color theme-text-color" />
          </span>
        </p-button>

        <a
          class="p-button p-button-navbar-icon no-underline"
          *ngIf="calculationModule && calculationModule.url"
          [routerLink]="['suiteview', 'calculation-module']"
          [title]="'information.calculation.title' | translate">
          <i class="fa-solid fa-calculator theme-text-color"></i>
        </a>

        <a
          class="p-button p-button-navbar-icon overflow-visible no-underline"
          [routerLink]="['ROUTES.userdata', 'ROUTES.generatedfiles'] | localize"
          [title]="exportsLength + ' ' + ('userdata.generatedfiles.title' | translate)">
          <span class="p-overlay-badge">
            <i class="fa-solid fa-arrow-down-to-line theme-text-color"></i>
            <p-badge
              *ngIf="exportsLength > 0"
              [value]="exportsLength + ''"
              severity="danger"
              styleClass="theme-alternating-color theme-text-color" />
          </span>
        </a>

        <p-button
          *ngIf="voting$ | async"
          styleClass="p-button-navbar-icon"
          (click)="showVotingDialog()"
          [title]="'voting.vote.title' | translate">
          <i class="fa-solid fa-check-to-slot theme-text-color"></i>
        </p-button>

        <app-tiered-menu-button
          *ngIf="helpMenuItems && helpMenuItems.length > 0"
          [label]="'information.help.title' | translate"
          iconRight="fa-solid fa-chevron-down"
          textClass="theme-text-color"
          [menuItems]="helpMenuItems"></app-tiered-menu-button>

        <app-tiered-menu-button
          *ngIf="userMenuItems && userMenuItems.length > 0"
          [label]="buildLabel()"
          buttonClass="p-button-text white-space-nowrap"
          textClass="theme-text-color"
          [iconLeft]="userIcon"
          iconRight="fa-solid fa-chevron-down"
          [menuItems]="userMenuItems"></app-tiered-menu-button>
        <app-tiered-menu-button
          *ngIf="configurationMenuItems && configurationMenuItems.length > 0"
          buttonClass="p-button-text"
          textClass="theme-text-color"
          iconLeft="fa-solid fa-ellipsis-vertical"
          [menuItems]="configurationMenuItems"
          [title]="'information.configuration.title' | translate">
        </app-tiered-menu-button>
      </div>

      <!-- Open mobile menu handle -->
      <div class="flex align-items-center relative lg:hidden">
        <div class="p-buttonset p-buttonset-nav button-new-starred" *ngIf="userHasRoles">
          <button
            pButton
            class="p-button p-button-icon-only theme-alternating-color theme-border-color theme-text-color md:hidden"
            icon="fa-regular fa-plus"
            [routerLink]="['suiteview', 'objecttypes']"
            [title]="'new.route' | translate"></button>
        </div>

        <a
          pButton
          class="p-button p-button-navbar-icon no-underline theme-text-color md:hidden"
          icon="fa-regular fa-calendar"
          [routerLink]="['suiteview', 'calendar', 'show']"
          [title]="'calendar.route' | translate"></a>

        <ng-container [ngTemplateOutlet]="searchBar"></ng-container>
        <button
          type="button"
          pButton
          icon="fa-solid fa-bars"
          class="p-button-navbar-icon theme-text-color"
          [title]="'common.menu' | translate"
          (click)="toggleMenu()"></button>
      </div>
    </nav>
    <p-sidebar
      class="lg:hidden navbar theme-background-color"
      [visible]="menuOpen"
      [showCloseIcon]="false"
      (visibleChange)="visibleChange.emit($event)"
      position="right"
      styleClass="w-full sm:w-24rem">
      <ng-template pTemplate="header">
        <div class="flex align-items-center justify-content-between w-full theme-background-color">
          <span class="text-lg text-white font-bold">Menu</span>
          <button
            type="button"
            pButton
            icon="fa-solid fa-xmark"
            class="p-button-navbar-icon ml-4"
            (click)="toggleMenu()"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <p-panelMenu [model]="mobileMenuItems" [style]="{ width: 'w-full' }"></p-panelMenu>
      </ng-template>
    </p-sidebar>
  </header>
</ng-template>

<ng-template #searchBar>
  <div class="relative h-full">
    <app-navbar-live-search [inNavbar]="true" (triggerSearch)="triggerSearch.emit($event)"></app-navbar-live-search>
  </div>
</ng-template>
