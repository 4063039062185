import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IMessage, RxStompState } from '@stomp/rx-stomp';
import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

import { VotingLetter, VotingMessage, VotingResult } from './voting.types';

import { suiteStompServiceFactory } from '~/core/websockets/suite-stomp.factory';

@Injectable({
  providedIn: 'root'
})
export class VotingService implements OnDestroy {
  private isVotingDialogOpen$ = new BehaviorSubject<boolean>(false);
  private isVotingResultOpen$ = new BehaviorSubject<boolean>(false);
  private letter$ = new BehaviorSubject<VotingLetter>(null);
  private result$ = new BehaviorSubject<VotingResult>(null);

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly http: HttpClient) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  init() {
    const suiteStomp = suiteStompServiceFactory();
    const suiteStompConnected$ = suiteStomp.connectionState$.pipe(
      filter((currentState: RxStompState) => {
        return currentState === RxStompState.OPEN;
      }),
      takeUntil(this.destroyed$)
    );

    suiteStompConnected$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      suiteStomp
        .createSubscription('/user/event/voting/letter')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((msg: IMessage) => {
          const data = JSON.parse(msg.body);

          if (data.closeVoting) {
            this.votingDialogOpen = false;
            this.votingLetter = null;
          } else if (data.voteRegistered) {
            console.log('Vote registered');
            // TODO
          } else {
            this.votingDialogOpen = true;
            this.votingResultDialogOpen = false;
            this.votingLetter = data;
          }
        });
      suiteStomp
        .createSubscription('/user/event/voting/result')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((msg: IMessage) => {
          const data = JSON.parse(msg.body);
          this.votingResultDialogOpen = false;
          this.votingDialogOpen = false;
          if (!data.closeVotingResultDetails) {
            this.votingResult = data;
            setTimeout(() => {
              this.votingResultDialogOpen = true;
            }, 100);
          }
        });
    });
  }

  get votingLetter$() {
    return this.letter$.asObservable();
  }
  set votingLetter(letter: VotingLetter) {
    this.letter$.next(letter);
  }

  get votingDialogOpen$() {
    return this.isVotingDialogOpen$.asObservable();
  }
  set votingDialogOpen(open: boolean) {
    this.isVotingDialogOpen$.next(open);
  }

  get votingResult$() {
    return this.result$.asObservable();
  }
  set votingResult(result: VotingResult) {
    this.result$.next(result);
  }

  get votingResultDialogOpen$() {
    return this.isVotingResultOpen$.asObservable();
  }
  set votingResultDialogOpen(open: boolean) {
    this.isVotingResultOpen$.next(open);
  }

  public submitVoting(voting: VotingMessage): Observable<string> {
    return this.http.post<string>(`${environment.BACKEND_URL}/voting/vote`, voting);
  }
}
