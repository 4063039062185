import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { map, take } from 'rxjs';

import { SUITEVIEWPATH } from '~/core/core.routing';
import { SearchService } from '~/repositories/search/services/search.service';
import { ILiveSearch, ILiveSearchResult } from '~/repositories/search/store/search.types';
import { SearchFacade } from '~/search/facades/search.facade';

@Component({
  selector: 'app-navbar-live-search',
  templateUrl: './live-search.component.html',
  styleUrls: ['./live-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveSearchComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const target = event.target as Element;
    if (event.key === '/' && target.tagName === 'BODY' && !this.searchOpen) {
      event.preventDefault();
      this.openSearch(true, 'search-autocomplete');
    }
  }

  @Input() inNavbar: boolean;
  @Input() kkcFilter: string;

  @Output() triggerSearch = new EventEmitter<string>();

  public searchForm: FormGroup;
  public searchOpen = signal<boolean>(false);
  public suiteViewPath = SUITEVIEWPATH;

  suggestions = signal<ILiveSearchResult[]>([]);

  constructor(
    private searchService: SearchService,
    private searchFacade: SearchFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
  }

  liveSearch(event: AutoCompleteCompleteEvent, kccFilter: string) {
    this.searchService
      .getLiveSearch(event.query, kccFilter)
      .pipe(
        map((result: ILiveSearch) => result.results),
        take(1)
      )
      .subscribe((items) => {
        this.suggestions.set(items);
      });
  }

  public openSearch(open: boolean, el?: string) {
    this.searchOpen.set(open);
    open && document.getElementById(el).focus();
  }

  public openAutoComplete() {
    this.searchOpen.set(true);
  }

  public closeAutoComplete(event: any) {
    setTimeout(() => {
      this.searchOpen.set(false);
      this.searchForm.reset();
      this.changeDetectorRef.detectChanges();
      this.closeLiveSearch(event);
    }, 250);
  }

  public onSearchClicked(event: any) {
    this.triggerSearch.emit(this.searchForm.get('search').value);
    this.searchFacade.clearLoadedSavedQuery();
    this.searchForm.controls['search'].reset();
    this.searchOpen.set(false);

    this.closeLiveSearch(event);
  }

  // Maarten: needed to remove focus from autocomplete
  closeLiveSearch(event: any) {
    event.target.blur();
    document.body.click();
    document.body.blur();
  }
}
