import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { PanelModule } from 'primeng/panel';

import { environment } from '../../environments/environment';
import { UiModule } from '../ui/ui.module';

import { ScreenSize } from './config/widgets.const';
import { CORE_ROUTES } from './core.routing';
import { ErrorComponent } from './error/error.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { GlobalErrorHandler } from './global-error-handler.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { suiteStompServiceFactory } from './websockets/suite-stomp.factory';
import { SuiteStompService } from './websockets/suite-stomp.service';

import { rxStompServiceFactory } from '~/core/websockets/rx-stomp.factory';
import { RxStompService } from '~/core/websockets/rx-stomp.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(CORE_ROUTES),
    LocalizeRouterModule.forChild(CORE_ROUTES),
    UiModule,

    TranslateModule,

    PanelModule
  ],
  exports: [RouterModule, LocalizeRouterModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory
    },
    {
      provide: SuiteStompService,
      useFactory: suiteStompServiceFactory
    }
  ],
  declarations: [ErrorComponent, NotFoundComponent, ForbiddenComponent]
})
export class CoreModule {}
export { ScreenSize, environment };
