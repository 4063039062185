/**
 * Because the environment.ts file is replaced on a production build
 * it is safer to provide a base that other environment files can extend on.
 *
 * This way the base is not removed in a production environment.
 */

import { IAppEnvironment } from './environment.types';

export const base: IAppEnvironment = {
  PRODUCTION: false,
  DEVELOP: false,
  BACKEND_URL: '/suite-backend',
  OLD_BACKEND_URL: '/suite',
  WS_URL: 'wss://' + location.host + '/suite-backend/websocket',
  WS_URL_SUITE: 'wss://' + location.host + '/suite/websocket',
  USERNAME: null,
  USERNAME_AS: null,
  USERNAME_AS_BEARER_TOKEN: null,
  USERID: null,
  BEARER_TOKEN: null
};
