import { environment } from '../../../environments/environment';

import { suiteStompConfig } from '~/core/websockets/suite-stomp.config';
import { SuiteStompService } from '~/core/websockets/suite-stomp.service';

export function suiteStompServiceFactory() {
  const suiteStomp = new SuiteStompService();

  if (environment.USERNAME == null) return suiteStomp;

  suiteStompConfig.connectHeaders['login'] = environment.USERNAME;
  suiteStompConfig.connectHeaders['passcode'] = environment.BEARER_TOKEN;

  suiteStomp.configure(suiteStompConfig);
  suiteStomp.activate();
  return suiteStomp;
}
