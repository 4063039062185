import { Pipe, PipeTransform } from '@angular/core';
import { utc } from 'moment-timezone';

@Pipe({
  name: 'getFormattedDate'
})
export class getFormattedDate implements PipeTransform {
  transform(input: string, outputFormat?: string, inputFormat?: string): string {
    return utc(input, inputFormat)
      .tz('Europe/Brussels')
      .format(outputFormat || inputFormat);
  }
}
