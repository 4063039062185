import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, Observable, of } from 'rxjs';

import { UserDataService } from '../services/userdata.service';

import {
  fetchAsyncUserTasks,
  fetchAsyncUserTasksFailure,
  fetchAsyncUserTasksSuccess,
  deleteAsyncUserTask,
  deleteAsyncUserTaskSuccess,
  deleteAsyncUserTaskFailure,
  fetchAllRolesActive,
  fetchAllRolesActiveSuccess,
  fetchAllRolesActiveFailure
} from './userdata.actions';

@Injectable()
export class UserDataEffects {
  constructor(private readonly actions$: Actions, private readonly userDataService: UserDataService) {}

  fetchAsyncUserTasks$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchAsyncUserTasks),
        mergeMap(() =>
          this.userDataService.fetchAsyncUserTasks().pipe(
            map((data) =>
              fetchAsyncUserTasksSuccess({
                data
              })
            ),
            catchError((error: any) => of(fetchAsyncUserTasksFailure({ error })))
          )
        )
      )
  );

  deleteAsyncUserTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAsyncUserTask),
      mergeMap(({ id }) =>
        this.userDataService.deleteAsyncUserTask(id).pipe(
          map(() => deleteAsyncUserTaskSuccess({ id })),
          catchError((error) => of(deleteAsyncUserTaskFailure({ error })))
        )
      )
    )
  );

  fetchAllRolesActive$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchAllRolesActive),
        mergeMap(() =>
          this.userDataService.fetchAllRolesActive().pipe(
            map((allRolesActive) =>
              fetchAllRolesActiveSuccess({
                allRolesActive
              })
            ),
            catchError((error: any) => of(fetchAllRolesActiveFailure({ error })))
          )
        )
      )
  );
}
