import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { MenuItem } from 'primeng/api';
import { Subject, combineLatest, distinctUntilChanged, map, take, takeUntil } from 'rxjs';

import { environment } from './core/core.module';
import { MobileMenuService } from './mobile-menu.service';
import { ThemeFacade } from './repositories/theme/store/theme.facade';
import { IHelpItem } from './repositories/theme/store/theme.models';
import { WalkthroughService } from './walkthrough.service';

@Injectable({
  providedIn: 'root'
})
export class HelpMenuService implements OnDestroy {
  public helpMenuItems$ = new Subject<MenuItem[]>();

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private themeFacade: ThemeFacade,
    private translateService: TranslateService,
    private mobileMenuService: MobileMenuService,
    private walkthroughService: WalkthroughService
  ) {}

  public init() {
    combineLatest([this.themeFacade.helpInformation$, this.walkthroughService.walkthroughForCurrentRoute$])
      .pipe(
        map(([helpInformation, currentWalkthrough]) => ({ helpInformation, currentWalkthrough })),
        distinctUntilChanged(isEqual),
        map(({ helpInformation, currentWalkthrough }) => {
          const items =
            helpInformation?.map((item: IHelpItem) => {
              if (item.cmsId) {
                return {
                  label: item.name,
                  command: () => {
                    this.mobileMenuService.mobileMenuOpen = false;
                    this.httpClient
                      .get(`${environment.BACKEND_URL}/file/download/cmsobjectid/${item.cmsId}/cmsfiletype/PDF`, {
                        responseType: 'blob'
                      })
                      .pipe(take(1))
                      .subscribe((blob) => {
                        const blobUrl = URL.createObjectURL(blob);

                        // temp link to download file
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.download = item.name;
                        link.click();
                        link.remove();
                      });
                  }
                };
              }

              return {
                label: item.name,
                url: item.url
              };
            }) || [];

          currentWalkthrough &&
            items?.push({
              label: this.translateService.instant('walkthrough.title'),
              command: () => {
                this.walkthroughService.showWalkthroughByMenu();
              }
            });

          return items;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((items) => {
        this.mobileMenuService.setMobileMenuItemAtIndex(
          {
            label: this.translateService.instant('information.help.title'),
            items: items,
            icon: 'fa-regular fa-question'
          },
          5
        );

        this.helpMenuItems$.next(items);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
