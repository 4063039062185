import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, mergeMap, of } from 'rxjs';

import { environment } from '~/core/core.module';

export interface Config {
  url: string;
  realm: string;
  client: string;
  contentSecurityPolicyUrls: string;
}

@Injectable({
  providedIn: 'root'
})
export class RealmService {
  private config?: Config;

  get current(): Config {
    if (this.config === undefined) {
      throw new Error('Config has not been loaded. Did you forget to call load() ?');
    }
    return this.config;
  }

  constructor(private httpClient: HttpClient) {}

  public getConfig(): Observable<Config> {
    return this.httpClient.get<Config>(`${environment.BACKEND_URL}/realm`).pipe(
      catchError((error) => {
        console.log(error);
        return of(null);
      }),
      mergeMap((response) => {
        if (response) {
          this.config = response;
          return of(this.config);
        } else {
          return of(null);
        }
      })
    );
  }
}
