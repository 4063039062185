<p-dialog
  [(visible)]="visible"
  header="{{ 'information.announcements.title' | translate }}"
  [style]="{ 'min-width': '40vw', 'max-width': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  contentStyleClass="p-0"
  (onHide)="onClose()">
  <div *ngFor="let announcement of announcements">
    <div class="font-semibold text-base bg-gray-200 px-4 py-3">
      {{ announcement.title }}
    </div>
    <div class="px-4" [innerHTML]="announcement.content"></div>
    <div class="px-4 py-3">
      <i class="fa-regular fa-calendar text-gray-600 mr-1"></i>
      {{ announcement.period }}
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-start">
      <button type="button" pButton label="{{ 'common.button.close' | translate }}" (click)="onClose()"></button>
    </div>
  </ng-template>

  <ng-template pTemplate="closeicon">
    <div
      class="p-dialog-header-icon"
      [title]="
        'dialog.button.close'
          | translate
            : {
                '0': 'information.announcements.title' | translate
              }
      ">
      <i class="text-lg fa-regular fa-xmark text-gray-800"></i>
    </div>
  </ng-template>
</p-dialog>
