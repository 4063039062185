import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as WidgetsActions from './widgets.actions';
import { ListOption, WidgetConfigEntity, WidgetTypeEntity } from './widgets.models';

export const WIDGETS_FEATURE_KEY = 'widgets';

export interface WidgetsState extends EntityState<WidgetConfigEntity> {
  selectedId?: string | number; // which Widgets record has been selected
  loaded: boolean; // has the Widgets list been loaded
  error?: string | null; // last known error (if any)
  widgetTypes: WidgetTypeEntity[];
  updateOrderLoading?: boolean;
  updateOrderError?: string | null;
  removeWidgetLoading?: boolean;
  removeWidgetError?: string | null;
  actionAssignees: ListOption[];
  actionAssignedTasks: number;
  actionAssigneesLoading: boolean;
  actionEmitLoading: boolean;
}

export interface IState {
  tabs: {
    selectedId: string;
  };
  widgets: WidgetsState;
}

export interface WidgetsPartialState {
  readonly [WIDGETS_FEATURE_KEY]: WidgetsState;
}

export const widgetsAdapter: EntityAdapter<WidgetConfigEntity> = createEntityAdapter<WidgetConfigEntity>();

export const initialWidgetsState: WidgetsState = widgetsAdapter.getInitialState({
  // set initial required properties
  widgetTypes: [],
  loaded: false,
  updateOrderLoading: false,
  actionAssigneesLoading: false,
  actionAssignees: [],
  actionAssignedTasks: 0,
  actionEmitLoading: false
});

const reducer = createReducer(
  initialWidgetsState,
  on(WidgetsActions.getWidgetById, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(WidgetsActions.loadWidgetSuccess, (state, { widget }) =>
    widgetsAdapter.setOne(widget, {
      ...state,
      loaded: true
    })
  ),
  on(WidgetsActions.loadWidgetFailure, (state, { error, id }) =>
    widgetsAdapter.setOne(
      { id, error, expanded: false },
      {
        ...state,
        loaded: true
      }
    )
  ),
  on(WidgetsActions.getWidgetsById, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(WidgetsActions.loadWidgetsSuccess, (state, { widgets }) =>
    widgetsAdapter.upsertMany(widgets, {
      ...state,
      loaded: true
    })
  ),
  on(WidgetsActions.loadWidgetsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(WidgetsActions.updateWidgetConfigSuccess, (state, { widget }) =>
    widgetsAdapter.updateOne(
      {
        changes: widget,
        id: widget.id
      },
      state
    )
  ),
  on(WidgetsActions.updateWidgetConfigFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(WidgetsActions.loadWidgetTypesSuccess, (state, { widgetTypes }) => ({
    ...state,
    widgetTypes: widgetTypes.map((type) => ({
      ...type,
      actions: type.actions?.map((action) => ({
        ...action,
        widgetType: type.code
      }))
    }))
  })),
  on(WidgetsActions.loadWidgetTypesFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(WidgetsActions.updateWidgetsOrder, (state) => ({
    ...state,
    updateOrderLoading: true
  })),
  on(WidgetsActions.updateWidgetsOrderSuccess, (state, { widgets }) => {
    const changes = widgets.map((widget) => {
      return {
        id: widget.id,
        changes: {
          sortOrder: widget.sortOrder
        }
      };
    });
    return widgetsAdapter.updateMany(changes, {
      ...state,
      updateOrderLoading: false,
      updateOrderError: null
    });
  }),
  on(WidgetsActions.updateWidgetsOrderFailure, (state, { error }) => ({
    ...state,
    updateOrderError: error,
    updateOrderLoading: false
  })),
  on(WidgetsActions.removeWidget, (state) => ({
    ...state,
    removeWidgetLoading: true
  })),
  on(WidgetsActions.removeWidgetSuccess, (state, { widgetId }) => {
    return widgetsAdapter.removeOne(widgetId, {
      ...state,
      removeWidgetLoading: false,
      selectedId: state.ids.map((id) => id.toString()).filter((id) => id !== widgetId)[0]
    });
  }),
  on(WidgetsActions.removeWidgetFailure, (state, { error }) => ({
    ...state,
    removeWidgetError: error
  })),
  on(WidgetsActions.emitAction, (state, { widget }) =>
    widgetsAdapter.updateOne(
      {
        changes: {
          actionEmitLoading: true
        },
        id: widget.id
      },
      {
        ...state,
        actionEmitLoading: true
      }
    )
  ),
  on(WidgetsActions.emitActionSuccess, (state, { widget }) =>
    widgetsAdapter.updateOne(
      {
        changes: {
          actionEmitLoading: false
        },
        id: widget.id
      },
      {
        ...state,
        actionEmitLoading: false
      }
    )
  ),
  on(WidgetsActions.emitActionFailure, (state, { widget }) =>
    widgetsAdapter.updateOne(
      {
        changes: {
          actionEmitLoading: false
        },
        id: widget.id
      },
      {
        ...state,
        actionEmitLoading: false
      }
    )
  ),
  on(WidgetsActions.getActionAssignees, (state) => ({
    ...state,
    actionAssignees: [],
    actionAssigneesLoading: true
  })),
  on(WidgetsActions.getActionAssigneesSuccess, (state, { data }) => ({
    ...state,
    actionAssignees: data.assignees,
    actionAssignedTasks: data.countAssignedTasks,
    actionAssigneesLoading: false
  })),
  on(WidgetsActions.getActionAssigneesFailure, (state, { error }) => ({
    ...state,
    actionAssigneesLoading: false,
    error
  }))
);

export function widgetsReducer(state: WidgetsState | undefined, action: Action) {
  return reducer(state, action);
}
