import { Routes } from '@angular/router';

import { ErrorComponent } from './error/error.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { SuiteViewComponent } from '~/core/suite-view/suite-view.component';

export const SUITEVIEWPATH = 'suiteview';

export const CORE_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('~/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    resolve: [],
    data: {
      key: 'dashboard'
    }
  },
  {
    path: 'gvb',
    component: ForbiddenComponent
  },
  {
    path: 'ROUTES.search',
    loadChildren: () => import('~/search/search.module').then((mod) => mod.SearchModule),
    data: {
      key: 'search'
    }
  },
  {
    path: 'ROUTES.userdata',
    loadChildren: () => import('~/userdata/userdata.module').then((mod) => mod.UserdataModule),
    data: {
      key: 'userdata'
    }
  },
  {
    path: 'ROUTES.writtenquestionspublish',
    loadChildren: () =>
      import('~/written-questions/written-questions-publish/written-questions-publish.module').then(
        (mod) => mod.WrittenQuestionsPublishModule
      ),
    data: {
      key: 'writtenquestionspublish'
    }
  },
  {
    path: 'ROUTES.holiday',
    loadChildren: () => import('~/holiday/holiday.module').then((mod) => mod.HolidayModule),
    data: {
      key: 'holiday'
    }
  },
  {
    path: SUITEVIEWPATH,
    children: [
      {
        path: '**',
        component: SuiteViewComponent,
        runGuardsAndResolvers: 'always'
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'ROUTES.archive',
    loadChildren: () => import('~/archive/archive.module').then((mod) => mod.ArchiveModule),
    data: {
      key: 'archive'
    }
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '403',
    component: ForbiddenComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
