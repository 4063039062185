import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { IConfigurationItem, IFavourite, IFooter, IHelpItem, ThemeEntity } from '../store/theme.models';

@Injectable()
export class ThemeService {
  constructor(private readonly http: HttpClient) {}

  public getThemeForOrganisation(organisation: number | string): Observable<ThemeEntity> {
    // TODO: get theme by organisation
    return this.http.get<ThemeEntity>(`${environment.BACKEND_URL}/layout`);
  }

  public getFavourites(): Observable<IFavourite[]> {
    return this.http.get<IFavourite[]>(`${environment.BACKEND_URL}/navigation/types/favourites`);
  }

  public getHelpInformation(): Observable<IHelpItem[]> {
    return this.http.get<IHelpItem[]>(`${environment.BACKEND_URL}/navigation/helpinformation`);
  }

  public getFooterInformation(): Observable<IFooter> {
    return this.http.get<IFooter>(`${environment.BACKEND_URL}/navigation/footer`);
  }

  public getConfigurationItems(): Observable<IConfigurationItem[]> {
    return this.http.get<IConfigurationItem[]>(`${environment.BACKEND_URL}/navigation/configuration`);
  }
}
