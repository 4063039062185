import { Component, OnDestroy, signal } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, take, takeUntil } from 'rxjs';

import { VotingLetter, VotingLetterCouncilMember } from '../voting.types';

import { IVoteForCouncilMember } from './dialogs/voting-dialog/voting-dialog.types';

import { VotingService } from '~/voting/voting.service';

@Component({
  selector: 'app-voting',
  templateUrl: './voting.component.html',
  styleUrls: ['./voting.component.scss']
})
export class VotingComponent implements OnDestroy {
  //public votingLetter: VotingLetter;
  public showVotingLetterDialog$ = this.votingService.votingDialogOpen$;
  public letter$ = this.votingService.votingLetter$;
  public showVotingResultDialog$ = this.votingService.votingResultDialogOpen$;
  public result$ = this.votingService.votingResult$;

  public votingResultWindowOpen = false;
  public keepVotingWindowOpen = false;
  public councilMembers: VotingLetterCouncilMember[] = [];

  letter = signal<VotingLetter>(null);
  votingOptions = signal<{ label: string; value: string }[]>([]);
  activeMemberIndex = signal<number>(0);
  submitVoteLoading = signal<boolean>(false);
  submitVoteError = signal<boolean>(false);

  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private votingService: VotingService, private messageService: MessageService) {
    this.letter$.pipe(takeUntil(this.destroyed$)).subscribe((letter) => this.letter.set(letter));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  votingDialogOpen() {
    this.votingService.votingDialogOpen = true;
  }

  toggleVotingDialog(open: boolean) {
    this.votingService.votingDialogOpen = open;
  }

  toggleVotingResultDialog(open: boolean) {
    this.votingService.votingResultDialogOpen = open;
  }

  handleSubmitVoting({
    voting,
    closeDialog = true,
    nextCouncilMember
  }: {
    voting: IVoteForCouncilMember;
    closeDialog?: boolean;
    nextCouncilMember?: number;
  }) {
    const { selectedCandidates, resultType } = voting;

    this.submitVoteLoading.set(true);

    let votes = [];

    if (voting.selectedCandidates?.length) {
      votes = selectedCandidates;
    } else {
      votes = [
        {
          resultType
        }
      ];
    }

    // TODO: letter is undefined
    this.votingService
      .submitVoting({
        letterId: this.letter().id,
        councilMemberStatusId: this.letter().councilMembers[this.activeMemberIndex()].id,
        votes
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.submitVoteError.set(false);
          if (nextCouncilMember) {
            this.activeMemberIndex.set(nextCouncilMember);
            this.messageService.add({
              key: 'votes',
              severity: 'success',
              detail: `Uw stem is successvol geregistreerd, gelieve ook nog te stemmen voor test`
            });
          } else {
            if (closeDialog) {
              this.votingService.votingDialogOpen = false;
            }
          }
        },
        error: () => {
          this.submitVoteError.set(true);
          this.messageService.add({
            key: 'votes',
            severity: 'error',
            detail: `Er ging iets mis bij het verzenden van de stemming`
          });
        }
      })
      .add(() => {
        this.submitVoteLoading.set(false);
      });
  }
}
