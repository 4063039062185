export enum Widgets {
  CASE_TASKS = 'CASE_TASKS',
  CONTACT_TASKS = 'CONTACT_TASKS',
  DECREE_TASKS = 'DECREE_TASKS',
  DECREE_CONCEPTS = 'DECREE_CONCEPTS',
  DECREE_FILES = 'DECREE_FILES',
  ROI_TASKS = 'ROI_TASKS',
  ROI_CONCEPTS = 'ROI_CONCEPTS',
  ROI_SUBMITTED = 'ROI_SUBMITTED',
  ROI_NOT_SUBMITTED = 'ROI_NOT_SUBMITTED'
}

export enum ScreenSize {
  PERCENTAGE_100 = 'col-12 px-0 lg:px-2',
  PERCENTAGE_50 = 'col-12 px-0 lg:px-2 xl:col-6'
}
