import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericWidgetDataResponse, PaginatedItemsParams } from '../store/generic-widget-data.types';

import { environment } from '~/core/core.module';
import { FilterEntity } from '~/repositories/widgets/store/widgets.models';

@Injectable({
  providedIn: 'root'
})
export class GenericWidgetDataService {
  constructor(private readonly http: HttpClient) {}

  public getDataByType(type: string, params: PaginatedItemsParams): Observable<GenericWidgetDataResponse> {
    return this.http.post<GenericWidgetDataResponse>(`${environment.BACKEND_URL}/dashboard/widgetdata`, {
      typeCode: type,
      pageNumber: params.currentPage,
      pageSize: params.currentItems,
      sortFieldCode: params.selectedSortField,
      sortDirectionCode: params.selectedSortDirection,
      fixedFilter: params.selectedFixedFilter,
      searchFilters: params?.selectedSearchFields?.reduce((acc: { [key: string]: string }, curr: FilterEntity) => {
        acc[curr.code] = curr.value;
        return acc;
      }, {}),
      configFields: params.selectedConfigFields?.reduce((acc: { [key: string]: string[] }, curr: FilterEntity) => {
        acc[curr.code] ? acc[curr.code].push(curr.value) : (acc[curr.code] = [curr.value]);
        return acc;
      }, {})
    });
  }
}
