import { createAction, props } from '@ngrx/store';

import { IFavourite, IHelpItem, IConfigurationItem, ThemeEntity, IFooter } from './theme.models';

export const initTheme = createAction('[Theme Page] Init', props<{ organisation: number }>());

export const loadThemeSuccess = createAction('[Theme/API] Load Theme Success', props<{ theme: ThemeEntity[] }>());

export const loadThemeFailure = createAction('[Theme/API] Load Theme Failure', props<{ error: any }>());

export const loadFavourites = createAction('[Favourites/API] Load Favourites');

export const loadFavouritesSuccess = createAction(
  '[Favourites/API] Load Favourites Success',
  props<{ favourites: IFavourite[] }>()
);

export const loadFavouritesFailure = createAction('[Favourites/API] Load Favourites Failure', props<{ error: any }>());

export const loadHelpInformation = createAction('[HelpInformation/API] Load HelpInformation');

export const loadHelpInformationSuccess = createAction(
  '[HelpInformation/API] Load HelpInformation Success',
  props<{ helpInformationItems: IHelpItem[] }>()
);

export const loadHelpInformationFailure = createAction(
  '[Favourites/API] Load Favourites Failure',
  props<{ error: any }>()
);

export const loadFooterInformation = createAction('[FooterInformation/API] Load FooterInformation');

export const loadFooterInformationSuccess = createAction(
  '[FooterInformation/API] Load FooterInformation Success',
  props<{ footerInformation: IFooter }>()
);

export const loadFooterInformationFailure = createAction(
  '[Favourites/API] Load Favourites Failure',
  props<{ error: any }>()
);

export const loadConfigurationItems = createAction('[ConfigurationItems/API] Load Configuration Items');

export const loadConfigurationItemsSuccess = createAction(
  '[ConfigurationItems/API] Load Configuration Items Success',
  props<{ configurationItems: IConfigurationItem[] }>()
);

export const loadConfigurationItemsFailure = createAction(
  '[ConfigurationItems/API] Load Configuration Items Failure',
  props<{ error: any }>()
);
