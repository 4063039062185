<p-dialog
  [(visible)]="visible"
  header="{{ 'voting.results' | translate }}"
  [style]="{ 'min-width': '40vw', 'max-width': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  contentStyleClass="p-0"
  (onHide)="onClose()">
  <div class="px-4">
    <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
  </div>
  <div class="px-4">
    <canvas id="chart">{{ chart() }}</canvas>
  </div>
  <div id="result-details" class="px-3">
    <h3>{{ 'voting.result.details' | translate }}</h3>
    <div>{{ result.detail }}</div>
    <div>
      <ul *ngFor="let votingResultVote of result.votingResultDataByType" class="pl-3">
        <li
          *ngIf="
            votingResultVote.type !== 'blanc' &&
            (votingResultVote.count > 0 || votingResultVote.type.indexOf('candidate') !== -1)
          ">
          <ng-container
            [ngTemplateOutlet]="votingResultVote.type.indexOf('candidate') !== -1 ? candidateVote : yesNoVote"
            [ngTemplateOutletContext]="{votingResultVote}">
          </ng-container>
        </li>
      </ul>
    </div>
  </div>

  <ng-template #yesNoVote let-votingResultVote="votingResultVote">
    <div>
      {{ votingResultVote.count }} {{ 'voting.result.details.' + votingResultVote.type | translate }}
      <ng-container *ngIf="!result.secret && votingResultVote.members !== undefined">
        {{ votingResultVote.members }}
      </ng-container>
    </div>
  </ng-template>

  <ng-template #candidateVote let-votingResultVote="votingResultVote">
    <div>
      {{ votingResultVote.name }}:
      <ng-container *ngIf="result.secret">
        <div>{{ votingResultVote.count }} {{ 'voting.result.details.for' | translate }}</div>
        <ng-container *ngIf="votingResultVote.count2">
          <div>{{ votingResultVote.count2 }} {{ 'voting.result.details.against' | translate }}</div>
        </ng-container>
      </ng-container>
      <ul *ngIf="!result.secret" class="pl-3">
        <li>
          {{ votingResultVote.count }} {{ 'voting.result.details.for' | translate }}: {{ votingResultVote.members }}
        </li>
        <li *ngIf="votingResultVote.count2">
          {{ votingResultVote.count2 }} {{ 'voting.result.details.against' | translate }}:
          {{ votingResultVote.members2 }}
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-start">
      <button type="button" pButton label="{{ 'common.button.close' | translate }}" (click)="onClose()"></button>
    </div>
  </ng-template>

  <ng-template pTemplate="closeicon">
    <div
      class="p-dialog-header-icon"
      [title]="
        'dialog.button.close'
          | translate
            : {
                '0': 'voting.results' | translate
              }
      ">
      <i class="text-lg fa-regular fa-xmark text-gray-800"></i>
    </div>
  </ng-template>
</p-dialog>
