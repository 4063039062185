<div class="flex h-full w-full align-items-center justify-content-center">
  <iframe
    *ngIf="navigationEnd()"
    [src]="iframeUrl | safe : 'resourceUrl'"
    (load)="isLoading.set(false)"
    [ngClass]="{
      hidden: isLoading()
    }"
    width="100%"
    height="100%"></iframe>
  <p-progressSpinner *ngIf="isLoading()" aria-label="Loading" />
</div>
