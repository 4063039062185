import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  deleteAsyncUserTask,
  fetchAllRolesActive,
  fetchAsyncUserTasks,
  updateAsyncUserTask
} from '~/repositories/userdata/store/userdata.actions';
import { AsyncUserTask } from '~/repositories/userdata/store/userdata.models';
import {
  selectAllExports,
  selectAllExportsLength,
  selectAllRolesActive,
  selectAllRolesActiveLoading,
  selectNewExportsLength
} from '~/repositories/userdata/store/userdata.selectors';

@Injectable()
export class UserDataFacade {
  private readonly store = inject(Store);

  exports$ = this.store.pipe(select(selectAllExports));
  newExportsLength$ = this.store.pipe(select(selectNewExportsLength));
  allExportsLength$ = this.store.pipe(select(selectAllExportsLength));

  allRolesActiveLoading$ = this.store.pipe(select(selectAllRolesActiveLoading));
  allRolesActive$ = this.store.pipe(select(selectAllRolesActive));

  fetchAsyncUserTasks() {
    this.store.dispatch(fetchAsyncUserTasks());
  }

  updateAsyncUserTask(task: AsyncUserTask) {
    this.store.dispatch(updateAsyncUserTask({ task }));
  }

  deleteAsyncUserTask(id: string) {
    this.store.dispatch(deleteAsyncUserTask({ id }));
  }

  fetchAllRolesActive() {
    this.store.dispatch(fetchAllRolesActive());
  }
}
