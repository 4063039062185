import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MobileMenuService {
  private _mobileMenuItems: MenuItem[] = [];
  private _mobileMenuOpen = false;

  public set mobileMenuItems(items: MenuItem[]) {
    this._mobileMenuItems = items;
  }

  public get mobileMenuItems() {
    return this._mobileMenuItems;
  }

  public set mobileMenuOpen(open: boolean) {
    this._mobileMenuOpen = open;
  }

  public get mobileMenuOpen() {
    return this._mobileMenuOpen;
  }

  public setMobileMenuItemAtIndex(item: MenuItem, index: number) {
    this._mobileMenuItems[index] = item;
  }
}
