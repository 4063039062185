import { Pipe, PipeTransform } from '@angular/core';
import { Params, UrlSegment, UrlSerializer } from '@angular/router';

@Pipe({
  name: 'decodeUrl'
})
export class DecodeUrlPipe implements PipeTransform {
  constructor(private urlSerializer: UrlSerializer) {}
  transform(path: string): { segments: Array<string | { [key: string]: string }>; queryParams: Params } {
    if (!path) return null;

    const urlTree = this.urlSerializer.parse(path);
    const segments = urlTree.root.children['primary'].segments.reduce((acc, curr: UrlSegment) => {
      acc.push(curr.path, curr.parameters);
      return acc;
    }, []);
    const queryParams = urlTree.queryParams;
    return { segments: ['/', ...segments], queryParams };
  }
}
