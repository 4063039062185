import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { SUITEVIEWPATH } from '~/core/core.routing';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent {
  @Input() visible: boolean;
  @Input() menuItems: MenuItem[];
  @Input() button: HTMLElement;

  @Output() hide: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router) {}

  hideOverlay() {
    this.hide.emit();
  }

  navigateToSuiteView(url: string) {
    this.router.navigateByUrl(`${SUITEVIEWPATH}/${url}`);
  }
}
