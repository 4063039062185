<div *ngIf="info" class="footer fixed bottom-0 w-full bg-gray-200 flex align-items-center p-3 lg:px-4 z-4">
  <span class="text-xs text-overflow-ellipsis white-space-nowrap overflow-hidden mr-2">
    {{ info?.versionInfo }}
  </span>
  <span *ngIf="info?.urlDescription" class="text-xs mr-1">©</span>
  <a
    [href]="info?.url"
    class="white-space-nowrap text-xs text-left font-medium line-height-2 text-blue-400 hover:text-blue-700 no-underline"
    >{{ info?.urlDescription }}</a
  >
</div>
