<app-voting-dialog
  *ngIf="(letter$ | async) && (showVotingLetterDialog$ | async)"
  [visible]="showVotingLetterDialog$ | async"
  (visibleChange)="toggleVotingDialog($event)"
  [votingLetter]="letter$ | async"
  [submitVoteLoading]="submitVoteLoading()"
  [submitVoteError]="submitVoteError()"
  [activeMemberIndex]="activeMemberIndex()"
  (activeMemberIndexChange)="activeMemberIndex.set($event)"
  (submitVoting)="handleSubmitVoting($event)">
</app-voting-dialog>

<app-voting-result-dialog
  *ngIf="(result$ | async) && (showVotingResultDialog$ | async)"
  [visible]="showVotingResultDialog$ | async"
  (visibleChange)="toggleVotingResultDialog($event)"
  [votingResult]="result$ | async">
</app-voting-result-dialog>
