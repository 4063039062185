import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '~/core/core.module';

export interface NavigationItemRO {
  name: string;
  url: string;
  routeToFrontend: boolean;
  routeType: string;
  target: 'FRONTENDROUTE' | 'SUITEROUTE';
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  checkUser(): Observable<NavigationItemRO> {
    return this.httpClient.get<NavigationItemRO>(`${environment.BACKEND_URL}/checkuser`);
  }
}
