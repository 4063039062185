import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NewTabEntity, SharedTab, SharedWithGroup, TabEntity, TabOrder } from '../store/tabs.models';

import { environment } from '~/core/core.module';

@Injectable()
export class TabsService {
  constructor(private readonly http: HttpClient) {}

  public getTabsForUser(userId: number | string): Observable<TabEntity[]> {
    // TODO: get tabs by userId
    return this.http.get<TabEntity[]>(`${environment.BACKEND_URL}/dashboard/tabs`);
  }

  public saveTab(newTab: NewTabEntity): Observable<TabEntity> {
    return this.http.post<TabEntity>(`${environment.BACKEND_URL}/dashboard/tabs`, newTab);
  }

  public updateTabsOrder(tabsOrder: TabOrder[]): Observable<TabEntity[]> {
    return this.http.post<TabEntity[]>(`${environment.BACKEND_URL}/dashboard/tabs/order`, tabsOrder);
  }

  public updateTabTitle(title: string, id: string): Observable<TabEntity> {
    return this.http.patch<TabEntity>(`${environment.BACKEND_URL}/dashboard/tabs/${id}/title`, title);
  }

  public setActiveTab(id: string): Observable<TabEntity> {
    return this.http.patch<TabEntity>(`${environment.BACKEND_URL}/dashboard/tabs/${id}/setactive`, id);
  }

  public removeTab(tabId: string): Observable<void> {
    return this.http.delete<void>(`${environment.BACKEND_URL}/dashboard/tabs/${tabId}`);
  }

  public getSharedTabs(): Observable<SharedTab[]> {
    return this.http.get<SharedTab[]>(`${environment.BACKEND_URL}/dashboard/tabs/shareable`);
  }

  public getShareableGroups(): Observable<SharedWithGroup[]> {
    return this.http.get<SharedWithGroup[]>(`${environment.BACKEND_URL}/dashboard/tabs/shareable-groups`);
  }

  public getTabSharedWith(tabId: string): Observable<SharedWithGroup[]> {
    return this.http.get<SharedWithGroup[]>(`${environment.BACKEND_URL}/dashboard/tabs/${tabId}/shared-groups`);
  }

  public updateTabSharedWith(groups: SharedWithGroup[], tabId: string): Observable<TabEntity> {
    return this.http.post<TabEntity>(
      `${environment.BACKEND_URL}/dashboard/tabs/${tabId}/share`,
      groups.flat().map((item) => item.id)
    );
  }
}
