import { createAction, props } from '@ngrx/store';

import { WrittenQuestionsActions } from '~/repositories/written-questions/store/written-questions.const';
import {
  WrittenQuestionEntity,
  WrittenQuestionsFilter
} from '~/repositories/written-questions/store/written-questions.models';

export const fetchWrittenQuestions = createAction(
  WrittenQuestionsActions.fetchWrittenQuestions,
  props<{ filter: WrittenQuestionsFilter }>()
);

export const fetchWrittenQuestionsSuccess = createAction(
  WrittenQuestionsActions.fetchWrittenQuestionsSuccess,
  props<{ writtenQuestions: WrittenQuestionEntity[] }>()
);

export const fetchWrittenQuestionsFailure = createAction(
  WrittenQuestionsActions.fetchWrittenQuestionsFailure,
  props<{ error: any }>()
);

export const publishWrittenQuestions = createAction(
  WrittenQuestionsActions.publishWrittenQuestions,
  props<{ writtenQuestions: WrittenQuestionEntity[] }>()
);

export const publishWrittenQuestionsSuccess = createAction(
  WrittenQuestionsActions.publishWrittenQuestionsSuccess,
  props<{ writtenQuestions: WrittenQuestionEntity[] }>()
);

export const publishWrittenQuestionsFailure = createAction(
  WrittenQuestionsActions.publishWrittenQuestionsFailure,
  props<{ error: any }>()
);
