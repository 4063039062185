import { createAction, props } from '@ngrx/store';

import { WidgetConfigEntity, WidgetOrder, WidgetTypeEntity } from './widgets.models';
import { IActionsAssigneesResponse, IGetActionAssigneesProps, IWidgetActionEmitProps } from './widgets.types';

import { IError } from '~/core/global-error-handler.service';

export const getWidgetById = createAction('[Widgets/API] Load widget', props<{ id: string; refresh?: boolean }>());

export const loadWidgetSuccess = createAction(
  '[Widget/API] Load Widget Success',
  props<{ widget: WidgetConfigEntity }>()
);

export const loadWidgetFailure = createAction(
  '[Widget/API] Load Widget Failure',
  props<{ id: string; error: IError }>()
);

export const getWidgetsById = createAction('[Widgets/API] Load widgets', props<{ ids: string[]; refresh?: boolean }>());

export const loadWidgetsSuccess = createAction(
  '[Widget/API] Load Widgets Success',
  props<{ widgets: WidgetConfigEntity[] }>()
);

export const loadWidgetsFailure = createAction('[Widget/API] Load Widgets Failure', props<{ error: any }>());

export const updateWidgetConfig = createAction(
  '[Widgets/API] Update widget config',
  props<{ widget: WidgetConfigEntity }>()
);

export const updateWidgetConfigSuccess = createAction(
  '[Widgets/API] Update widget config success',
  props<{ widget: WidgetConfigEntity }>()
);

export const updateWidgetConfigFailure = createAction(
  '[Widgets/API] Update widget config failure',
  props<{ error: any }>()
);

export const fetchWidgetTypes = createAction('[Widgets/API] Load Widget Types');

export const loadWidgetTypesSuccess = createAction(
  '[Widget/API] Load Widget Types Success',
  props<{ widgetTypes: WidgetTypeEntity[] }>()
);

export const loadWidgetTypesFailure = createAction('[Widget/API] Load Widget Types Failure', props<{ error: any }>());

export const updateWidgetsOrder = createAction(
  '[Widgets/API] Update Widgets Order',
  props<{ widgetsOrder: WidgetOrder[] }>()
);

export const updateWidgetsOrderSuccess = createAction(
  '[Widgets/API] Update Widgets Order Success',
  props<{ widgets: WidgetConfigEntity[] }>()
);

export const updateWidgetsOrderFailure = createAction(
  '[Widgets/API] Update Widgets Order Failure',
  props<{ error: any }>()
);

export const removeWidget = createAction('[Widgets/API] Remove Widget', props<{ widgetId: string }>());

export const removeWidgetSuccess = createAction('[Widgets/API] Remove Widget Success', props<{ widgetId: string }>());

export const removeWidgetFailure = createAction('[Widgets/API] Remove Widget Failure', props<{ error: any }>());

export const emitAction = createAction(
  '[Widgets/API] Emit action',
  props<{ widget: WidgetConfigEntity; props: IWidgetActionEmitProps }>()
);

export const emitActionSuccess = createAction(
  '[Widgets/API] Emit action Success',
  props<{ widget: WidgetConfigEntity; props: IWidgetActionEmitProps }>()
);

export const emitActionFailure = createAction(
  '[Widgets/API] Emit action Failure',
  props<{ widget: WidgetConfigEntity; error: any }>()
);

export const getActionAssignees = createAction(
  '[Widgets/API] Get action assignees',
  props<{ props: IGetActionAssigneesProps }>()
);

export const getActionAssigneesSuccess = createAction(
  '[Widgets/API] Get action assignees Success',
  props<{ data: IActionsAssigneesResponse }>()
);

export const getActionAssigneesFailure = createAction(
  '[Widgets/API] Get action assignees Failure',
  props<{ error: any }>()
);
