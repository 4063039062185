export enum UserTaskType {
  SEARCH_EXPORT = 'SEARCH_EXPORT',
  GENERATE_PUBLICATION = 'GENERATE_PUBLICATION'
}

export enum UserTaskStatus {
  QUEUED = 'QUEUED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  BUSY = 'BUSY'
}

export interface AsyncUserTask {
  id: string;
  name: string;
  created: string; //date
  completed: string; //date
  availableUntil: string; //date
  type: UserTaskType;
  status: UserTaskStatus;
  documentId: string;
  documentName: string;
  documentType: string;
  downloaded: boolean;
}
