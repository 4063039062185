import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeEmpty',
  pure: false
})
export class RemoveEmptyPipe implements PipeTransform {
  transform(items: Array<unknown>): Array<unknown> {
    return items.filter((item) => !!item);
  }
}
