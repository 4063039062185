import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, combineLatest, map, mergeMap, Observable, of, tap, withLatestFrom } from 'rxjs';

import { WidgetsService } from '../services/widgets.service';

import {
  emitAction,
  emitActionFailure,
  emitActionSuccess,
  fetchWidgetTypes,
  getActionAssignees,
  getActionAssigneesFailure,
  getActionAssigneesSuccess,
  getWidgetById,
  getWidgetsById,
  loadWidgetFailure,
  loadWidgetsFailure,
  loadWidgetsSuccess,
  loadWidgetSuccess,
  loadWidgetTypesFailure,
  loadWidgetTypesSuccess,
  removeWidget,
  removeWidgetFailure,
  removeWidgetSuccess,
  updateWidgetConfig,
  updateWidgetConfigFailure,
  updateWidgetConfigSuccess,
  updateWidgetsOrder,
  updateWidgetsOrderFailure,
  updateWidgetsOrderSuccess
} from './widgets.actions';
import { WidgetConfigEntity } from './widgets.models';
import { selectWidgetById } from './widgets.selectors';

import { IState } from '~/repositories/repositories.store';

@Injectable()
export class WidgetsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<IState>,
    private readonly widgetsService: WidgetsService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  loadWidgetById$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(getWidgetById),
        withLatestFrom(this.store$),
        mergeMap(([action, state]) => {
          const { id }: { id: string } = action;
          const existing: WidgetConfigEntity = selectWidgetById(state, {
            id: action.id
          });

          if (existing && !action.refresh) {
            return of(
              loadWidgetSuccess({
                widget: existing
              })
            );
          }

          return this.widgetsService.getWidgetConfigById(id).pipe(
            mergeMap((result: WidgetConfigEntity) =>
              of(
                loadWidgetSuccess({
                  widget: result
                })
              )
            ),
            catchError((error) => of(loadWidgetFailure({ error, id })))
          );
        })
      )
  );

  loadWidgetsById$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(getWidgetsById),
        withLatestFrom(this.store$),
        mergeMap(([action, state]): Observable<WidgetConfigEntity[]> => {
          if (!action.ids?.length) {
            return new Observable((observer) => observer.next([]));
          }

          return combineLatest<WidgetConfigEntity[]>(
            action.ids.map((id: string) => {
              const existing: WidgetConfigEntity = selectWidgetById(state, {
                id
              });

              if (existing && !action.refresh) {
                return new Observable((observer) => observer.next(existing));
              }

              return this.widgetsService.getWidgetConfigById(id);
            })
          );
        }),
        mergeMap((result) =>
          of(
            loadWidgetsSuccess({
              widgets: result.map((widget, index) => ({
                ...widget,
                sortOrder: index
              }))
            })
          )
        ),
        catchError((error) => of(loadWidgetsFailure({ error })))
      )
  );

  loadWidgetTypes$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchWidgetTypes),
        mergeMap(() =>
          this.widgetsService.getWidgetTypes().pipe(
            map((widgetTypes) => loadWidgetTypesSuccess({ widgetTypes })),
            catchError((error: any) => of(loadWidgetTypesFailure({ error })))
          )
        )
      )
  );

  // navigate to error page when loading widgetTypes fails
  loadWidgetTypesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadWidgetTypesFailure),
        tap(() => this.router.navigate(['/error']))
      ),
    { dispatch: false }
  );

  updateWidgetConfig$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(updateWidgetConfig),
        mergeMap((action) =>
          this.widgetsService.updateWidgetConfig(action.widget).pipe(
            map((widget) => updateWidgetConfigSuccess({ widget })),
            catchError((error: any) => of(updateWidgetConfigFailure({ error })))
          )
        )
      )
  );

  updateWidgetsOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWidgetsOrder),
      withLatestFrom(this.store$),
      mergeMap(([action, state]) =>
        this.widgetsService.updateWidgetsOrder(state.repository.tabs.selectedId, action.widgetsOrder).pipe(
          map((widgets: WidgetConfigEntity[]) =>
            updateWidgetsOrderSuccess({
              widgets: widgets.sort((a, b) => a.sortOrder - b.sortOrder)
            })
          ),
          catchError((error) => of(updateWidgetsOrderFailure({ error })))
        )
      )
    )
  );

  removeWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeWidget),
      mergeMap(({ widgetId }) =>
        this.widgetsService.removeWidget(widgetId).pipe(
          map(() => removeWidgetSuccess({ widgetId })),
          catchError((error) => of(removeWidgetFailure({ error })))
        )
      )
    )
  );

  emitAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(emitAction),
      mergeMap(({ widget: WidgetConfigProperty, props: props }) =>
        this.widgetsService.emitAction(props).pipe(
          map((props) => emitActionSuccess({ widget: WidgetConfigProperty, props: props })),
          catchError((error) => of(emitActionFailure({ widget: WidgetConfigProperty, error: error })))
        )
      )
    )
  );

  getActionAssignees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActionAssignees),
      mergeMap(({ props }) =>
        this.widgetsService.getActionAssignees(props).pipe(
          map((data) => getActionAssigneesSuccess({ data })),
          catchError((error) => of(getActionAssigneesFailure({ error })))
        )
      )
    )
  );

  // collection of all successes that have to show a toast message
  successes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(emitActionSuccess),
        tap(({ props }) => {
          this.messageService.add({
            severity: 'success',
            detail: props.successMessage,
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );

  // collection of all failures that have to show a toast message
  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ...[
            loadWidgetFailure,
            updateWidgetConfigFailure,
            updateWidgetsOrderFailure,
            removeWidgetFailure,
            getActionAssigneesFailure,
            emitActionFailure
          ]
        ),
        tap(({ error }) => {
          this.messageService.add({
            severity: 'error',
            detail: error.error.message,
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );
}
