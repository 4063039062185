import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as AnnouncementsActions from './announcement.actions';
import { AnnouncementEntity } from './announcement.models';

export interface AnnouncementsState extends EntityState<AnnouncementEntity> {
  loaded: boolean; // has the Tabs list been loaded
  error?: string | null; // last known error (if any)
}

export const announcementsAdapter: EntityAdapter<AnnouncementEntity> = createEntityAdapter<AnnouncementEntity>();

export const initialAnnouncementsState: AnnouncementsState = announcementsAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

const reducer = createReducer(
  initialAnnouncementsState,
  on(AnnouncementsActions.initAnnouncements, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(AnnouncementsActions.loadAnnouncementsSuccess, (state, { announcements }) =>
    announcementsAdapter.setAll(announcements, {
      ...state,
      loaded: true
    })
  ),
  on(AnnouncementsActions.loadAnnouncementsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(AnnouncementsActions.markAnnouncementsViewedSuccess, (state, { announcementIds }) => {
    return announcementsAdapter.updateMany(
      Object.keys(state.entities)
        .map((key) => state.entities[key])
        .map((announcement) => ({
          id: announcement.id,
          changes: {
            ...announcement,
            view: announcementIds.includes(announcement.id)
          }
        })),
      {
        ...state,
        loaded: true
      }
    );
  }),
  on(AnnouncementsActions.markAnnouncementsViewedFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(AnnouncementsActions.addNewAnnouncementsToStore, (state, { newAnnouncements }) =>
    announcementsAdapter.upsertMany(newAnnouncements, state)
  )
);

export function announcementReducer(state: AnnouncementsState | undefined, action: Action) {
  return reducer(state, action);
}
