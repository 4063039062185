import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValue'
})
export class GetValuePipe implements PipeTransform {
  transform(input: { key: string; value: string } | string): string {
    return typeof input === 'object' ? input?.value : input;
  }
}
