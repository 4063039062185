export enum SearchActions {
  reset = '[Search]: reset',
  fetchSearchResults = '[Search]: fetch results',
  fetchSearchResultsSuccess = '[Search]: fetch results success',
  fetchSearchResultsFailure = '[Search]: fetch results failure',
  fetchNewPage = '[Search]: fetch new page',
  fetchNewPageSuccess = '[Search]: fetch new page success',
  fetchNewPageFailure = '[Search]: fetch new page failure',
  fetchFilters = '[Search]: fetch filters',
  fetchFiltersSuccess = '[Search]: fetch filters success',
  fetchFiltersFailure = '[Search]: fetch filters failure',
  fetchColumns = '[Search]: fetch columns',
  fetchColumnsSuccess = '[Search]: fetch columns success',
  fetchColumnsFailure = '[Search]: fetch columns failure',
  fetchSelectedColumns = '[Search]: fetch selected columns',
  fetchSelectedColumnsSuccess = '[Search]: fetch selected columns success',
  fetchSelectedColumnsFailure = '[Search]: fetch selected columns failure',
  updateColumnsOrder = '[Search]: update columns order',
  updateColumnsOrderSuccess = '[Search]: update columns order success',
  updateColumnsOrderFailure = '[Search]: update columns order failure',
  updateSelectedColumns = '[Search]: update selected columns',
  updateSelectedColumnsSuccess = '[Search]: update selected columns success',
  updateSelectedColumnsFailure = '[Search]: update selected columns failure',
  fetchChartConfig = '[Search]: fetch chart config',
  fetchChartConfigSuccess = '[Search]: fetch chart config success',
  fetchChartConfigFailure = '[Search]: fetch chart config failure',
  fetchAvailableExports = '[Search]: fetch available exports',
  fetchAvailableExportsSuccess = '[Search]: fetch available exports success',
  fetchAvailableExportsFailure = '[Search]: fetch available exports failure',
  setActiveResultsModule = '[Search]: set active results module',
  updateActiveResultsTab = '[Search]: update active results tab',
  updateFilterData = '[Search]: update filterData',
  updateSearchValue = '[Search]: update searchValue',
  updateFilterMode = '[Search]: update filterMode',
  setCurrentSearchState = '[Search]: set current searchstate',

  saveQuery = '[Search]: save query',
  saveQuerySuccess = '[Search]: save query success',
  saveQueryFailure = '[Search]: save query failure',
  fetchQueries = '[Search]: fetch queries',
  fetchQueriesSuccess = '[Search]: fetch queries success',
  fetchQueriesFailure = '[Search]: fetch queries failure',
  fetchSavedQuery = '[Search]: fetch saved query',
  fetchSavedQuerySuccess = '[Search]: fetch saved query success',
  fetchSavedQueryFailure = '[Search]: fetch saved query failure',
  removeQuery = '[Search]: remove query',
  removeQuerySuccess = '[Search]: remove query success',
  removeQueryFailure = '[Search]: remove query failure',
  clearLoadedSavedQuery = '[Search]: Clear loaded query',
  updateQuerySharedWith = '[Search]: Update Query SharedWith',
  updateQuerySharedWithSuccess = '[Search]: Update Query SharedWith success',
  updateQuerySharedWithFailure = '[Search]: Update Query SharedWith failure',

  exportResults = '[Search]: export results',
  exportResultsSuccess = '[Search]: export results success',
  exportResultsFailure = '[Search]: export results failure'
}
