import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayModule } from 'primeng/overlay';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepperModule } from 'primeng/stepper';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TreeSelectModule } from 'primeng/treeselect';

import { CardviewComponent } from './cardview/cardview.component';
import { CardView } from './cardview/cardview.types';
import { InfoBarItemComponent } from './cardview/info-bar-item.component';
import { AnnouncementsDialogComponent } from './dialogs/announcements-dialog/announcements-dialog.component';
import { LoginAsDialogComponent } from './dialogs/login-as-dialog/login-as-dialog.component';
import { MeetingpointersDialogComponent } from './dialogs/meetingpointers-dialog/meetingpointers-dialog.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FooterComponent } from './footer/footer.component';
import { FavouritesComponent } from './navbar/favourites/favourites.component';
import { LiveSearchComponent } from './navbar/live-search/live-search.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TieredMenuButtonComponent } from './navbar/tiered-menu-button/tiered-menu-button.component';
import { ResultsGraphComponent } from './results-graph/results-graph.component';
import { ResultsTableComponent } from './results-table/results-table.component';
import { TablePaginatorComponent, PaginatorOptions } from './table-paginator/table-paginator.component';
import { TreeSelectComponent } from './treeSelect/tree-select/tree-select.component';
import { WalkthroughComponent } from './walkthrough/walkthrough.component';

import { SearchFacade } from '~/search/facades/search.facade';
import { SharedModule } from '~/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,

    SkeletonModule,
    RouterLink,
    TieredMenuModule,
    LocalizeRouterModule,
    TranslateModule,
    CheckboxModule,
    ChipModule,
    DialogModule,
    TagModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    ButtonModule,
    SidebarModule,
    PanelMenuModule,
    SplitButtonModule,
    OverlayModule,
    InputTextModule,
    AutoCompleteModule,
    MenuModule,
    PaginatorModule,
    TableModule,
    TreeSelectModule,
    StepsModule,
    StepperModule,
    ImageModule,

    SharedModule
  ],
  declarations: [
    NavbarComponent,
    CardviewComponent,
    InfoBarItemComponent,
    EmptyStateComponent,
    TieredMenuButtonComponent,
    FavouritesComponent,
    FooterComponent,
    LiveSearchComponent,
    AnnouncementsDialogComponent,
    MeetingpointersDialogComponent,
    ResultsGraphComponent,
    ResultsTableComponent,
    LoginAsDialogComponent,
    TreeSelectComponent,
    TablePaginatorComponent,
    WalkthroughComponent
  ],
  exports: [
    NavbarComponent,
    CardviewComponent,
    InfoBarItemComponent,
    EmptyStateComponent,
    FooterComponent,
    AnnouncementsDialogComponent,
    MeetingpointersDialogComponent,
    LiveSearchComponent,
    MeetingpointersDialogComponent,
    ResultsGraphComponent,
    ResultsTableComponent,
    TieredMenuButtonComponent,
    LoginAsDialogComponent,
    TreeSelectComponent,
    TablePaginatorComponent,
    WalkthroughComponent
  ],
  providers: [SearchFacade]
})
export class UiModule {}
export { CardView, PaginatorOptions };
