import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, map, mergeMap, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';

import { SearchService } from '../services/search.service';

import {
  fetchColumns,
  fetchColumnsFailure,
  fetchColumnsSuccess,
  fetchFilters,
  fetchFiltersFailure,
  fetchFiltersSuccess,
  fetchNewPage,
  fetchNewPageFailure,
  fetchNewPageSuccess,
  fetchQueries,
  fetchQueriesFailure,
  fetchQueriesSuccess,
  fetchSavedQuery,
  fetchSavedQueryFailure,
  fetchSavedQuerySuccess,
  fetchSearchResults,
  fetchSearchResultsFailure,
  fetchSearchResultsSuccess,
  fetchSelectedColumns,
  fetchSelectedColumnsFailure,
  fetchSelectedColumnsSuccess,
  removeQuery,
  removeQueryFailure,
  removeQuerySuccess,
  saveQuery,
  saveQueryFailure,
  saveQuerySuccess,
  updateQuerySharedWithSuccess,
  updateQuerySharedWithFailure,
  fetchChartConfig,
  fetchChartConfigSuccess,
  fetchChartConfigFailure,
  fetchAvailableExportsSuccess,
  fetchAvailableExportsFailure,
  fetchAvailableExports,
  exportResults,
  exportResultsSuccess,
  exportResultsFailure
} from './search.actions';
import { SearchActions } from './search.const';
import { SavedSearchQuery, SearchResultEntity } from './search.models';
import { ISavedSearchQuerySummary } from './search.types';

import { IState } from '~/repositories/repositories.store';

@Injectable()
export class SearchEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<IState>,
    private readonly searchService: SearchService,
    private readonly messageService: MessageService
  ) {}

  fetchSearchResults$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchSearchResults),
        switchMap((action) =>
          this.searchService.getSearchResults(action.data).pipe(
            map((data) => {
              const queryModule = action.data.queryModule;

              if (queryModule) {
                const index = data.findIndex((item) => item.module === queryModule);
                data[index] = {
                  ...data[index],
                  sortColumn: action.data.params?.sortColumn,
                  sortOrder: action.data.params?.sortOrder
                };
              }
              return fetchSearchResultsSuccess({
                data: data.filter((item) => item.totalItems > 0),
                queryModule
              });
            }),
            catchError((error: any) => of(fetchSearchResultsFailure({ error })))
          )
        )
      )
  );

  fetchNewPage$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchNewPage),
        mergeMap((action) =>
          this.searchService.getNewPage(action.data).pipe(
            map((data: SearchResultEntity[]) =>
              fetchNewPageSuccess({
                data: {
                  ...data[0],
                  sortColumn: action.data.params.sortColumn,
                  sortOrder: action.data.params.sortOrder
                }
              })
            ),
            catchError((error: any) => of(fetchNewPageFailure({ error })))
          )
        )
      )
  );

  fetchFilters$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchFilters),
        mergeMap(() =>
          this.searchService.getFilters().pipe(
            map((data) =>
              fetchFiltersSuccess({
                data
              })
            ),
            catchError((error: any) => of(fetchFiltersFailure({ error })))
          )
        )
      )
  );

  fetchColumns$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchColumns),
        mergeMap(() =>
          this.searchService.getColumns().pipe(
            map((data) =>
              fetchColumnsSuccess({
                data
              })
            ),
            catchError((error: any) => of(fetchColumnsFailure({ error })))
          )
        )
      )
  );

  fetchSelectedColumns$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchSelectedColumns),
        withLatestFrom(this.store$),
        mergeMap(([, state]) => {
          const selectedColumns = state.repository.searchModule.search.selectedColumns;
          if (selectedColumns) {
            return of(
              fetchSelectedColumnsSuccess({
                data: selectedColumns
              })
            );
          }
          return this.searchService.getSelectedColumns().pipe(
            map((data) =>
              fetchSelectedColumnsSuccess({
                data
              })
            ),
            catchError((error: any) => of(fetchSelectedColumnsFailure({ error })))
          );
        })
      )
  );

  fetchChartConfig$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchChartConfig),
        mergeMap(() =>
          this.searchService.getChartConfig().pipe(
            map((config) =>
              fetchChartConfigSuccess({
                config
              })
            ),
            catchError((error: any) => of(fetchChartConfigFailure({ error })))
          )
        )
      )
  );

  saveQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveQuery),
      mergeMap(
        ({
          id,
          name,
          filters,
          columns,
          params,
          filterTabId,
          selectedViewMode,
          module,
          searchValue,
          chartGroupByOption,
          chartXAxisOption,
          chartXAxisInterval,
          chartType
        }) =>
          this.searchService
            .saveQuery(
              id,
              name,
              filters,
              columns,
              params,
              filterTabId,
              selectedViewMode,
              module,
              searchValue,
              chartGroupByOption,
              chartXAxisOption,
              chartXAxisInterval,
              chartType
            )
            .pipe(
              map((summary: ISavedSearchQuerySummary) => saveQuerySuccess({ summary })),
              catchError((error) => of(saveQueryFailure({ error })))
            )
      )
    )
  );

  fetchQueries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQueries),
      mergeMap(() =>
        this.searchService.fetchQueries().pipe(
          map((data: ISavedSearchQuerySummary[]) => fetchQueriesSuccess({ data })),
          catchError((error) => of(fetchQueriesFailure({ error })))
        )
      )
    )
  );

  fetchSavedQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSavedQuery),
      mergeMap(({ query }) =>
        this.searchService.fetchSavedQuery(query).pipe(
          map((data: SavedSearchQuery) => fetchSavedQuerySuccess({ data })),
          catchError((error) => of(fetchSavedQueryFailure({ error })))
        )
      )
    )
  );

  removeSavedQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeQuery),
      mergeMap(({ id }) =>
        this.searchService.removeQuery(id).pipe(
          map((data: void) => removeQuerySuccess({ id })),
          catchError((error) => of(removeQueryFailure({ error })))
        )
      )
    )
  );

  updateQuerySharedWith$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.updateQuerySharedWith),
      mergeMap(({ groups, queryId }) =>
        this.searchService.updateQuerySharedWith(groups, queryId).pipe(
          map(() =>
            updateQuerySharedWithSuccess({
              groups,
              queryId
            })
          ),
          catchError((error) => of(updateQuerySharedWithFailure({ error })))
        )
      )
    )
  );

  fetchAvailableExports$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchAvailableExports),
        mergeMap(() =>
          this.searchService.getAvailableExports().pipe(
            map((data) =>
              fetchAvailableExportsSuccess({
                data
              })
            ),
            catchError((error: any) => of(fetchAvailableExportsFailure({ error })))
          )
        )
      )
  );

  exportResults$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(exportResults),
        mergeMap((action) =>
          this.searchService.exportResults(action).pipe(
            map((exportId) =>
              exportResultsSuccess({
                id: action.timestamp,
                code: action.exportCode,
                module: action.module,
                label: action.label,
                exportId
              })
            ),
            catchError((error: any) => of(exportResultsFailure({ error, id: action.timestamp })))
          )
        )
      )
  );

  // collection of all failures that have to show a toast message
  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ...[
            fetchSearchResultsFailure,
            fetchNewPageFailure,
            fetchFiltersFailure,
            fetchColumnsFailure,
            fetchSelectedColumnsFailure
          ]
        ),
        tap(({ error }) => {
          this.messageService.add({
            severity: 'error',
            detail: error?.error?.message || error,
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );
}
