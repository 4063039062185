<form
  class="navbar-search theme-background-color flex align-items-center gap-2 border-round"
  [ngClass]="{
    'navbar-search-open absolute md:bottom-0 lg:pr-0 lg:right-100 mx-auto my-0 lg:w-30rem z-5': searchOpen() && inNavbar
  }"
  [formGroup]="searchForm"
  (ngSubmit)="onSearchClicked($event)"
  (keyup.enter)="onSearchClicked($event)">
  <button
    type="button"
    pButton
    icon="fa-solid fa-arrow-left"
    class="p-button-navbar-icon theme-text-color md:hidden"
    [ngClass]="{
      hidden: !searchOpen() || !inNavbar
    }"
    (click)="openSearch(false)"></button>
  <span
    class="p-input-icon-left w-full align-items-center hidden md:flex"
    [ngClass]="{
      flex: searchOpen() || !inNavbar
    }">
    <i class="pi pi-search z-1"></i>
    <p-autoComplete
      formControlName="search"
      [placeholder]="'search.button' | translate"
      field="title"
      class="w-full search-autocomplete outline-none"
      inputStyleClass="w-full h-full px-6 outline-none"
      panelStyleClass="mt-1 lg:w-30rem search-autocomplete outline-none"
      inputId="search-autocomplete"
      appendTo="body"
      scrollHeight="100vh"
      [delay]="750"
      [group]="true"
      [dropdown]="false"
      [lazy]="true"
      [suggestions]="searchOpen() ? suggestions() : []"
      [minLength]="0"
      [completeOnFocus]="true"
      (completeMethod)="liveSearch($event, kkcFilter)"
      (onFocus)="openAutoComplete()"
      (onBlur)="closeAutoComplete($event)">
      <ng-template let-item pTemplate="group">
        <div
          class="flex justify-content-between align-items-center py-2 px-3 bg-gray-100 border-bottom-1 border-gray-300">
          <span class="text-base text-left font-medium line-height-3 text-gray-800 mr-3"
            >{{ item.label | translate }}
          </span>
          <a
            [routerLink]="['ROUTES.search' | localize]"
            [queryParams]="{
              query: searchForm.get('search').value,
              module: item.value
            }"
            class="pointer-events-auto white-space-nowrap text-sm text-left font-medium line-height-2 text-blue-400 hover:text-blue-700 flex align-items-center"
            >{{ 'searchresults.all' | translate }}
          </a>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <ng-container *ngIf="suiteViewPath + '/' + item.path | decodeUrl as url">
          <a class="flex flex-column gap-1 no-underline" [routerLink]="url.segments" [queryParams]="url.queryParams">
            <span class="text-gray-700 text-overflow-ellipsis white-space-nowrap overflow-hidden">{{
              item.title
            }}</span>
            <span class="text-xs text-gray-600 text-overflow-ellipsis white-space-nowrap overflow-hidden">{{
              item.subtitle
            }}</span>
          </a>
        </ng-container>
      </ng-template>
      <ng-template let-loadingIcon pTemplate="loadingicon"
        ><i *ngIf="searchOpen()" class="fa-regular fa-circle-notch"></i
      ></ng-template>
    </p-autoComplete>
    <button
      class="navbar-search-button theme-alternating-color theme-border-color theme-text-color"
      type="submit"
      pButton
      icon="pi pi-search"></button>
  </span>
  <button
    type="button"
    pButton
    icon="fa-solid fa-magnifying-glass"
    class="p-button-navbar-icon theme-text-color md:hidden"
    [ngClass]="{
      hidden: searchOpen() || !inNavbar
    }"
    (click)="openSearch(true, 'search-autocomplete')"></button>
</form>
