import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { WrittenQuestionsService } from '../services/written-questions.service';

import * as WrittenQuestionActions from './written-questions.actions';
import { WrittenQuestionEntity } from './written-questions.models';

@Injectable()
export class WrittenQuestionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly writtenQuestionsService: WrittenQuestionsService,
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService
  ) {}

  fetchWrittenQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WrittenQuestionActions.fetchWrittenQuestions),
      mergeMap(({ filter }) => {
        return this.writtenQuestionsService.fetchWrittenQuestions(filter).pipe(
          map((writtenQuestions: WrittenQuestionEntity[]) =>
            WrittenQuestionActions.fetchWrittenQuestionsSuccess({
              writtenQuestions
            })
          ),
          catchError((error) => of(WrittenQuestionActions.fetchWrittenQuestionsFailure({ error })))
        );
      })
    )
  );

  publishWrittenQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WrittenQuestionActions.publishWrittenQuestions),
      mergeMap(({ writtenQuestions }) => {
        return this.writtenQuestionsService.publishWrittenQuestions(writtenQuestions).pipe(
          map((writtenQuestions: WrittenQuestionEntity[]) =>
            WrittenQuestionActions.publishWrittenQuestionsSuccess({
              writtenQuestions
            })
          ),
          catchError((error) => of(WrittenQuestionActions.publishWrittenQuestionsFailure({ error })))
        );
      })
    )
  );

  publishSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(...[WrittenQuestionActions.publishWrittenQuestionsSuccess]),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('consult.publish.success'),
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );

  // collection of all failures that have to show a toast message
  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ...[
            WrittenQuestionActions.fetchWrittenQuestionsFailure,
            WrittenQuestionActions.publishWrittenQuestionsFailure
          ]
        ),
        tap(({ error }) => {
          this.messageService.add({
            severity: 'error',
            detail: error.error.message,
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );
}
