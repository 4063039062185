import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, lastValueFrom, map, take, tap } from 'rxjs';

import { environment } from './core/core.module';

interface IWalkthrough {
  viewedByUser: boolean;
  openedThroughMenu: boolean;
  imagesLoaded: boolean;
  steps: {
    title: string;
    image?: string;
    description: string;
  }[];
}

interface IWalkthroughRes {
  [key: string]: IWalkthrough;
}

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {
  public walkthroughs: IWalkthroughRes = null;
  private _currentWalkthrough: string = null;

  public walkthroughForCurrentRoute$ = new Subject<IWalkthrough>();

  public set currentWalkthrough(key: string) {
    this._currentWalkthrough = key;
    //this.setWalkthrough();

    //console.log(this.walkthroughs[this._currentWalkthrough]);

    if (!this.walkthroughs) {
      this.getWalkthroughData().then((data: { key: string }[]) => {
        this.walkthroughs = data.reduce((a, v) => ({ ...a, [v.key]: v }), {});
        this.walkthroughForCurrentRoute$.next(this.walkthroughToShow);

        if (!this.walkthroughToShow?.viewedByUser) {
          this.setWalkthrough();
        }
      });
    } else {
      this.walkthroughForCurrentRoute$.next(this.walkthroughToShow);

      if (!this.walkthroughToShow?.viewedByUser) {
        this.setWalkthrough();
      }
    }
  }

  private get walkthroughToShow() {
    return this.walkthroughs[this._currentWalkthrough] || null;
  }

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {}

  public getWalkthroughs(): Promise<unknown> {
    return lastValueFrom(this.httpClient.get(`${environment.BACKEND_URL}/walkthrough`));
  }

  public setWalkthrough() {
    if (this.walkthroughToShow && !this.walkthroughToShow?.viewedByUser) {
      setTimeout(() => this.showWalkthrough(), 0);
    }
  }

  public async getWalkthroughData() {
    const data = (await this.getWalkthroughs()) as IWalkthroughRes;

    // Map over walkthroughs
    return Promise.all(
      Object.entries(data).map(async ([key, walkthrough]) => {
        return {
          ...walkthrough,
          key,
          imagesLoaded: false,
          openedThroughMenu: false
        };
      })
    );
  }

  public showWalkthroughByMenu() {
    this.walkthroughs[this._currentWalkthrough].openedThroughMenu = true;
    this.showWalkthrough();
  }

  private async showWalkthrough() {
    if (!this.walkthroughToShow?.imagesLoaded) {
      this.walkthroughs[this._currentWalkthrough].steps = await Promise.all(
        this.walkthroughToShow?.steps.map(async (step) => {
          // Preload image
          const imageResponse = step.image
            ? await lastValueFrom(
                this.httpClient.get(`${environment.BACKEND_URL}${step.image}`, {
                  responseType: 'blob'
                })
              )
            : null;

          return {
            ...step,
            image: imageResponse && URL.createObjectURL(imageResponse)
          };
        })
      );
      this.walkthroughs[this._currentWalkthrough].imagesLoaded = true;
    }

    this.walkthroughForCurrentRoute$.next(this.walkthroughToShow);
  }

  public setViewed() {
    if (!this.walkthroughToShow?.openedThroughMenu) {
      this.httpClient
        ?.post(`${environment.BACKEND_URL}/walkthrough/${this._currentWalkthrough}/viewed`, null)
        .pipe(take(1))
        .subscribe();
    }

    this.walkthroughs[this._currentWalkthrough].viewedByUser = true;
    this.walkthroughs[this._currentWalkthrough].openedThroughMenu = false;
  }
}
