import { ActionReducerMap, combineReducers, createFeatureSelector } from '@ngrx/store';

import { GenericWidgetDataEffects } from './generic-widget-data/store/generic-widget-data.effects';
import { genericWidgetDataReducer } from './generic-widget-data/store/generic-widget-data.reducer';
import { IGenericWidgetDataState } from './generic-widget-data/store/generic-widget-data.types';
import { SearchEffects } from './search/store/search.effects';
import {
  exportsReducerFunction,
  ExportsState,
  ISearchModuleState,
  queryReducerFunction,
  QueryState,
  searchReducerFunction,
  SearchState
} from './search/store/search.reducer';
import { TabsEffects } from './tabs/store/tabs.effects';
import { tabsReducer, TabsState } from './tabs/store/tabs.reducer';
import { ThemeEffects } from './theme/store/theme.effects';
import { themeReducer, ThemeState } from './theme/store/theme.reducer';
import { UserDataEffects } from './userdata/store/userdata.effects';
import { userDataReducer, UserDataState } from './userdata/store/userdata.reducer';
import { WidgetsEffects } from './widgets/store/widgets.effects';
import { widgetsReducer, WidgetsState } from './widgets/store/widgets.reducer';

import { AnnouncementEffects } from '~/repositories/announcements/store/announcement.effects';
import { announcementReducer, AnnouncementsState } from '~/repositories/announcements/store/announcement.reducer';
import { ArchiveEffects } from '~/repositories/archive/store/archive.effects';
import { ArchiveState, archiveReducer } from '~/repositories/archive/store/archive.reducer';
import { HolidayEffects } from '~/repositories/holiday/store/holiday.effects';
import { holidayReducer, HolidayState } from '~/repositories/holiday/store/holiday.reducer';
import { WrittenQuestionsEffects } from '~/repositories/written-questions/store/written-questions.effects';
import {
  writtenQuestionsReducer,
  WrittenQuestionsState
} from '~/repositories/written-questions/store/written-questions.reducer';

export interface IState {
  repository: IRepositoryState;
}

export interface IRepositoryState {
  tabs: TabsState;
  widgets: WidgetsState;
  genericWidgetData: IGenericWidgetDataState;
  searchModule: {
    search: SearchState;
    query: QueryState;
    exports: ExportsState;
  };
  userData: UserDataState;
  announcements: AnnouncementsState;
  writtenQuestions: WrittenQuestionsState;
  holidays: HolidayState;
  archive: ArchiveState;
  theme: ThemeState;
}

export const REDUCERS: ActionReducerMap<IRepositoryState> = {
  tabs: tabsReducer,
  widgets: widgetsReducer,
  genericWidgetData: genericWidgetDataReducer,
  searchModule: combineReducers<ISearchModuleState>({
    search: searchReducerFunction,
    query: queryReducerFunction,
    exports: exportsReducerFunction
  }),
  announcements: announcementReducer,
  writtenQuestions: writtenQuestionsReducer,
  userData: userDataReducer,
  holidays: holidayReducer,
  archive: archiveReducer,
  theme: themeReducer
};

export const EFFECTS = [
  TabsEffects,
  WidgetsEffects,
  GenericWidgetDataEffects,
  SearchEffects,
  AnnouncementEffects,
  WrittenQuestionsEffects,
  UserDataEffects,
  HolidayEffects,
  ArchiveEffects,
  ThemeEffects
];

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');
