import { createAction, props } from '@ngrx/store';

import { ArchiveActions } from './archive.const';
import {
  ArchiveDataFilter,
  ArchiveDataResult,
  ArchiveFormData,
  ArchiveTabDataEntity,
  ArchiveTab
} from './archive.models';

export const fetchArchiveTabs = createAction(ArchiveActions.fetchArchiveTabs);

export const fetchArchiveTabsSuccess = createAction(
  ArchiveActions.fetchArchiveTabsSuccess,
  props<{ tabs: ArchiveTabDataEntity[] }>()
);

export const fetchArchiveTabsFailure = createAction(ArchiveActions.fetchArchiveTabsFailure, props<{ error: any }>());

export const setSelectedTab = createAction(ArchiveActions.setSelectedTab, props<{ tab: ArchiveTab }>());

export const fetchArchiveData = createAction(
  ArchiveActions.fetchArchiveData,
  props<{ module: string; filter: ArchiveDataFilter }>()
);

export const fetchArchiveDataSuccess = createAction(
  ArchiveActions.fetchArchiveDataSuccess,
  props<{ result: ArchiveDataResult }>()
);

export const fetchArchiveDataFailure = createAction(ArchiveActions.fetchArchiveDataFailure, props<{ error: any }>());

export const sendToArchive = createAction(ArchiveActions.sendToArchive, props<{ data: ArchiveFormData }>());

export const sendToArchiveSuccess = createAction(ArchiveActions.sendToArchiveSuccess, props<{ total: number }>());

export const sendToArchiveFailure = createAction(ArchiveActions.sendToArchiveFailure, props<{ error: any }>());
