import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { SUITEVIEWPATH } from '~/core/core.routing';
import { MeetingPointer } from '~/repositories/meetingpointers/services/meetingpointer.service';

@Component({
  selector: 'app-meetingpointers-dialog',
  templateUrl: './meetingpointers-dialog.component.html',
  styleUrls: ['./meetingpointers-dialog.component.scss']
})
export class MeetingpointersDialogComponent {
  @Input() visible: boolean;

  @Input() meetingPointers: MeetingPointer[];

  @Output() visibleChange = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  onClose() {
    this.visibleChange.emit(false);
  }

  navigateToSuiteView(url: string) {
    this.onClose();
    this.router.navigateByUrl(`${SUITEVIEWPATH}/${url}`);
  }
}
