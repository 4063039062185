import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';

import { ISelectedCandidate, IVoteForCouncilMember } from './voting-dialog.types';

import { VotingLetter, VotingLetterCouncilMember } from '~/voting/voting.types';

@Component({
  selector: 'app-voting-dialog',
  templateUrl: './voting-dialog.component.html',
  styleUrls: ['./voting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VotingDialogComponent {
  @Input() visible: boolean;
  @Input() submitVoteLoading: boolean;
  @Input() submitVoteError: boolean;
  @Input() activeMemberIndex: number;

  @Input() set votingLetter(letter: VotingLetter) {
    this.letter = letter;

    this.votingForm = new FormGroup({
      members: new FormGroup({})
    });
    const membersGroup = this.votingForm.get('members') as FormGroup;

    if (letter.totalVotingDuration > 0) {
      this.progressBarStep.set(letter.totalVotingDuration / 100);
    }

    if (this.letter.yesno) {
      this.votingOptions.set([
        {
          label: this.translateService.instant('voting.result.type.for'),
          value: 'FOR',
          icon: 'fa-regular fa-thumbs-up'
        },
        {
          label: this.translateService.instant('voting.result.type.against'),
          value: 'AGAINST',
          icon: 'fa-regular fa-thumbs-down'
        },
        { label: this.translateService.instant('voting.abstent'), value: 'ABSTENTION', icon: 'fa-regular fa-hand' }
      ]);

      letter?.councilMembers?.forEach((member: VotingLetterCouncilMember) => {
        membersGroup.addControl(
          member.id,
          new FormGroup({
            resultType: new FormControl('')
          })
        );
      });
    } else {
      /* this.votingOptions.set([
        { label: this.translateService.instant('voting.result.type.for'), value: 'CANDIDATE_FOR' },
        this.letter.voteAgainstCandidates && {
          label: this.translateService.instant('voting.result.type.against'),
          value: 'CANDIDATE_AGAINST'
        }
      ]); */

      letter?.councilMembers?.forEach((member: VotingLetterCouncilMember) => {
        membersGroup.addControl(
          member.id,
          new FormGroup({
            selectedCandidates: new FormControl<ISelectedCandidate[]>(
              [],
              [Validators.maxLength(this.letter.candidatesToSelect)]
            ),
            resultType: new FormControl('')
          })
        );
      });
    }
  }

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() activeMemberIndexChange = new EventEmitter<number>();

  @Output() submitVoting = new EventEmitter<{
    voting: IVoteForCouncilMember;
    closeDialog?: boolean;
    nextCouncilMember?: number;
  }>();

  letter: VotingLetter;
  votingForm: FormGroup;
  progressBarStep = signal<number>(null);
  votingOptions = signal<{ label: string; value: string; icon?: string }[]>([]);

  constructor(private translateService: TranslateService, private messageService: MessageService) {}

  get votingFormForActiveMember() {
    return this.votingForm?.get('members').get(this.activeMember.id);
  }

  get activeMember() {
    return this.letter?.councilMembers[this.activeMemberIndex];
  }

  onFinishVoting() {
    this.submitVoting.emit({ voting: this.votingForm.getRawValue(), closeDialog: true });
  }

  onClose() {
    this.visibleChange.emit(false);
  }

  selectVote({ value }: SelectButtonChangeEvent) {
    this.votingForm.get('members').patchValue({
      [this.activeMember.id]: value
    });

    this.votingFormForActiveMember.patchValue({
      resultType: value
    });

    this.messageService.clear('votes');
  }

  toggleVote(button: string) {
    this.messageService.clear('votes');

    this.votingFormForActiveMember.patchValue({
      resultType: button,
      selectedCandidates: []
    });
  }

  toggleCandidate(candidateId: string, resultType: string) {
    this.messageService.clear('votes');

    let selectedCandidates: ISelectedCandidate[] = this.votingFormForActiveMember.get('selectedCandidates').value;
    const candidateIndex = selectedCandidates.findIndex((item: ISelectedCandidate) => item.candidateId === candidateId);
    if (candidateIndex >= 0 && selectedCandidates[candidateIndex].resultType === resultType) {
      selectedCandidates.splice(candidateIndex, 1);
    } else if (candidateIndex >= 0 && selectedCandidates[candidateIndex].resultType !== resultType) {
      selectedCandidates[candidateIndex].resultType = resultType;
    } else {
      selectedCandidates = [...selectedCandidates, { candidateId, resultType }];
    }
    this.votingFormForActiveMember.patchValue({
      selectedCandidates,
      resultType: ''
    });
  }

  votedForCandidate(candidateId: string, resultType: string) {
    return (
      this.votingFormForActiveMember
        .get('selectedCandidates')
        .value.findIndex(
          (item: ISelectedCandidate) => item.candidateId === candidateId && item.resultType === resultType
        ) >= 0
    );
  }

  hasVotedForMaxCandidates() {
    const selectedCandidates: ISelectedCandidate[] = this.votingFormForActiveMember.get('selectedCandidates').value;

    return (
      selectedCandidates.filter((item) => item.resultType === 'CANDIDATE_FOR').length === this.letter.candidatesToSelect
    );
  }

  prevMember() {
    this.messageService.clear('votes');
    this.activeMemberIndexChange.emit(this.activeMemberIndex - 1);
  }

  nextMember() {
    this.messageService.clear('votes');
    this.activeMemberIndexChange.emit(this.activeMemberIndex + 1);
  }

  submitVote() {
    this.messageService.clear('votes');

    if (this.passedSubmitChecks()) {
      this.submitVoting.emit({ voting: this.votingFormForActiveMember.getRawValue() });
    }
  }

  passedSubmitChecks(): boolean {
    const values = Object.values(this.votingForm?.get('members').value) as IVoteForCouncilMember[];

    let passed = true;

    values.forEach((value, index) => {
      if (this.letter.yesno && passed) {
        if (!value.resultType && passed) {
          if (index === this.activeMemberIndex && passed) {
            this.messageService.add({
              key: 'votes',
              severity: 'error',
              detail: 'Gelieve eerst een keuze te maken'
            });

            passed = false;
          }
          if (index != this.activeMemberIndex && passed) {
            this.submitVoting.emit({
              voting: this.votingFormForActiveMember.getRawValue(),
              closeDialog: false,
              nextCouncilMember: index
            });

            passed = false;
          }
        }
      } else {
        const voteForCouncilMember = value as IVoteForCouncilMember;
        const activeMemberValues = values[this.activeMemberIndex] as IVoteForCouncilMember;
        if (index === this.activeMemberIndex && passed) {
          if (
            !voteForCouncilMember.selectedCandidates.length &&
            !['AGAINST', 'ABSTENTION'].includes(voteForCouncilMember.resultType)
          ) {
            this.messageService.add({
              key: 'votes',
              severity: 'error',
              detail: 'Gelieve eerst een keuze te maken'
            });

            passed = false;
          }
        } else if (passed) {
          if (
            !voteForCouncilMember.selectedCandidates.length &&
            !['AGAINST', 'ABSTENTION'].includes(voteForCouncilMember.resultType)
          ) {
            if (
              index != this.activeMemberIndex &&
              !activeMemberValues.selectedCandidates.length &&
              !['AGAINST', 'ABSTENTION'].includes(activeMemberValues.resultType)
            ) {
              this.messageService.add({
                key: 'votes',
                severity: 'error',
                detail: 'Gelieve eerst een keuze te maken'
              });

              passed = false;
            } else {
              this.submitVoting.emit({
                voting: this.votingFormForActiveMember.getRawValue(),
                closeDialog: false,
                nextCouncilMember: index
              });

              passed = false;
            }
          }
        }
      }
    });

    return passed;
  }
}
