import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as ThemeActions from './theme.actions';
import * as ThemeSelectors from './theme.selectors';

@Injectable()
export class ThemeFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(ThemeSelectors.selectThemeLoaded));
  theme$ = this.store.pipe(select(ThemeSelectors.selectTheme));
  favourites$ = this.store.pipe(select(ThemeSelectors.selectAllFavourites));
  helpInformation$ = this.store.pipe(select(ThemeSelectors.selectAllHelpItems));
  footerInformation$ = this.store.pipe(select(ThemeSelectors.selectFooter));
  configurationItems$ = this.store.pipe(select(ThemeSelectors.selectAllConfigurationItems));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(organisation: number) {
    this.store.dispatch(ThemeActions.initTheme({ organisation }));
  }

  loadFavourites() {
    this.store.dispatch(ThemeActions.loadFavourites());
  }

  loadHelpInformation() {
    this.store.dispatch(ThemeActions.loadHelpInformation());
  }

  loadFooterInformation() {
    this.store.dispatch(ThemeActions.loadFooterInformation());
  }

  loadConfigurationItems() {
    this.store.dispatch(ThemeActions.loadConfigurationItems());
  }
}
