import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

import { environment } from '~/core/core.module';

@Pipe({
  name: 'secureUrl'
})
export class SecureUrlPipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(path: string): Observable<SafeUrl> {
    const header = {
      Authorization: environment.BEARER_TOKEN
    };

    return this.http
      .get(environment.BACKEND_URL + path, { headers: header, responseType: 'blob' })
      .pipe(map((val) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
