import { Pipe, PipeTransform } from '@angular/core';
import { get, orderBy } from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(array: object, sortBy: string, order?: boolean | 'asc' | 'desc'): any[] {
    const sortOrder = order ? order : 'asc'; // setting default ascending order

    return orderBy(array, [(item) => (get(item, sortBy) as string).toLowerCase()], [sortOrder]);
  }
}
