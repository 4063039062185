import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'booleanText'
})
export class BooleanTextPipe implements PipeTransform {
  constructor(private translationService: TranslateService) {}

  transform(value: boolean): string {
    return this.translationService.instant('common.' + (value ? 'yes' : 'no'));
  }
}
