import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GenericWidgetDataService } from './generic-widget-data/services/generic-widget-data.service';
import { EFFECTS, REDUCERS } from './repositories.store';
import { SearchService } from './search/services/search.service';
import { TabsService } from './tabs/services/tabs.service';
import { ThemeService } from './theme/services/theme.service';
import { ThemeFacade } from './theme/store/theme.facade';
import { UserDataService } from './userdata/services/userdata.service';
import { WidgetsService } from './widgets/services/widgets.service';

import { AnnouncementService } from '~/repositories/announcements/services/announcement.service';
import { AnnouncementFacade } from '~/repositories/announcements/store/announcement.facade';
import { ArchiveService } from '~/repositories/archive/services/archive.service';
import { WrittenQuestionsService } from '~/repositories/written-questions/services/written-questions.service';

@NgModule({
  providers: [
    TabsService,
    WidgetsService,
    GenericWidgetDataService,
    SearchService,
    UserDataService,
    AnnouncementService,
    AnnouncementFacade,
    WrittenQuestionsService,
    ArchiveService,
    ThemeService
  ],
  imports: [StoreModule.forFeature('repository', REDUCERS), EffectsModule.forFeature(EFFECTS)]
})
export class RepositoriesModule {}
export { ThemeFacade };
