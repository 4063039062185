<div class="h-full">
  <app-navbar
    *ngIf="!isForbidden()"
    [loaded]="themeLoaded$ | async"
    [title]="'home.title' | translate"
    [user]="user"
    [theme]="theme$ | async"
    [userHasRoles]="userHasRoles()"
    [allRolesActive]="allRolesActive$ | async"
    [allRolesActiveLoading]="allRolesActiveLoading$ | async"
    [mobileMenuItems]="mobileMenuItems | removeEmpty"
    [starredMenuItems]="starredMenuItems"
    [userMenuItems]="userMenuItems()"
    [helpMenuItems]="helpMenuItems$ | async"
    [configurationMenuItems]="configurationMenuItems"
    [exportsLength]="exportsLength$ | async"
    [printListModule]="printListModule"
    [calculationModule]="calculationModule"
    [menuOpen]="mobileMenuOpen"
    [countAnnouncements]="announcements.length"
    [countMeetingPointers]="meetingPointers.length"
    [editModeEnabled]="false"
    (triggerSearch)="handleSearch($event)"
    (visibleChange)="toggleMobileMenu($event)"
    (showAnnouncements)="openAnnouncementDialog()"
    (showMeetingPointers)="openMeetingPointersDialog()"
    (logout)="logout()"
    class="h-5"></app-navbar>
  <div *ngIf="user || isForbidden()" class="router relative overflow-y-auto">
    <router-outlet> </router-outlet>
  </div>
  <app-footer [info]="footerInformation$ | async"></app-footer>

  <p-toast position="top-right" [baseZIndex]="7000">
    <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
      <div class="flex flex-column relative">
        <div class="p-toast-header">
          <div class="p-toast-title">
            <i class="fa-regular"></i>
            <h3>
              <ng-container
                [ngTemplateOutlet]="message.summary ? toastSummaryTitle : toastSeverityTitle"
                [ngTemplateOutletContext]="{message}">
              </ng-container>
            </h3>
          </div>
          <div>
            <button pButton class="p-toast-icon-close" (click)="closeFn($event)" icon="fa-regular fa-times"></button>
          </div>
        </div>
        <div class="p-toast-content">
          <span [innerHTML]="message.detail | safe : 'html'"></span>
        </div>
        <div
          class="p-toast-progress"
          [style.animation-duration]="message.life + 'ms'"
          *ngIf="message.life > 0 && !message.sticky"></div>
      </div>
    </ng-template>
  </p-toast>
</div>

<app-announcements-dialog
  *ngIf="showAnnouncementDialog"
  [visible]="showAnnouncementDialog"
  [announcements]="announcements"
  (closeAnnouncementDialog)="markAnnouncementsViewed($event)">
</app-announcements-dialog>
<app-announcements-dialog
  *ngIf="showNewAnnouncements"
  [visible]="showNewAnnouncements"
  [announcements]="newAnnouncements"
  (closeAnnouncementDialog)="markNewAnnouncementsViewed($event)">
</app-announcements-dialog>

<app-meetingpointers-dialog
  *ngIf="showMeetingPointersDialog"
  [(visible)]="showMeetingPointersDialog"
  [meetingPointers]="meetingPointers">
</app-meetingpointers-dialog>

<app-login-as-dialog *ngIf="showLoginAsDialog" [(visible)]="showLoginAsDialog" (loginAs)="handleLoginAs($event)">
</app-login-as-dialog>

<app-voting></app-voting>

<ng-template #toastSummaryTitle let-message="message">
  {{ message.summary }}
</ng-template>

<ng-template #toastSeverityTitle let-message="message">
  <ng-container [ngSwitch]="message.severity">
    <ng-container *ngSwitchCase="'error'">{{ 'common.error' | translate }}</ng-container>
    <ng-container *ngSwitchCase="'warn'">{{ 'common.warning' | translate }}</ng-container>
    <ng-container *ngSwitchCase="'success'">{{ 'common.success' | translate }}</ng-container>
    <ng-container *ngSwitchCase="'info'">{{ 'common.info' | translate }}</ng-container>
  </ng-container>
</ng-template>

<app-walkthrough
  [visible]="
    (!(walkthrough$ | async)?.viewedByUser || (walkthrough$ | async)?.openedThroughMenu) &&
    !!(walkthrough$ | async)?.steps?.length
  "
  [steps]="(walkthrough$ | async)?.steps"
  (closeDialog)="handleCloseWalkthrough()"></app-walkthrough>
