import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

import { IFooter } from '~/repositories/theme/store/theme.models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() info: IFooter;

  public readonly OLD_BACKEND_URL = environment.OLD_BACKEND_URL;
}
