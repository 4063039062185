import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root'
})
export class SuiteStompService extends RxStomp {
  constructor() {
    super();
  }

  createSubscription(topic: string) {
    return this.watch(topic);
  }
}
