import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ThemeState } from './theme.reducer';

import { IRepositoryState } from '~/repositories/repositories.store';

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');

export const selectThemeState = createSelector(REPOSITORY_SELECTOR, (state: IRepositoryState) => state.theme);

export const selectThemeLoaded = createSelector(selectThemeState, (state: ThemeState) => state.loaded);

export const selectThemeError = createSelector(selectThemeState, (state: ThemeState) => state.error);

export const selectTheme = createSelector(
  selectThemeState,
  (state: ThemeState) => state.entities[state.selectedId || 0]
);

export const selectAllFavourites = createSelector(selectThemeState, (state: ThemeState) => state.favourites);

export const selectAllHelpItems = createSelector(selectThemeState, (state: ThemeState) => state.helpInformationItems);

export const selectFooter = createSelector(selectThemeState, (state: ThemeState) => state.footerInformation);

export const selectAllConfigurationItems = createSelector(
  selectThemeState,
  (state: ThemeState) => state.configurationItems
);
