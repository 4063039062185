import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap, merge } from 'lodash';

import { exportsAdapter, ExportsState, queryAdapter, QueryState, searchAdapter, SearchState } from './search.reducer';
import { IColumn, ICurrentSearchState } from './search.types';

import { IRepositoryState } from '~/repositories/repositories.store';

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');

const { selectAll: selectAllSearchResults } = searchAdapter.getSelectors();
const { selectAll: selectAllQueries } = queryAdapter.getSelectors();
const { selectAll: selectAllExports } = exportsAdapter.getSelectors();

export const selectSearchState = createSelector(
  REPOSITORY_SELECTOR,
  (state: IRepositoryState) => state.searchModule.search
);

export const selectCurrentSearchState = createSelector(
  selectSearchState,
  (state: SearchState): ICurrentSearchState => ({
    filterData: state.filterData,
    activeResultsModule: state.activeResultsModule,
    searchValue: state.searchValue,
    filterMode: state.filterMode,
    selectedColumns: state.selectedColumns,
    chartConfig: state.chartConfig
  })
);

export const selectQueryState = createSelector(
  REPOSITORY_SELECTOR,
  (state: IRepositoryState) => state.searchModule.query
);

export const selectExportsState = createSelector(
  REPOSITORY_SELECTOR,
  (state: IRepositoryState) => state.searchModule.exports
);

export const selectAllResults = createSelector(selectSearchState, (state: SearchState) =>
  selectAllSearchResults(state)
);

export const selectResultsLoading = createSelector(selectSearchState, (state: SearchState) => state.loading);

export const selectFilters = createSelector(selectSearchState, (state: SearchState) => state.filters);

export const selectFiltersLoading = createSelector(selectSearchState, (state: SearchState) => state.filtersLoading);

export const selectColumns = createSelector(selectSearchState, (state: SearchState) => state.columns);

export const selectColumnsLoading = createSelector(selectSearchState, (state: SearchState) => state.columnsLoading);

export const selectSelectedColumns = createSelector(selectSearchState, (state: SearchState): IColumn[] =>
  state.selectedColumns ? flatMap(merge(state.selectedColumns)) : []
);

export const selectDefaultColumnsForTab = createSelector(
  selectSearchState,
  (state: SearchState): IColumn[] =>
    state?.columns?.filter((col) => col.module === state.activeResultsModule && col.defaultColumn) || []
);

export const selectSelectedColumnsLoading = createSelector(
  selectSearchState,
  (state: SearchState) => state.selectedColumnsLoading
);

export const selectUpdateColumnsOrderLoading = createSelector(
  selectSearchState,
  (state: SearchState) => state.updateColumnsOrderLoading
);

export const selectUpdateColumnsLoading = createSelector(
  selectSearchState,
  (state: SearchState) => state.updateColumnsLoading
);

export const selectActiveResultsModule = createSelector(
  selectSearchState,
  (state: SearchState) => state.activeResultsModule
);

export const selectActiveResultsTab = createSelector(selectSearchState, (state: SearchState) =>
  state.activeResultsModule ? state.entities[state.activeResultsModule] : undefined
);

export const selectFilterData = createSelector(selectSearchState, (state: SearchState) => state.filterData);
export const selectSearchValue = createSelector(selectSearchState, (state: SearchState) => state.searchValue);
export const selectFilterMode = createSelector(selectSearchState, (state: SearchState) => state.filterMode);

export const selectChartConfig = createSelector(selectSearchState, (state: SearchState) => state.chartConfig);

export const selectChartConfigLoading = createSelector(
  selectSearchState,
  (state: SearchState) => state.chartConfigLoading
);

export const selectSaveQueryLoading = createSelector(selectQueryState, (state: QueryState) => state.saveQueryLoading);

export const selectSavedQueries = createSelector(selectQueryState, (state: QueryState) => selectAllQueries(state));

export const selectSavedQueriesLoading = createSelector(selectQueryState, (state: QueryState) => state.queriesLoading);

export const selectSavedQueryLoading = createSelector(selectQueryState, (state: QueryState) => state.saveQueryLoading);

export const selectSavedQuery = createSelector(selectQueryState, (state: QueryState) => state.savedQuery);

export const selectUpdateQuerySharedWithLoading = createSelector(
  selectQueryState,
  (state: QueryState) => state.updateSharedWithLoading
);

export const selectAvailableExports = createSelector(
  selectExportsState,
  (state: ExportsState) => state.availableExports
);

export const selectAvailableExportsLoading = createSelector(
  selectExportsState,
  (state: ExportsState) => state.availableExportsLoading
);

export const selectExports = createSelector(selectExportsState, (state: ExportsState) => selectAllExports(state));
