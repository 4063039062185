import { Action, createReducer, on } from '@ngrx/store';

import {
  getGenericWidgetData,
  fetchGenericWidgetDataSuccess,
  loadGenericWidgetDataSuccess,
  loadGenericWidgetDataFailure,
  toggleItemSelected,
  toggleAllItemsSelected
} from './generic-widget-data.actions';
import { adapter, cardViewAdapter, initialState, initialItemState } from './generic-widget-data.adapter';
import { IGenericWidgetDataState } from './generic-widget-data.types';

const reducer = createReducer(
  initialState,
  on(getGenericWidgetData, (state, { id, widgetTypeCode, params, refresh }) => {
    const changes = {
      ...initialItemState,
      ...params
    };

    // reset items on data refresh
    if (refresh) {
      changes.items = { ids: [], entities: {} };
    }

    if (state.entities[id]) {
      return adapter.updateOne(
        {
          changes,
          id
        },
        state
      );
    }

    return adapter.addOne(
      {
        ...changes,
        id,
        widgetTypeCode,
        items: { ids: [], entities: {} }
      },
      state
    );
  }),
  on(fetchGenericWidgetDataSuccess, (state, { item }) =>
    adapter.updateOne(
      {
        changes: {
          loaded: true,
          items: cardViewAdapter.setAll(item.items, state.entities[item.id].items),
          totalItems: item.totalItems,
          totals: item.totals,
          currentPage: item.currentPage,
          currentItems: item.currentItems,
          message: item.message,
          error: null
        },
        id: item.id
      },
      state
    )
  ),
  on(loadGenericWidgetDataSuccess, (state, { item }) =>
    adapter.updateOne(
      {
        changes: {
          ...item,
          items: state.entities[item.id].items,
          loaded: true,
          error: null,
          totalItems: item.totalItems,
          currentPage: item.currentPage,
          currentItems: item.currentItems,
          message: item.message
        },
        id: item.id
      },
      state
    )
  ),
  on(loadGenericWidgetDataFailure, (state, { id, error }) =>
    adapter.updateOne({ changes: { loaded: true, error: error }, id }, state)
  ),
  on(toggleItemSelected, (state, { widgetId, itemId, checked }) => {
    return adapter.updateOne(
      {
        changes: {
          items: cardViewAdapter.updateOne(
            {
              changes: {
                checked
              },
              id: itemId
            },
            state.entities[widgetId].items
          )
        },
        id: widgetId
      },
      state
    );
  }),
  on(toggleAllItemsSelected, (state, { widgetId, checked }) => {
    return adapter.updateOne(
      {
        changes: {
          items: cardViewAdapter.updateMany(
            Object.keys(state.entities[widgetId].items.entities)
              .map((key) => state.entities[widgetId].items.entities[key])
              .map((item) => ({
                id: item.id,
                changes: {
                  ...item,
                  id: item.id,
                  checked
                }
              })),
            state.entities[widgetId].items
          )
        },
        id: widgetId
      },
      state
    );
  })
);

export function genericWidgetDataReducer(state: IGenericWidgetDataState, action: Action): IGenericWidgetDataState {
  return reducer(state, action);
}
