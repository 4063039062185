import { createAction, props } from '@ngrx/store';

import { SearchActions } from './search.const';
import { SavedSearchQuery, SearchResultEntity, SharedWithGroup } from './search.models';
import {
  IChartConfig,
  IColumn,
  ICurrentSearchState,
  IExports,
  IFetchNewPageProps,
  IFetchSearchResultProps,
  IRequestedExport,
  ISavedSearchQuerySummary
} from './search.types';

import { SEARCHTYPE } from '~/search/search/search.const';
import { IExportResultsProps, IFilterItem, PaginatedItemsParams } from '~/search/search/search.types';

export const reset = createAction(SearchActions.reset);

export const fetchSearchResults = createAction(
  SearchActions.fetchSearchResults,
  props<{ data: IFetchSearchResultProps }>()
);

export const fetchSearchResultsSuccess = createAction(
  SearchActions.fetchSearchResultsSuccess,
  props<{ data: SearchResultEntity[]; queryModule?: string }>()
);

export const fetchSearchResultsFailure = createAction(SearchActions.fetchSearchResultsFailure, props<{ error: any }>());

export const fetchNewPage = createAction(SearchActions.fetchNewPage, props<{ data: IFetchNewPageProps }>());

export const fetchNewPageSuccess = createAction(
  SearchActions.fetchNewPageSuccess,
  props<{ data: SearchResultEntity }>()
);

export const fetchNewPageFailure = createAction(SearchActions.fetchNewPageFailure, props<{ error: any }>());

export const fetchFilters = createAction(SearchActions.fetchFilters);

export const fetchFiltersSuccess = createAction(SearchActions.fetchFiltersSuccess, props<{ data: any }>());

export const fetchFiltersFailure = createAction(SearchActions.fetchFiltersFailure, props<{ error: any }>());

export const fetchColumns = createAction(SearchActions.fetchColumns);

export const fetchColumnsSuccess = createAction(SearchActions.fetchColumnsSuccess, props<{ data: any }>());

export const fetchColumnsFailure = createAction(SearchActions.fetchColumnsFailure, props<{ error: any }>());

export const fetchSelectedColumns = createAction(SearchActions.fetchSelectedColumns);

export const fetchSelectedColumnsSuccess = createAction(
  SearchActions.fetchSelectedColumnsSuccess,
  props<{ data: any }>()
);

export const fetchSelectedColumnsFailure = createAction(
  SearchActions.fetchSelectedColumnsFailure,
  props<{ error: any }>()
);

export const updateColumnsOrder = createAction(
  SearchActions.updateColumnsOrder,
  props<{ columns: IColumn[]; module: string }>()
);

export const updateColumnsOrderSuccess = createAction(
  SearchActions.updateColumnsOrderSuccess,
  props<{ columns: IColumn[]; module: string }>()
);

export const updateColumnsOrderFailure = createAction(SearchActions.updateColumnsOrderFailure, props<{ error: any }>());

export const updateSelectedColumns = createAction(
  SearchActions.updateSelectedColumns,
  props<{ columns: IColumn[]; module: string }>()
);

export const updateSelectedColumnsSuccess = createAction(
  SearchActions.updateSelectedColumnsSuccess,
  props<{ columns: IColumn[] }>()
);

export const updateSelectedColumnsFailure = createAction(
  SearchActions.updateSelectedColumnsFailure,
  props<{ error: any }>()
);

export const setActiveResultsModule = createAction(SearchActions.setActiveResultsModule, props<{ module: string }>());
export const updateActiveResultsTab = createAction(
  SearchActions.updateActiveResultsTab,
  props<{ tab: SearchResultEntity }>()
);
export const updateFilterData = createAction(SearchActions.updateFilterData, props<{ filterData: IFilterItem[] }>());
export const updateSearchValue = createAction(SearchActions.updateSearchValue, props<{ searchValue: string }>());
export const updateFilterMode = createAction(SearchActions.updateFilterMode, props<{ filterMode: SEARCHTYPE }>());

export const setCurrentSearchState = createAction(SearchActions.setCurrentSearchState, props<ICurrentSearchState>());

export const fetchChartConfig = createAction(SearchActions.fetchChartConfig);

export const fetchChartConfigSuccess = createAction(
  SearchActions.fetchChartConfigSuccess,
  props<{ config: IChartConfig }>()
);

export const fetchChartConfigFailure = createAction(SearchActions.fetchChartConfigFailure, props<{ error: any }>());

export const saveQuery = createAction(
  SearchActions.saveQuery,
  props<{
    id: string;
    name: string;
    filters: IFilterItem[];
    columns: IColumn[];
    params: PaginatedItemsParams;
    filterTabId: string;
    selectedViewMode: string;
    module: string;
    searchValue?: string;
    chartGroupByOption?: string;
    chartXAxisOption?: string;
    chartXAxisInterval?: string;
    chartType?: string;
  }>()
);

export const saveQuerySuccess = createAction(
  SearchActions.saveQuerySuccess,
  props<{ summary: ISavedSearchQuerySummary }>()
);

export const saveQueryFailure = createAction(SearchActions.saveQueryFailure, props<{ error: any }>());

export const fetchQueries = createAction(SearchActions.fetchQueries);

export const fetchQueriesSuccess = createAction(
  SearchActions.fetchQueriesSuccess,
  props<{ data: ISavedSearchQuerySummary[] }>()
);

export const fetchQueriesFailure = createAction(SearchActions.fetchQueriesFailure, props<{ error: any }>());

export const fetchSavedQuery = createAction(
  SearchActions.fetchSavedQuery,
  props<{ query: ISavedSearchQuerySummary }>()
);

export const fetchSavedQuerySuccess = createAction(
  SearchActions.fetchSavedQuerySuccess,
  props<{ data: SavedSearchQuery }>()
);

export const fetchSavedQueryFailure = createAction(SearchActions.fetchSavedQueryFailure, props<{ error: any }>());

export const removeQuery = createAction(SearchActions.removeQuery, props<{ id: string }>());

export const removeQuerySuccess = createAction(SearchActions.removeQuerySuccess, props<{ id: string }>());

export const removeQueryFailure = createAction(SearchActions.removeQueryFailure, props<{ error: any }>());

export const clearLoadedSavedQuery = createAction(SearchActions.clearLoadedSavedQuery);

export const updateQuerySharedWith = createAction(
  SearchActions.updateQuerySharedWith,
  props<{ groups: SharedWithGroup[]; queryId: string }>()
);

export const updateQuerySharedWithSuccess = createAction(
  SearchActions.updateQuerySharedWithSuccess,
  props<{ groups: SharedWithGroup[]; queryId: string }>()
);

export const updateQuerySharedWithFailure = createAction(
  SearchActions.updateQuerySharedWithFailure,
  props<{ error: any }>()
);

export const fetchAvailableExports = createAction(SearchActions.fetchAvailableExports);

export const fetchAvailableExportsSuccess = createAction(
  SearchActions.fetchAvailableExportsSuccess,
  props<{ data: IExports }>()
);

export const fetchAvailableExportsFailure = createAction(
  SearchActions.fetchAvailableExportsFailure,
  props<{ error: any }>()
);

export const exportResults = createAction(SearchActions.exportResults, props<IExportResultsProps>());

export const exportResultsSuccess = createAction(SearchActions.exportResultsSuccess, props<IRequestedExport>());

export const exportResultsFailure = createAction(
  SearchActions.exportResultsFailure,
  props<{ error: any; id: string }>()
);
