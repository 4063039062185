import { createAction, props } from '@ngrx/store';

import { GenericWidgetDataActions } from './generic-widget-data.const';
import { PaginatedItemsParams, GenericWidgetData, GenericWidgetDataResponse } from './generic-widget-data.types';

import { CardView } from '~/ui/ui.module';

export const getGenericWidgetData = createAction(
  GenericWidgetDataActions.getGenericWidgetData,
  props<{
    id: string;
    widgetTypeCode: string;
    params: PaginatedItemsParams;
    refresh?: boolean;
  }>()
);

export const fetchGenericWidgetDataSuccess = createAction(
  GenericWidgetDataActions.getGenericWidgetDataSuccess,
  props<{ item: GenericWidgetDataResponse }>()
);

export const loadGenericWidgetDataSuccess = createAction(
  GenericWidgetDataActions.loadGenericWidgetDataSuccess,
  props<{ item: GenericWidgetData<CardView> }>()
);

export const loadGenericWidgetDataFailure = createAction(
  GenericWidgetDataActions.getGenericWidgetDataFailed,
  props<{ id: string; error: any }>()
);

export const toggleItemSelected = createAction(
  GenericWidgetDataActions.toggleItemSelected,
  props<{
    widgetId: string;
    itemId: number;
    checked: boolean;
  }>()
);

export const toggleAllItemsSelected = createAction(
  GenericWidgetDataActions.toggleAllItemsSelected,
  props<{
    widgetId: string;
    checked: boolean;
  }>()
);
