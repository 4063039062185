import { createAction, props } from '@ngrx/store';

import { AnnouncementEntity } from '~/repositories/announcements/store/announcement.models';

export const initAnnouncements = createAction('[Announcement Page] Init');

export const loadAnnouncementsSuccess = createAction(
  '[Announcements/API] Load Announcements Success',
  props<{ announcements: AnnouncementEntity[] }>()
);

export const loadAnnouncementsFailure = createAction(
  '[Announcements/API] Load Announcements Failure',
  props<{ error: any }>()
);

export const markAnnouncementsViewed = createAction(
  '[Announcement Page] View Announcements',
  props<{ announcementIds: string[] }>()
);

export const markAnnouncementsViewedSuccess = createAction(
  '[Announcements/API] View Announcements Success',
  props<{ announcementIds: string[] }>()
);

export const markAnnouncementsViewedFailure = createAction(
  '[Announcements/API] View Announcements Failure',
  props<{ error: any }>()
);

export const addNewAnnouncementsToStore = createAction(
  '[Announcements/API] Add New Announcements To Store',
  props<{ newAnnouncements: AnnouncementEntity[] }>()
);
