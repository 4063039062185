import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { of, take } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

export interface IError {
  httpStatusCode: number;
  httpStatusReason: string;
  message: string;
  timestamp: string;
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    super();
  }

  override handleError(error: Error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      if (confirm('New version available. Load New Version?')) {
        return window.location.reload();
      }
    }

    this.translateService
      .get('error.unexpectedError')
      .pipe(take(1))
      .subscribe((message: string) => {
        this.messageService.add({
          severity: 'error',
          detail: message,
          life: 3000
        });
      });

    this.httpClient
      .post<void>(`${environment.BACKEND_URL}/log/error`, {
        message: error.message,
        name: error.name,
        stack: error.stack,
        cause: error.cause,
        url: this.router.url
      })
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();

    super.handleError(error);
  }

  /**
   * Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
   * https://github.com/scttcper/ngx-toastr/issues/327
   */
  private get messageService(): MessageService {
    return this.injector.get(MessageService);
  }
}
