import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { map } from 'rxjs';

import { SEARCHTYPE } from '../search/search.const';
import { IExportResultsProps, IFilterItem, PaginatedItemsParams } from '../search/search.types';

import {
  clearLoadedSavedQuery,
  fetchAvailableExports,
  fetchChartConfig,
  fetchColumns,
  fetchFilters,
  fetchNewPage,
  fetchQueries,
  fetchSavedQuery,
  fetchSearchResults,
  fetchSelectedColumns,
  removeQuery,
  saveQuery,
  updateColumnsOrder,
  updateQuerySharedWith,
  updateSelectedColumns,
  exportResults,
  setActiveResultsModule,
  updateActiveResultsTab,
  updateFilterData,
  updateSearchValue,
  updateFilterMode,
  reset,
  setCurrentSearchState
} from '~/repositories/search/store/search.actions';
import { SearchResultEntity, SharedWithGroup } from '~/repositories/search/store/search.models';
import {
  selectActiveResultsTab,
  selectAllResults,
  selectAvailableExports,
  selectAvailableExportsLoading,
  selectChartConfig,
  selectChartConfigLoading,
  selectColumns,
  selectColumnsLoading,
  selectExports,
  selectFilterData,
  selectFilterMode,
  selectFilters,
  selectFiltersLoading,
  selectResultsLoading,
  selectSavedQueries,
  selectSavedQueriesLoading,
  selectSavedQuery,
  selectSaveQueryLoading,
  selectSearchValue,
  selectSelectedColumns,
  selectSelectedColumnsLoading,
  selectDefaultColumnsForTab,
  selectUpdateColumnsLoading,
  selectUpdateColumnsOrderLoading,
  selectUpdateQuerySharedWithLoading,
  selectActiveResultsModule,
  selectCurrentSearchState
} from '~/repositories/search/store/search.selectors';
import {
  IColumn,
  ICurrentSearchState,
  IFetchNewPageProps,
  IFetchSearchResultProps,
  IFilter,
  ISavedSearchQuerySummary
} from '~/repositories/search/store/search.types';

@Injectable()
export class SearchFacade {
  private readonly store = inject(Store);
  private readonly translateService = inject(TranslateService);

  results$ = this.store.pipe(select(selectAllResults));
  loadingResults$ = this.store.pipe(select(selectResultsLoading));
  filters$ = this.store.pipe(
    select(selectFilters),
    map((filters: IFilter[]): TreeNode[] => this.mapFilters(filters))
  );
  filtersLoading$ = this.store.pipe(select(selectFiltersLoading));
  columns$ = this.store.pipe(select(selectColumns));
  columnsLoading$ = this.store.pipe(select(selectColumnsLoading));
  selectedColumns$ = this.store.pipe(select(selectSelectedColumns));
  selectedColumnsLoading$ = this.store.pipe(select(selectSelectedColumnsLoading));
  defaultColumnsForTab$ = this.store.pipe(select(selectDefaultColumnsForTab));

  updateColumnsOrderLoading$ = this.store.pipe(select(selectUpdateColumnsOrderLoading));
  updateColumnsLoading$ = this.store.pipe(select(selectUpdateColumnsLoading));
  saveQueryLoading$ = this.store.pipe(select(selectSaveQueryLoading));
  savedQueries$ = this.store.pipe(select(selectSavedQueries));
  savedQueriesLoading$ = this.store.pipe(select(selectSavedQueriesLoading));
  savedQuery$ = this.store.pipe(select(selectSavedQuery));
  savedQueryLoading$ = this.store.pipe(select(selectSavedQueriesLoading));
  shareQueryLoading$ = this.store.pipe(select(selectUpdateQuerySharedWithLoading));
  chartConfig$ = this.store.pipe(select(selectChartConfig));
  chartConfigLoading$ = this.store.pipe(select(selectChartConfigLoading));
  availableExports$ = this.store.pipe(select(selectAvailableExports));
  availableExportsLoading$ = this.store.pipe(select(selectAvailableExportsLoading));
  exports$ = this.store.pipe(select(selectExports));
  activeResultsTab$ = this.store.pipe(select(selectActiveResultsTab));
  activeResultsModule$ = this.store.pipe(select(selectActiveResultsModule));
  filterData$ = this.store.pipe(select(selectFilterData));
  searchValue$ = this.store.pipe(select(selectSearchValue));
  filterMode$ = this.store.pipe(select(selectFilterMode));

  currentSearchState$ = this.store.pipe(select(selectCurrentSearchState));

  reset() {
    this.store.dispatch(reset());
  }

  fetchSearchResults(data: IFetchSearchResultProps) {
    this.store.dispatch(fetchSearchResults({ data }));
  }

  fetchNewPage(data: IFetchNewPageProps) {
    this.store.dispatch(fetchNewPage({ data }));
  }

  fetchChartConfig() {
    this.store.dispatch(fetchChartConfig());
  }

  fetchFilters() {
    this.store.dispatch(fetchFilters());
  }

  fetchColumns() {
    this.store.dispatch(fetchColumns());
  }

  fetchAvailableExports() {
    this.store.dispatch(fetchAvailableExports());
  }

  fetchSelectedColumns() {
    this.store.dispatch(fetchSelectedColumns());
  }

  updateColumnsOrder(columns: IColumn[], module: string) {
    this.store.dispatch(updateColumnsOrder({ columns, module }));
  }

  updateSelectedColumns(columns: IColumn[], module: string) {
    this.store.dispatch(updateSelectedColumns({ columns, module }));
  }

  setActiveResultsModule(module: string) {
    this.store.dispatch(setActiveResultsModule({ module }));
  }

  updateActiveResultsTab(tab: SearchResultEntity) {
    this.store.dispatch(updateActiveResultsTab({ tab }));
  }

  updateFilterData(filterData: IFilterItem[]) {
    this.store.dispatch(updateFilterData({ filterData }));
  }

  updateSearchValue(searchValue: string) {
    this.store.dispatch(updateSearchValue({ searchValue }));
  }

  updateFilterMode(filterMode: SEARCHTYPE) {
    this.store.dispatch(updateFilterMode({ filterMode }));
  }

  setCurrentSearchState(state: ICurrentSearchState) {
    this.store.dispatch(setCurrentSearchState(state));
  }

  saveQuery(
    id: string,
    name: string,
    filters: IFilterItem[],
    columns: IColumn[],
    params: PaginatedItemsParams,
    filterTabId: string,
    selectedViewMode: string,
    module: string,
    searchValue?: string,
    chartGroupByOption?: string,
    chartXAxisOption?: string,
    chartXAxisInterval?: string,
    chartType?: string
  ) {
    this.store.dispatch(
      saveQuery({
        id,
        name,
        filters,
        columns,
        params,
        filterTabId,
        selectedViewMode,
        module,
        searchValue,
        chartGroupByOption,
        chartXAxisOption,
        chartXAxisInterval,
        chartType
      })
    );
  }

  fetchQueries() {
    this.store.dispatch(fetchQueries());
  }

  openQuery(query: ISavedSearchQuerySummary) {
    this.store.dispatch(fetchSavedQuery({ query }));
  }

  removeQuery(id: string) {
    this.store.dispatch(removeQuery({ id }));
  }

  clearLoadedSavedQuery() {
    this.store.dispatch(clearLoadedSavedQuery());
  }

  updateQuerySharedWith(groups: SharedWithGroup[], queryId: string) {
    this.store.dispatch(updateQuerySharedWith({ groups, queryId }));
  }

  exportResults(props: IExportResultsProps) {
    this.store.dispatch(exportResults(props));
  }

  private mapFilters(filters: IFilter[]): TreeNode[] {
    const mappedFilters: TreeNode[] = [];

    filters?.forEach((filter: IFilter) => {
      if (filter.module) {
        const filterModule = mappedFilters.find((item) => item.key === filter.module);

        // if parent does not exist yet
        if (!filterModule) {
          return mappedFilters.push({
            label: filter.moduleLabel,
            key: filter.module,
            type: filter.type,
            selectable: false,
            children: [
              {
                label: filter.label,
                key: filter.code,
                type: filter.type,
                data: {
                  ...filter,
                  operators: filter.operators.map((operator: string) => ({
                    key: operator,
                    value: this.translateService.instant('search.operator.' + operator.toLowerCase())
                  }))
                }
              }
            ]
          });
        }
        return filterModule.children.push({
          label: filter.label,
          key: filter.code,
          type: filter.type,
          data: {
            ...filter,
            operators: filter.operators.map((operator: string) => ({
              key: operator,
              value: this.translateService.instant('search.operator.' + operator.toLowerCase())
            }))
          }
        });
      }

      return mappedFilters.push({
        label: filter.label,
        key: filter.code,
        type: filter.type,
        data: {
          ...filter,
          operators: filter.operators.map((operator: string) => ({
            key: operator,
            value: this.translateService.instant('search.operator.' + operator.toLowerCase())
          }))
        },
        children: null
      });
    });

    return mappedFilters;
  }
}
