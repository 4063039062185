import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep, groupBy } from 'lodash';

import {
  clearLoadedSavedQuery,
  exportResults,
  exportResultsFailure,
  exportResultsSuccess,
  fetchAvailableExports,
  fetchAvailableExportsFailure,
  fetchAvailableExportsSuccess,
  fetchChartConfig,
  fetchChartConfigFailure,
  fetchChartConfigSuccess,
  fetchColumns,
  fetchColumnsFailure,
  fetchColumnsSuccess,
  fetchFilters,
  fetchFiltersFailure,
  fetchFiltersSuccess,
  fetchNewPage,
  fetchNewPageFailure,
  fetchNewPageSuccess,
  fetchQueries,
  fetchQueriesFailure,
  fetchQueriesSuccess,
  fetchSavedQuery,
  fetchSavedQueryFailure,
  fetchSavedQuerySuccess,
  fetchSearchResults,
  fetchSearchResultsFailure,
  fetchSearchResultsSuccess,
  fetchSelectedColumns,
  fetchSelectedColumnsFailure,
  fetchSelectedColumnsSuccess,
  removeQuery,
  removeQueryFailure,
  removeQuerySuccess,
  reset,
  saveQuery,
  saveQueryFailure,
  saveQuerySuccess,
  setActiveResultsModule,
  setCurrentSearchState,
  updateActiveResultsTab,
  updateColumnsOrder,
  updateFilterData,
  updateFilterMode,
  updateQuerySharedWith,
  updateQuerySharedWithFailure,
  updateQuerySharedWithSuccess,
  updateSearchValue,
  updateSelectedColumns
} from './search.actions';
import { SavedSearchQuery, SearchResultEntity } from './search.models';
import {
  IColumn,
  ICurrentSearchState,
  IExports,
  IFilter,
  IRequestedExport,
  ISavedSearchQuerySummary
} from './search.types';

import { SEARCHTYPE, VIEWMODES } from '~/search/search/search.const';

export interface SearchState extends EntityState<SearchResultEntity>, ICurrentSearchState {
  loading: boolean;
  error: any;
  filtersLoading: boolean;
  filters: IFilter[];
  columnsLoading: boolean;
  columns: IColumn[];
  selectedColumnsLoading: boolean;
  updateColumnsOrderLoading: boolean;
  updateColumnsLoading: boolean;
  chartConfigLoading: boolean;
}

export interface QueryState extends EntityState<ISavedSearchQuerySummary> {
  saveQueryLoading: boolean;
  queriesLoading: boolean;
  savedQueryLoading: boolean;
  savedQuery: SavedSearchQuery;
  updateSharedWithLoading: boolean;
}

export interface ExportsState extends EntityState<IRequestedExport> {
  availableExportsLoading: boolean;
  availableExports: IExports;
}

export interface ISearchModuleState {
  search: SearchState;
  query: QueryState;
  exports: ExportsState;
}

export const searchAdapter: EntityAdapter<SearchResultEntity> = createEntityAdapter<SearchResultEntity>({
  selectId: (result: SearchResultEntity) => result.module
});

export const queryAdapter: EntityAdapter<ISavedSearchQuerySummary> = createEntityAdapter<ISavedSearchQuerySummary>({
  selectId: (result: ISavedSearchQuerySummary) => result.id
});

export const exportsAdapter: EntityAdapter<IRequestedExport> = createEntityAdapter<IRequestedExport>({
  selectId: (result: IRequestedExport) => result.id
});

export const initialSearchState: SearchState = searchAdapter.getInitialState({
  // set initial required properties
  loading: false,
  error: null,
  filtersLoading: false,
  filters: null,
  columnsLoading: false,
  columns: null,
  selectedColumns: null,
  selectedColumnsLoading: false,
  updateColumnsOrderLoading: false,
  updateColumnsLoading: false,
  chartConfig: null,
  chartConfigLoading: false,
  activeResultsModule: null,
  filterData: [],
  searchValue: '',
  filterMode: SEARCHTYPE.SIMPLE
});

export const initialQueryState: QueryState = queryAdapter.getInitialState({
  saveQueryLoading: false,
  queriesLoading: false,
  savedQueryLoading: false,
  savedQuery: null,
  removeQueryLoading: false,
  selectedColumns: null,
  updateSharedWithLoading: false
});

export const initialExportsState: ExportsState = exportsAdapter.getInitialState({
  availableExportsLoading: false,
  availableExports: null
});

const searchReducer = createReducer(
  initialSearchState,
  on(reset, (state) => ({
    ...initialSearchState,
    columns: state.columns,
    filters: state.filters,
    chartConfig: state.chartConfig
  })),
  on(fetchSearchResults, (state, { data }) => {
    const stateChanges: SearchState = {
      ...state,
      loading: true,
      error: null
    };

    if (data.queryModule) {
      return searchAdapter.upsertOne(
        {
          chartGroupByOption: data.viewModeParams['chartGroupByOption'] as string,
          chartXAxisOption: data.viewModeParams['chartXAxisOption'] as string,
          chartXAxisInterval: data.viewModeParams['chartXAxisInterval'] as string,
          module: data.queryModule
        },
        stateChanges
      );
    }
    return stateChanges;
  }),
  on(fetchSearchResultsSuccess, (state, { data, queryModule }) => {
    const activeResultsModuleHasData =
      data.findIndex((item) => item.module === state.activeResultsModule && item.totalItems > 0) >= 0;

    const activeModule = activeResultsModuleHasData ? state.activeResultsModule : data[0]?.module;
    const activeModuleDataIndex = data.findIndex((item) => item.module === activeModule);

    const updatedData = cloneDeep(data);

    if (updatedData[activeModuleDataIndex]?.view !== VIEWMODES.LIST) {
      updatedData[activeModuleDataIndex] = {
        ...state.entities[activeModule],
        ...updatedData[activeModuleDataIndex]
      };
    }

    return searchAdapter.setAll(updatedData, {
      ...state,
      activeResultsModule: activeModule,
      loading: false
    });
  }),
  on(fetchSearchResultsFailure, (state, { error }) =>
    searchAdapter.removeAll({
      ...state,
      loading: false,
      error
    })
  ),
  on(fetchNewPage, (state, { data }) => {
    const viewModeParams = data.viewModeParams
      ? {
          chartGroupByOption: data.viewModeParams['chartGroupByOption'] as string,
          chartXAxisOption: data.viewModeParams['chartXAxisOption'] as string,
          chartXAxisInterval: data.viewModeParams['chartXAxisInterval'] as string
        }
      : undefined;

    return searchAdapter.upsertOne(
      {
        loading: true,
        module: data.module,
        ...viewModeParams
      },
      state
    );
  }),
  on(fetchNewPageSuccess, (state, { data }) =>
    searchAdapter.upsertOne(
      {
        ...data,
        loading: false,
        id: data.module
      },
      state
    )
  ),
  on(fetchNewPageFailure, (state, { error }) => ({
    ...state,
    error: error
  })),
  on(fetchFilters, (state) => ({
    ...state,
    filtersLoading: true
  })),
  on(fetchFiltersSuccess, (state, { data }) => ({
    ...state,
    filtersLoading: false,
    filters: data
  })),
  on(fetchFiltersFailure, (state, { error }) => ({
    ...state,
    error: error
  })),
  on(fetchColumns, (state) => ({
    ...state,
    columnsLoading: true,
    columns: [],
    selectedColumns: null
  })),
  on(fetchColumnsSuccess, (state, { data }) => ({
    ...state,
    columnsLoading: false,
    columns: data,
    selectedColumns: !Object.keys(state.selectedColumns || {})?.length
      ? groupBy(
          data.filter((col: IColumn) => col.defaultColumn),
          'module'
        )
      : state.selectedColumns
  })),
  on(fetchColumnsFailure, (state, { error }) => ({
    ...state,
    error: error
  })),
  on(fetchSelectedColumns, (state) => ({
    ...state,
    selectedColumnsLoading: true
  })),
  on(fetchSelectedColumnsSuccess, (state, { data }) => ({
    ...state,
    selectedColumnsLoading: false,
    selectedColumns: data
  })),
  on(fetchSelectedColumnsFailure, (state, { error }) => ({
    ...state,
    error: error
  })),
  on(updateColumnsOrder, (state, { columns, module }) => ({
    ...state,
    selectedColumns: { ...state.selectedColumns, [module]: columns }
  })),
  on(updateSelectedColumns, (state, { columns, module }) => ({
    ...state,
    selectedColumns: { ...state.selectedColumns, [module]: columns }
  })),
  on(fetchSavedQuery, (state) => searchAdapter.removeAll({ ...state })),
  on(fetchSavedQuerySuccess, (state, { data }) => ({
    ...state,
    selectedColumns: groupBy(
      data.columns.flatMap((key) => state.columns.find((item) => item.code === key) || []),
      'module'
    ),
    activeResultsModule: data.module,
    filterMode: data.filterGroup ? SEARCHTYPE.EXTENDED : SEARCHTYPE.SIMPLE,
    chartGroupByOption: data.chartGroupByOption,
    chartXAxisOption: data.chartXAxisOption,
    chartXAxisInterval: data.chartXAxisInterval
  })),
  on(fetchChartConfig, (state) => ({ ...state, chartConfigLoading: true })),
  on(fetchChartConfigSuccess, (state, { config }) => ({
    ...state,
    chartConfig: config,
    chartConfigLoading: false
  })),
  on(fetchChartConfigFailure, (state, { error }) => ({
    ...state,
    error,
    chartConfig: null,
    chartConfigLoading: false
  })),
  on(setActiveResultsModule, (state, { module }) => ({
    ...state,
    activeResultsModule: module
  })),
  on(updateActiveResultsTab, (state, { tab }) => {
    if (!tab) {
      return searchAdapter.removeAll(state);
    }
    return searchAdapter.updateOne(
      {
        changes: {
          ...tab
        },
        id: tab?.module
      },
      state
    );
  }),
  on(updateFilterData, (state, { filterData }) => ({
    ...state,
    filterData
  })),
  on(updateSearchValue, (state, { searchValue }) => ({
    ...state,
    searchValue
  })),
  on(updateFilterMode, (state, { filterMode }) => ({
    ...state,
    filterMode
  })),
  on(setCurrentSearchState, (state, currentSearchState) => ({
    ...state,
    ...currentSearchState
  }))
);

const queryReducer = createReducer(
  initialQueryState,
  on(saveQuery, (state) => ({
    ...state,
    saveQueryLoading: true
  })),
  on(saveQuerySuccess, (state, { summary }) =>
    queryAdapter.addOne(summary, {
      ...state,
      saveQueryLoading: false
    })
  ),
  on(saveQueryFailure, (state, { error }) => ({
    ...state,
    error: error,
    saveQueryLoading: false
  })),
  on(fetchQueries, (state) => ({
    ...state,
    queriesLoading: true,
    error: null
  })),
  on(fetchQueriesSuccess, (state, { data }) =>
    queryAdapter.setAll(data, {
      ...state,
      queriesLoading: false
    })
  ),
  on(fetchQueriesFailure, (state, { error }) =>
    queryAdapter.removeAll({
      ...state,
      queriesLoading: false,
      error
    })
  ),
  on(fetchSavedQuery, (state) => ({
    ...state,
    savedQuery: null,
    savedQueryLoading: true,
    error: null
  })),
  on(fetchSavedQuerySuccess, (state, { data }) => ({
    ...state,
    savedQuery: data,
    saveQueryLoading: false
  })),
  on(fetchSavedQueryFailure, (state, { error }) => ({
    ...state,
    savedQueryLoading: false,
    error
  })),
  on(removeQuery, (state) => ({
    ...state,
    removeQueryLoading: true,
    error: null
  })),
  on(removeQuerySuccess, (state, { id }) => {
    return queryAdapter.removeOne(id, {
      ...state,
      removeQueryLoading: false
    });
  }),
  on(removeQueryFailure, (state, { error }) => ({
    ...state,
    removeQueryLoading: false,
    error
  })),
  on(clearLoadedSavedQuery, (state) => ({
    ...state,
    savedQuery: null
  })),
  on(updateQuerySharedWith, (state) => ({
    ...state,
    updateSharedWithLoading: true,
    error: null
  })),
  on(updateQuerySharedWithSuccess, (state, { groups, queryId }) => {
    return queryAdapter.updateOne(
      {
        changes: {
          shared: !!groups.length
        },
        id: queryId
      },
      {
        ...state,
        updateSharedWithLoading: false
      }
    );
  }),
  on(updateQuerySharedWithFailure, (state, { error }) => ({
    ...state,
    updateSharedWithLoading: false,
    error
  }))
);

const exportsReducer = createReducer(
  initialExportsState,
  on(fetchAvailableExports, (state) => ({
    ...state,
    availableExportsLoading: true
  })),
  on(fetchAvailableExportsSuccess, (state, { data }) => ({
    ...state,
    availableExportsLoading: false,
    availableExports: data
  })),
  on(fetchAvailableExportsFailure, (state, { error }) => ({
    ...state,
    error: error,
    availableExportsLoading: false
  })),
  on(exportResults, (state, { timestamp, exportCode, module, label }) =>
    exportsAdapter.addOne(
      {
        id: timestamp,
        code: exportCode,
        module,
        label,
        loading: true,
        isNew: true
      },
      state
    )
  ),
  on(exportResultsSuccess, (state, action) =>
    exportsAdapter.updateOne(
      {
        changes: {
          loading: false,
          error: false,
          exportId: action.exportId
        },
        id: action.id
      },
      state
    )
  ),
  on(exportResultsFailure, (state, action) =>
    exportsAdapter.updateOne(
      {
        changes: {
          loading: false,
          error: true
        },
        id: action.id
      },
      state
    )
  )
);

export function searchReducerFunction(state: SearchState | undefined, action: Action) {
  return searchReducer(state, action);
}

export function queryReducerFunction(state: QueryState | undefined, action: Action) {
  return queryReducer(state, action);
}

export function exportsReducerFunction(state: ExportsState | undefined, action: Action) {
  return exportsReducer(state, action);
}
