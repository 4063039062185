import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';

import * as TabsActions from './tabs.actions';
import { TabEntity, WidgetInfo } from './tabs.models';

export const TABS_FEATURE_KEY = 'tabs';

export interface TabsState extends EntityState<TabEntity> {
  selectedId: string; // which Tabs record has been selected
  editModeEnabled: boolean; // toggle edit mode
  loaded: boolean; // has the Tabs list been loaded
  error?: string | null; // last known error (if any)
  addTabLoading: boolean;
  addTabError: string | null;
  updateOrderLoading: boolean;
  updateOrderError: string | null;
  updateSharedWithLoading: boolean;
  updateSharedWithError: string | null;
  updateTabTitleLoading: boolean;
  updateTabTitleError: string | null;
  removeTabLoading: boolean;
  removeTabError: string | null;
  moveWidgetLoading: boolean;
}

export interface IState {
  tabs: TabsState;
}

export interface TabsPartialState {
  readonly [TABS_FEATURE_KEY]: TabsState;
}

export const tabsAdapter: EntityAdapter<TabEntity> = createEntityAdapter<TabEntity>();

export const initialTabsState: TabsState = tabsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  selectedId: null,
  addTabLoading: false,
  addTabError: null,
  updateOrderLoading: false,
  updateOrderError: null,
  updateSharedWithLoading: false,
  updateSharedWithError: null,
  editModeEnabled: false,
  updateTabTitleLoading: false,
  updateTabTitleError: null,
  removeTabLoading: false,
  removeTabError: null,
  moveWidgetLoading: false
});

const reducer = createReducer(
  initialTabsState,
  on(TabsActions.initTabs, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(TabsActions.loadTabsSuccess, (state, { tabs }) => {
    const activeTab = tabs.find((tab) => tab.active);

    return tabsAdapter.setAll(tabs, {
      ...state,
      loaded: true,
      selectedId: activeTab?.id || tabs[0]?.id || null
    });
  }),
  on(TabsActions.loadTabsFailure, (state, { error }) => ({ ...state, error })),

  on(TabsActions.selectTab, (state, { tabId }) => ({
    ...state,
    selectedId: tabId
  })),
  on(TabsActions.selectTabFailure, (state, { error }) => ({ ...state, error })),

  on(TabsActions.saveTab, (state) => ({
    ...state,
    addTabLoading: true
  })),
  on(TabsActions.saveTabSuccess, (state, { tab }) => {
    const isOwnTab = tab.owner === environment.USERNAME;
    return tabsAdapter.addOne(tab, {
      ...state,
      selectedId: tab.id,
      addTabLoading: false,
      editModeEnabled: isOwnTab
    });
  }),
  on(TabsActions.saveTabFailure, (state, { error }) => ({
    ...state,
    addTabError: error,
    addTabLoading: false
  })),

  on(TabsActions.updateTabsOrder, (state) => ({
    ...state,
    updateOrderLoading: true
  })),
  on(TabsActions.updateTabsOrderSuccess, (state, { tabs }) => {
    return tabsAdapter.setAll(tabs, {
      ...state,
      updateOrderLoading: false
    });
  }),
  on(TabsActions.updateTabsOrderFailure, (state, { error }) => ({
    ...state,
    updateOrderError: error,
    updateOrderLoading: false
  })),

  on(TabsActions.updateTabSharedWith, (state) => ({
    ...state,
    updateSharedWithLoading: true
  })),
  on(TabsActions.updateTabSharedWithSuccess, (state) => ({
    ...state,
    updateSharedWithError: null,
    updateSharedWithLoading: false
  })),
  on(TabsActions.updateTabSharedWithFailure, (state, { error }) => ({
    ...state,
    updateSharedWithError: error,
    updateSharedWithLoading: false
  })),

  on(TabsActions.setEditMode, (state, { enabled }) => ({
    ...state,
    editModeEnabled: enabled
  })),
  on(TabsActions.updateTabTitle, (state) => ({
    ...state,
    updateTabTitleLoading: true
  })),
  on(TabsActions.updateTabTitleSuccess, (state, { tab }) => {
    return tabsAdapter.updateOne(
      {
        changes: tab,
        id: tab.id
      },
      {
        ...state,
        updateTabTitleLoading: false,
        updateTabTitleError: null
      }
    );
  }),
  on(TabsActions.updateTabTitleFailure, (state, { error }) => ({
    ...state,
    updateTabTitleLoading: false,
    updateTabTitleError: error
  })),
  on(TabsActions.addNewWidgetSuccess, (state, { widgetConfig }) => {
    const tab: TabEntity = cloneDeep(state.entities[state.selectedId]);
    const widgetInfo: WidgetInfo = {
      widgetId: widgetConfig.id,
      widgetTypeId: widgetConfig.widgetTypeId,
      tabId: state.selectedId
    };

    tab.widgets.unshift(widgetInfo);

    return tabsAdapter.updateOne(
      {
        changes: tab,
        id: state.selectedId
      },
      state
    );
  }),
  on(TabsActions.addNewWidgetFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(TabsActions.moveWidget, (state) => ({
    ...state,
    moveWidgetLoading: true
  })),
  on(TabsActions.moveWidgetSuccess, (state, { widgetInfo, tabId }) => {
    const { entities } = state;
    const tabs = Object.values(entities);

    const updatedTabs: Update<TabEntity>[] = tabs.map((tab) => {
      if (tab.widgets.some((widget) => widget.widgetId === widgetInfo.widgetId)) {
        const updatedWidgets = tab.widgets.filter((widget) => widget.widgetId !== widgetInfo.widgetId);
        return { id: tab.id, changes: { ...tab, widgets: updatedWidgets } };
      } else if (tab.id === tabId) {
        return {
          id: tab.id,
          changes: { ...tab, widgets: [...tab.widgets, widgetInfo] }
        };
      } else {
        return { id: tab.id, changes: tab };
      }
    });

    return tabsAdapter.updateMany(updatedTabs, {
      ...state,
      moveWidgetLoading: false
    });
  }),
  on(TabsActions.moveWidgetFailure, (state, { error }) => ({
    ...state,
    moveWidgetLoading: false,
    error
  })),
  on(TabsActions.removeWidgetFromTab, (state, { widgetId }) => {
    const { entities, selectedId } = state;
    const tab: TabEntity = cloneDeep(entities[selectedId]);
    const widgets = tab.widgets.filter((widget) => widget.widgetId !== widgetId);

    return tabsAdapter.updateOne(
      {
        changes: {
          ...tab,
          widgets
        },
        id: state.selectedId
      },
      state
    );
  }),
  on(TabsActions.removeWidgetFromTabFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(TabsActions.removeTab, (state) => ({
    ...state,
    removeTabLoading: true
  })),
  on(TabsActions.removeTabSuccess, (state, { tabId }) => {
    const tabIndex = state.ids.findIndex((id) => id == tabId);

    return tabsAdapter.removeOne(tabId, {
      ...state,
      removeTabLoading: false,
      selectedId: (tabIndex > 0 ? state.ids[tabIndex - 1] : state.ids[tabIndex + 1]).toString()
    });
  }),
  on(TabsActions.removeTabFailure, (state, { error }) => ({
    ...state,
    error,
    removeTabLoading: false
  }))
);

export function tabsReducer(state: TabsState | undefined, action: Action) {
  return reducer(state, action);
}
