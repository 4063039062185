<p-dialog
  [(visible)]="visible"
  header="{{ 'management.superadmin.loginas' | translate }}"
  [style]="{ width: '600px' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="onClose()"
  [closable]="(loginAsLoading$ | async) !== true">
  <div class="flex flex-column gap-2 mb-3">
    <label htmlFor="title">{{ 'management.superadmin.loginas.username' | translate }}</label>
    <input
      pInputText
      id="username"
      [(ngModel)]="username"
      [ngClass]="{ 'ng-invalid ng-dirty': usernameInvalid }"
      [disabled]="loginAsLoading$ | async" />
    <small *ngIf="usernameInvalid" id="input-error-help" class="p-error block">{{
      'management.superadmin.loginas.username.error' | translate
    }}</small>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-start">
      <button
        type="button"
        pButton
        label="{{ 'common.button.continue' | translate }}"
        (click)="onLoginAs()"
        [loading]="loginAsLoading$ | async"
        [disabled]="loginAsLoading$ | async"></button>
      <button
        type="button"
        pButton
        label="{{ 'common.button.cancel' | translate }}"
        class="p-button-outlined"
        (click)="onClose()"
        [disabled]="loginAsLoading$ | async"></button>
    </div>
  </ng-template>

  <ng-template pTemplate="closeicon">
    <div
      class="p-dialog-header-icon"
      [title]="
        'dialog.button.close'
          | translate
            : {
                '0': 'management.superadmin.loginas' | translate
              }
      ">
      <i class="text-lg fa-regular fa-xmark text-gray-800"></i>
    </div>
  </ng-template>
</p-dialog>
