import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as WrittenQuestionActions from './written-questions.actions';

import {
  WrittenQuestionEntity,
  WrittenQuestionsFilter
} from '~/repositories/written-questions/store/written-questions.models';

export const WRITTEN_QUESTIONS_FEATURE_KEY = 'writtenQuestions';

export interface WrittenQuestionsState extends EntityState<WrittenQuestionEntity> {
  filter: WrittenQuestionsFilter;
  loaded: boolean;
}

export interface IState {
  writtenQuestions: WrittenQuestionsState;
}

export interface WrittenQuestionsPartialState {
  readonly [WRITTEN_QUESTIONS_FEATURE_KEY]: WrittenQuestionsState;
}

export const writtenQuestionsAdapter: EntityAdapter<WrittenQuestionEntity> =
  createEntityAdapter<WrittenQuestionEntity>();

export const initialWrittenQuestionsState: WrittenQuestionsState = writtenQuestionsAdapter.getInitialState({
  // set initial required properties
  filter: {},
  loaded: false
});

const reducer = createReducer(
  initialWrittenQuestionsState,
  on(WrittenQuestionActions.fetchWrittenQuestions, (state, { filter }) => ({
    ...state,
    filter: filter,
    loaded: false
  })),
  on(WrittenQuestionActions.fetchWrittenQuestionsSuccess, (state, { writtenQuestions }) =>
    writtenQuestionsAdapter.setAll(writtenQuestions, {
      ...state,
      loaded: true
    })
  ),
  on(WrittenQuestionActions.fetchWrittenQuestionsFailure, (state) => ({
    ...state,
    loaded: false
  })),
  on(WrittenQuestionActions.publishWrittenQuestions, (state) => ({
    ...state
  })),
  on(WrittenQuestionActions.publishWrittenQuestionsSuccess, (state, { writtenQuestions }) => {
    const changes = writtenQuestions.map((wq) => {
      return {
        id: wq.id,
        changes: wq
      };
    });
    return writtenQuestionsAdapter.updateMany(changes, {
      ...state
    });
  }),
  on(WrittenQuestionActions.publishWrittenQuestionsFailure, (state) => ({
    ...state
  }))
);

export function writtenQuestionsReducer(state: WrittenQuestionsState, action: Action): WrittenQuestionsState {
  return reducer(state, action);
}
