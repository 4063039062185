import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

export interface PrintListModule {
  counter: number;
  urgent: boolean;
  tooltip: string;
}

@Injectable({
  providedIn: 'root'
})
export class PrintListModuleService {
  constructor(private readonly http: HttpClient) {}

  getPrintList(): Observable<PrintListModule> {
    return this.http.get<PrintListModule>(`${environment.BACKEND_URL}/navigation/printlistmodule`);
  }
}
