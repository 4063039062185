import { Location, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl-BE';
import { APP_INITIALIZER, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import 'moment/locale/nl-be'; // without this line it didn't work
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { AppComponent } from './app.component';
import { TabsFacade } from './dashboard/facades/tabs.facade';
import { KeycloakInitializer } from './keycloak.initializer';
import { RealmService } from './realm.service';
import { SharedModule } from './shared/shared.module';
import { HttpTranslationsLoader } from './translations.loader';
import { UserDataFacade } from './userdata/facades/userdata.facade';
import { VotingModule } from './voting/voting.module';

import { CoreModule } from '~/core/core.module';
import { LoginAsInterceptor } from '~/core/http-interceptors/login-as-interceptor';
import { SuiteViewComponent } from '~/core/suite-view/suite-view.component';
import { RepositoriesModule, ThemeFacade } from '~/repositories/repositories.module';
import { UiModule } from '~/ui/ui.module';

export function LocalizeRouterHttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

@NgModule({
  declarations: [AppComponent, SuiteViewComponent],
  imports: [
    BrowserModule,
    ToastModule,
    BrowserAnimationsModule,
    ButtonModule,
    UiModule,
    KeycloakAngularModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslationsLoader,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([], {
      enableTracing: false,
      initialNavigation: 'disabled',
      onSameUrlNavigation: 'reload'
    }),
    LocalizeRouterModule.forRoot([], {
      parser: {
        provide: LocalizeParser,
        useFactory: LocalizeRouterHttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
      initialNavigation: true,
      alwaysSetPrefix: false
    }),

    // App imports
    CoreModule,
    RepositoriesModule,
    DialogModule,
    CardModule,
    VotingModule,
    SharedModule,
    ProgressSpinnerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginAsInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: KeycloakInitializer,
      multi: true,
      deps: [KeycloakService, RealmService, Router]
    },
    MessageService,
    TabsFacade,
    UserDataFacade,
    ThemeFacade
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  constructor() {
    registerLocaleData(localeNl, 'nl');
    moment.locale('nl-be');
    document.documentElement.lang = 'nl';
  }
}
