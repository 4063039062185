import { createFeatureSelector, createSelector } from '@ngrx/store';

import { announcementsAdapter, AnnouncementsState } from '~/repositories/announcements/store/announcement.reducer';
import { IRepositoryState } from '~/repositories/repositories.store';

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');

export const selectAnnouncementsState = createSelector(
  REPOSITORY_SELECTOR,
  (state: IRepositoryState) => state.announcements
);

const { selectAll, selectEntities } = announcementsAdapter.getSelectors();

export const selectAllAnnouncements = createSelector(selectAnnouncementsState, (state: AnnouncementsState) =>
  selectAll(state)
);

export const selectAnnouncementsLoaded = createSelector(
  selectAnnouncementsState,
  (state: AnnouncementsState) => state.loaded
);
