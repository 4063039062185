import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '~/core/core.module';

export class TranslationsLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getTranslation(lang: string): any {
    if (!lang) {
      return;
    }

    const language = lang.substr(0, 2);

    return this.httpClient.get(`${environment.BACKEND_URL}/translations/${language}`).pipe(
      map((res: any) => get(res, 0, res)),
      catchError(() => of({}))
    );
  }
}

// AoT requires an exported function for factories
export function HttpTranslationsLoader(http: HttpClient) {
  return new TranslationsLoader(http);
}
