import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { finalize, interval, take } from 'rxjs';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() set step(num: number) {
    if (num) {
      interval(num)
        .pipe(
          take(101),
          finalize(() => {
            this.timeIsUp.emit();
          })
        )
        .subscribe((x: number) => this.interval.update(() => x));
    }
  }
  @Output() timeIsUp = new EventEmitter();

  interval = signal<number>(0);
}
