import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as ArchiveActions from './archive.actions';
import { ArchiveTabDataEntity, ArchiveTab } from './archive.models';

export const ARCHIVE_FEATURE_KEY = 'archive';

export interface ArchiveState extends EntityState<ArchiveTabDataEntity> {
  tabs: ArchiveTab[];
  selectedTab: ArchiveTab;
}

export interface IState {
  archive: ArchiveState;
}

export interface ArchiveDataPartialState {
  readonly [ARCHIVE_FEATURE_KEY]: ArchiveState;
}

export const archiveAdapter: EntityAdapter<ArchiveTabDataEntity> = createEntityAdapter<ArchiveTabDataEntity>();

export const initialArchiveState: ArchiveState = archiveAdapter.getInitialState({
  // set initial required properties
  tabs: [],
  selectedTab: null
});

const reducer = createReducer(
  initialArchiveState,
  on(ArchiveActions.fetchArchiveTabs, (state) => ({
    ...state
  })),
  on(ArchiveActions.fetchArchiveTabsSuccess, (state, { tabs }) =>
    archiveAdapter.setAll(tabs, {
      ...state,
      tabs: tabs.map((t) => ({
        id: t.id,
        label: t.label,
        module: t.module
      }))
    })
  ),
  on(ArchiveActions.fetchArchiveDataFailure, (state) => ({
    ...state
  })),
  on(ArchiveActions.setSelectedTab, (state, { tab }) => ({
    ...state,
    selectedTab: tab
  })),
  on(ArchiveActions.fetchArchiveData, (state, { filter }) =>
    archiveAdapter.updateOne(
      {
        changes: { filter, loaded: false },
        id: state.selectedTab.id
      },
      state
    )
  ),
  on(ArchiveActions.fetchArchiveDataSuccess, (state, { result }) =>
    archiveAdapter.updateOne(
      {
        changes: { result, loaded: true },
        id: state.selectedTab.id
      },
      state
    )
  ),
  on(ArchiveActions.fetchArchiveDataFailure, (state) =>
    archiveAdapter.updateOne(
      {
        changes: { loaded: true },
        id: state.selectedTab.id
      },
      state
    )
  )
);

export function archiveReducer(state: ArchiveState, action: Action): ArchiveState {
  return reducer(state, action);
}
