export enum HolidayActions {
  fetchHolidays = '[Holidays]: fetch holidays',
  fetchHolidaysSuccess = '[Holidays]: fetch holidays success',
  fetchHolidaysFailure = '[Holidays]:  fetch holidays failure',
  fetchAvailableYears = '[Holidays]: fetch available years',
  changeSelectedYear = '[Holidays]: change selected year',
  publishHoliday = '[Holidays]: publish holiday',
  publishHolidaySuccess = '[Holidays]: publish holiday success',
  publishHolidayFailure = '[Holidays]: publish holiday failure',
  deleteHoliday = '[Holidays]: delete holiday',
  deleteHolidaySuccess = '[Holidays]: delete holiday success',
  deleteHolidayFailure = '[Holidays]: delete holiday failure'
}
