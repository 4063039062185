import { BooleanTextPipe } from './booleanText.pipe';
import { DecodeUrlPipe } from './decodeUrl.pipe';
import { getFormattedDate } from './getFormattedDate.pipe';
import { GetValuePipe } from './getValue.pipe';
import { LightenDarkenColorPipe } from './lightenDarken.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { RemoveEmptyPipe } from './removeEmpty.pipe';
import { SecureResourceUrlPipe } from './secureResourceUrl.pipe';
import { SecureUrlPipe } from './secureUrl.pipe';

export const Pipes = [
  GetValuePipe,
  RemoveEmptyPipe,
  LightenDarkenColorPipe,
  getFormattedDate,
  DecodeUrlPipe,
  OrderByPipe,
  BooleanTextPipe,
  SecureUrlPipe,
  SecureResourceUrlPipe
];
