import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as HolidayActions from '~/repositories/holiday/store/holiday.actions';
import { HolidayEntity } from '~/repositories/holiday/store/holiday.models';

export interface HolidayState extends EntityState<HolidayEntity> {
  loaded?: boolean;
  error?: string | null;
  selectedYear: { year: number };
  availableYears: { year: number }[];
}

export const holidayAdapter: EntityAdapter<HolidayEntity> = createEntityAdapter<HolidayEntity>();
export const initialHolidayState: HolidayState = holidayAdapter.getInitialState({
  loaded: false,
  selectedYear: { year: new Date().getFullYear() },
  availableYears: Array.from({ length: 4 }, (_, index) => {
    return {
      year: new Date().getFullYear() + index - 1
    };
  })
});

const reducer = createReducer(
  initialHolidayState,
  on(HolidayActions.fetchHolidays, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(HolidayActions.fetchHolidaysSuccess, (state, { holidays }) =>
    holidayAdapter.setAll(holidays, {
      ...state,
      loaded: true
    })
  ),
  on(HolidayActions.fetchHolidaysFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(HolidayActions.fetchAvailableYears, (state) => ({ ...state })),
  on(HolidayActions.changeSelectedYear, (state, { year }) => ({
    ...state,
    selectedYear: year
  })),
  on(HolidayActions.publishHoliday, (state) => ({
    ...state
  })),
  on(HolidayActions.publishHolidaySuccess, (state, { holiday }) => holidayAdapter.addOne(holiday, state)),
  on(HolidayActions.publishHolidayFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(HolidayActions.deleteHoliday, (state) => ({
    ...state
  })),
  on(HolidayActions.deleteHolidaySuccess, (state, { id }) => holidayAdapter.removeOne(id, state)),
  on(HolidayActions.deleteHolidayFailure, (state, { error }) => ({
    ...state,
    error
  }))
);

export function holidayReducer(state: HolidayState, action: Action) {
  return reducer(state, action);
}
