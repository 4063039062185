import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as AnnouncementActions from './announcement.actions';
import * as AnnouncementSelector from './announcement.selectors';

import { AnnouncementEntity } from '~/repositories/announcements/store/announcement.models';

@Injectable()
export class AnnouncementFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  allAnnouncements$ = this.store.pipe(select(AnnouncementSelector.selectAllAnnouncements));
  loaded$ = this.store.pipe(select(AnnouncementSelector.selectAnnouncementsLoaded));

  initAnnouncements() {
    this.store.dispatch(AnnouncementActions.initAnnouncements());
  }
  markAnnouncementsViewed(announcementIds: string[]) {
    this.store.dispatch(AnnouncementActions.markAnnouncementsViewed({ announcementIds }));
  }
  addNewAnnouncementsToStore(newAnnouncements: AnnouncementEntity[]) {
    this.store.dispatch(AnnouncementActions.addNewAnnouncementsToStore({ newAnnouncements }));
  }
}
