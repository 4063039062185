import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as ThemeActions from './theme.actions';
import { IConfigurationItem, IFavourite, IFooter, IHelpItem, ThemeEntity } from './theme.models';

export interface ThemeState extends EntityState<ThemeEntity> {
  selectedId?: string | number; // which Theme record has been selected
  loaded: boolean; // has the Theme list been loaded
  error?: string | null; // last known error (if any)
  favourites?: IFavourite[];
  helpInformationItems?: IHelpItem[];
  footerInformation?: IFooter;
  configurationItems?: IConfigurationItem[];
}

export const themeAdapter: EntityAdapter<ThemeEntity> = createEntityAdapter<ThemeEntity>();

export const initialThemeState: ThemeState = themeAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

const reducer = createReducer(
  initialThemeState,
  on(ThemeActions.initTheme, (state) => ({
    ...state,
    selectedId: 0,
    loaded: false,
    error: null
  })),
  on(ThemeActions.loadThemeSuccess, (state, { theme }) => themeAdapter.setAll(theme, { ...state, loaded: true })),
  on(ThemeActions.loadThemeFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(ThemeActions.loadFavouritesSuccess, (state, { favourites }) => ({
    ...state,
    favourites
  })),
  on(ThemeActions.loadFavouritesFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(ThemeActions.loadHelpInformationSuccess, (state, { helpInformationItems }) => ({
    ...state,
    helpInformationItems
  })),
  on(ThemeActions.loadHelpInformationFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(ThemeActions.loadFooterInformationSuccess, (state, { footerInformation }) => ({
    ...state,
    footerInformation
  })),
  on(ThemeActions.loadFooterInformationFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(ThemeActions.loadConfigurationItemsSuccess, (state, { configurationItems }) => ({
    ...state,
    configurationItems
  })),
  on(ThemeActions.loadConfigurationItemsFailure, (state, { error }) => ({
    ...state,
    error
  }))
);

export function themeReducer(state: ThemeState | undefined, action: Action) {
  return reducer(state, action);
}
