import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { cloneDeep } from 'lodash';
import { Message } from 'primeng/api';

import { VotingResult } from '~/voting/voting.types';

@Component({
  selector: 'app-voting-result-dialog',
  templateUrl: './voting-result-dialog.component.html',
  styleUrls: ['./voting-result-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VotingResultDialogComponent implements AfterViewInit {
  @Input() visible: boolean;
  @Input() set votingResult(result: VotingResult) {
    this.result = cloneDeep(result);
    this.messages = [
      { severity: 'info', detail: `${this.translateService.instant('voting.result.title.prefix')} ${result.title}` }
    ];

    if (!result.quorum) {
      this.messages.push({
        severity: 'warn',
        detail: `${this.translateService.instant('voting.result.warning.quorum')}`
      });
    }

    if (!result.majority) {
      this.messages.push({
        severity: 'warn',
        detail: `${this.translateService.instant('voting.result.warning.majority')}`
      });
    }

    this.messages.push({
      severity: result.positiveResult ? 'success' : 'error',
      detail: result.result
    });
  }

  @Output() visibleChange = new EventEmitter<boolean>();

  public chart = signal<Chart>(null);
  public result: VotingResult;
  public messages: Message[];

  constructor(private translateService: TranslateService) {}

  ngAfterViewInit(): void {
    this.createChart();
  }

  onClose() {
    this.visibleChange.emit(false);
  }

  createChart() {
    const labels: Array<string> = [];
    const VOTING_RESULT_CHART_DATA: Array<number> = [];
    const VOTING_RESULT_CHART_DATA2: Array<number> = [];
    for (const votingResultVote of this.result.votingResultDataByType) {
      labels.push(votingResultVote.name);
      VOTING_RESULT_CHART_DATA.push(votingResultVote.count);
      if (this.result.candidateVoting && votingResultVote.count2 !== undefined) {
        VOTING_RESULT_CHART_DATA2.push(votingResultVote.count2);
      }
    }

    const dataSets = [];
    dataSets.push({
      label: this.translateService.instant('voting.result.type.for'),
      data: VOTING_RESULT_CHART_DATA,
      backgroundColor: this.result.graphBarColor
    });
    if (this.result.candidateVoting && VOTING_RESULT_CHART_DATA2.length > 0) {
      dataSets.push({
        label: this.translateService.instant('voting.result.type.against'),
        data: VOTING_RESULT_CHART_DATA2,
        backgroundColor: this.result.graphBarColor2
      });
    }

    this.chart.set(
      new Chart('chart', {
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataSets
        },
        options: {
          indexAxis: 'y',
          plugins: {
            legend: {
              display: this.result.candidateVoting && VOTING_RESULT_CHART_DATA2.length > 0,
              position: 'bottom'
            },
            tooltip: {
              displayColors: false
            }
          },
          scales: {
            x: {
              ticks: {
                precision: 0
              }
            },
            y: {
              grid: {
                display: false
              },
              ticks: {
                precision: 0
              }
            }
          }
        }
      })
    );
  }
}
