<div class="w-full flex justify-content-center">
  <p-panel class="block max-w-30rem m-3 p-panel-with-green-header">
    <ng-template pTemplate="header">
      <div class="flex align-items-center gap-2">
        <span class="font-bold">Toegang geweigerd</span>
      </div>
    </ng-template>
    <p>De toegang tot de toepassing werd geweigerd. U moet <a href="/">opnieuw aanmelden</a>.</p>
  </p-panel>
</div>
