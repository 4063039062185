import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AsyncUserTask } from '../store/userdata.models';

import { environment } from '~/core/core.module';

@Injectable()
export class UserDataService {
  constructor(private readonly http: HttpClient) {}

  public fetchAsyncUserTasks(): Observable<AsyncUserTask[]> {
    return this.http.get<AsyncUserTask[]>(`${environment.BACKEND_URL}/userdata/async-tasks`);
  }

  public deleteAsyncUserTask(id: string): Observable<string> {
    return this.http.delete<string>(`${environment.BACKEND_URL}/userdata/async-tasks/${id}`);
  }

  public fetchAllRolesActive(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.BACKEND_URL}/user/allrolesactive`);
  }
}
