import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, map, mergeMap, Observable, of, withLatestFrom } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { TabsService } from '../services/tabs.service';

import * as TabsActions from './tabs.actions';
import { TabEntity } from './tabs.models';

import { IState } from '~/repositories/repositories.store';
import { WidgetsService } from '~/repositories/widgets/services/widgets.service';

@Injectable()
export class TabsEffects {
  constructor(
    private readonly actions$: Actions,
    private store$: Store<IState>,
    private readonly tabsService: TabsService,
    private readonly widgetsService: WidgetsService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.initTabs),
      mergeMap(({ userId }) => {
        return this.tabsService.getTabsForUser(userId).pipe(
          map((tabs: TabEntity[]) => TabsActions.loadTabsSuccess({ tabs })),
          catchError((error) => of(TabsActions.loadTabsFailure({ error })))
        );
      })
    )
  );

  // navigate to error page when loading tabs fails
  initFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TabsActions.loadTabsFailure),
        tap(() => this.router.navigate(['/error']))
      ),
    { dispatch: false }
  );

  selectTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.selectTab),
      switchMap(({ tabId }) => {
        return this.tabsService.setActiveTab(tabId).pipe(
          map(() => TabsActions.selectTabSuccess({ tabId })),
          catchError((error) => of(TabsActions.selectTabFailure({ error })))
        );
      })
    )
  );

  saveNewTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.saveTab),
      mergeMap(({ tab }) =>
        this.tabsService.saveTab(tab).pipe(
          map((tab: TabEntity) => TabsActions.saveTabSuccess({ tab })),
          catchError((error) => of(TabsActions.saveTabFailure({ error })))
        )
      )
    )
  );

  updateTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.updateTabTitle),
      withLatestFrom(this.store$),
      mergeMap(([action, state]) => {
        return this.tabsService.updateTabTitle(action.title, state.repository.tabs.selectedId).pipe(
          map((tab: TabEntity) => TabsActions.updateTabTitleSuccess({ tab })),
          catchError((error) => of(TabsActions.updateTabTitleFailure({ error })))
        );
      })
    )
  );

  updateTabsOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.updateTabsOrder),
      mergeMap(({ tabsOrder }) =>
        this.tabsService.updateTabsOrder(tabsOrder).pipe(
          map((tabs: TabEntity[]) =>
            TabsActions.updateTabsOrderSuccess({
              tabs: tabs.sort((a, b) => a.sortOrder - b.sortOrder)
            })
          ),
          catchError((error) => of(TabsActions.updateTabsOrderFailure({ error })))
        )
      )
    )
  );

  updateTabSharedWith$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.updateTabSharedWith),
      mergeMap(({ groups, tabId }) =>
        this.tabsService.updateTabSharedWith(groups, tabId).pipe(
          map((tab: TabEntity) =>
            TabsActions.updateTabSharedWithSuccess({
              tab
            })
          ),
          catchError((error) => of(TabsActions.updateTabSharedWithFailure({ error })))
        )
      )
    )
  );

  addNewWidget$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TabsActions.addNewWidget),
        mergeMap((action) =>
          this.widgetsService.addWidgetConfig(action.widgetConfig).pipe(
            map((widgetConfig) => TabsActions.addNewWidgetSuccess({ widgetConfig })),
            catchError((error: any) => of(TabsActions.addNewWidgetFailure({ error })))
          )
        )
      )
  );

  moveWidget$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TabsActions.moveWidget),
        mergeMap(({ widgetId, tabId }) =>
          this.widgetsService.moveWidget(widgetId, tabId).pipe(
            map((widgetInfo) => TabsActions.moveWidgetSuccess({ widgetInfo, tabId })),
            catchError((error: any) => of(TabsActions.moveWidgetFailure({ error })))
          )
        )
      )
  );

  removeWidgetFromTab$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(TabsActions.removeWidgetFromTab),
        switchMap(({ widgetId }) => of(TabsActions.removeWidgetFromTabSuccess({ widgetId }))),
        catchError((error) => of(TabsActions.removeWidgetFromTabFailure({ error })))
      )
  );

  removeTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TabsActions.removeTab),
      mergeMap(({ tabId }) =>
        this.tabsService.removeTab(tabId).pipe(
          map(() => TabsActions.removeTabSuccess({ tabId })),
          catchError((error) => of(TabsActions.removeTabFailure({ error })))
        )
      )
    )
  );

  // collection of all failures that have to show a toast message
  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ...[
            TabsActions.saveTabFailure,
            TabsActions.updateTabTitleFailure,
            TabsActions.updateTabsOrderFailure,
            TabsActions.addNewWidgetFailure,
            TabsActions.moveWidgetFailure,
            TabsActions.removeTabFailure
          ]
        ),
        tap(({ error }) => {
          this.messageService.add({
            severity: 'error',
            detail: error.error.message,
            life: 3000
          });
        })
      ),
    { dispatch: false }
  );
}
