import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

export interface CalculationModule {
  name: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class CalculationModuleService {
  constructor(private readonly http: HttpClient) {}

  getCalculationModule(): Observable<CalculationModule> {
    return this.http.get<CalculationModule>(`${environment.BACKEND_URL}/navigation/calculationmodule`);
  }
}
