import { Location } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import { AppModule } from '~/app.module';
import { installDragAndDropPatch } from '~/utils/drag-and-drop/drag-and-drop-patch';

// next function added to load suite urls ending with a slash (/) correctly in de suite-view iframe
// remove this when suite-view iframe is removed
Location.stripTrailingSlash = (url: string) => {
  return url;
};

if (environment.PRODUCTION) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

installDragAndDropPatch();
