import { createFeatureSelector, createSelector } from '@ngrx/store';

import { tabsAdapter, TabsState } from './tabs.reducer';

import { IRepositoryState } from '~/repositories/repositories.store';

// Lookup the 'Tabs' feature state managed by NgRx
/* export const selectTabsState =
  createFeatureSelector<TabsState>(TABS_FEATURE_KEY); */

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');

export const selectTabsState = createSelector(REPOSITORY_SELECTOR, (state: IRepositoryState) => state.tabs);

const { selectAll, selectEntities } = tabsAdapter.getSelectors();

export const selectTabsLoaded = createSelector(selectTabsState, (state: TabsState) => state.loaded);

export const selectTabsError = createSelector(selectTabsState, (state: TabsState) => state.error);

export const selectAllTabs = createSelector(selectTabsState, (state: TabsState) => selectAll(state));

export const selectEmptyTabs = createSelector(selectTabsState, (state: TabsState) =>
  selectAll(state).filter((tab) => !tab.kcc && tab.widgets.length === 0)
);

export const selectTabsEntities = createSelector(selectTabsState, (state: TabsState) => selectEntities(state));

export const selectSelectedId = createSelector(selectTabsState, (state: TabsState) => state.selectedId);

export const selectEntity = createSelector(selectTabsEntities, selectSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : entities[0]
);

export const selectAddTabLoading = createSelector(selectTabsState, (state: TabsState) => state.addTabLoading);

export const selectUpdateTabsOrderLoading = createSelector(
  selectTabsState,
  (state: TabsState) => state.updateOrderLoading
);

export const selectUpdateTabSharedWithLoading = createSelector(
  selectTabsState,
  (state: TabsState) => state.updateSharedWithLoading
);

export const selectEditModeEnabled = createSelector(selectTabsState, (state: TabsState) => state.editModeEnabled);

export const selectTabTitleUpdateLoading = createSelector(
  selectTabsState,
  (state: TabsState) => state.updateTabTitleLoading
);

export const selectRemoveTabLoading = createSelector(selectTabsState, (state: TabsState) => state.removeTabLoading);

export const selectMoveWidgetLoading = createSelector(selectTabsState, (state: TabsState) => state.moveWidgetLoading);
