import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { AnnouncementEntity } from '~/repositories/announcements/store/announcement.models';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(private readonly http: HttpClient) {}

  getAnnouncements(): Observable<AnnouncementEntity[]> {
    return this.http.get<AnnouncementEntity[]>(`${environment.BACKEND_URL}/information/announcements/current`);
  }

  getNewAnnouncements(): Observable<AnnouncementEntity[]> {
    return this.http.get<AnnouncementEntity[]>(`${environment.BACKEND_URL}/information/announcements/new`);
  }

  markAnnouncementsViewed(announcementIds: string[]): Observable<void> {
    return this.http.post<void>(`${environment.BACKEND_URL}/information/mark-viewed`, { ids: announcementIds });
  }
}
