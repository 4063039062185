import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AnnouncementEntity } from '~/repositories/announcements/store/announcement.models';

@Component({
  selector: 'app-announcements-dialog',
  templateUrl: './announcements-dialog.component.html',
  styleUrls: ['./announcements-dialog.component.scss']
})
export class AnnouncementsDialogComponent {
  @Input() visible: boolean;
  @Input() announcements: AnnouncementEntity[] = [];
  @Output() closeAnnouncementDialog = new EventEmitter<string[]>();

  onClose() {
    this.closeAnnouncementDialog.emit(this.announcements.map((announcement) => announcement.id));
  }
}
