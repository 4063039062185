import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import {
  ArchiveDataFilter,
  ArchiveDataResult,
  ArchiveFormData,
  ArchiveTabDataEntity
} from '~/repositories/archive/store/archive.models';

@Injectable()
export class ArchiveService {
  constructor(private readonly http: HttpClient) {}

  public fetchArchiveTabs(): Observable<ArchiveTabDataEntity[]> {
    return this.http.get<ArchiveTabDataEntity[]>(`${environment.BACKEND_URL}/archive/tabs`);
  }

  public fetchArchiveData(module: string, filter: ArchiveDataFilter): Observable<ArchiveDataResult> {
    return this.http.post<ArchiveDataResult>(`${environment.BACKEND_URL}/archive/tabs/` + module, {
      ...filter,
      dateFrom: new DatePipe('nl').transform(filter.dateFrom, 'dd/MM/yyyy') || null,
      dateTo: new DatePipe('nl').transform(filter.dateTo, 'dd/MM/yyyy') || null
    });
  }

  public sendToArchive(data: ArchiveFormData): Observable<number> {
    return this.http.post<number>(`${environment.BACKEND_URL}/archive`, data);
  }
}
