import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '~/core/core.module';

/** Add Login As bearer token header to fake backend user */
@Injectable()
export class LoginAsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (environment.USERNAME_AS != null && environment.USERNAME_AS !== '') {
      const modifiedReq = req.clone({
        headers: req.headers.delete('Authorization')
      });
      const modifiedReqBearer = modifiedReq.clone({
        headers: modifiedReq.headers.set('Authorization', `Bearer ${environment.USERNAME_AS_BEARER_TOKEN}`)
      });
      //console.log('LoginAsInterceptor: LOGIN AS : ' + environment.USERNAME_AS);
      return next.handle(modifiedReqBearer);
    } else {
      const modifiedReq = req.clone({
        // headers: req.headers.delete('LOGIN-AS')
      });
      //console.log('LoginAsInterceptor: REMOVE LOGIN AS!');
      //console.log('LoginAsInterceptor: REMOVE LOGIN AS, prior login: ' + environment.USERNAME);
      return next.handle(modifiedReq);
    }
  }
}
