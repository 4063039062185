import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WidgetConfigEntity } from './widgets.models';
import { widgetsAdapter, WidgetsState } from './widgets.reducer';

import { IRepositoryState } from '~/repositories/repositories.store';

export const REPOSITORY_SELECTOR = createFeatureSelector<IRepositoryState>('repository');

export const selectWidgetsState = createSelector(REPOSITORY_SELECTOR, (state: IRepositoryState) => state?.widgets);

const { selectAll, selectEntities } = widgetsAdapter.getSelectors();

export const selectWidgetsLoaded = createSelector(selectWidgetsState, (state: WidgetsState) => state.loaded);

export const selectWidgetsError = createSelector(selectWidgetsState, (state: WidgetsState) => state.error);

export const selectAllWidgets = createSelector(selectWidgetsState, (state: WidgetsState) => selectAll(state));

export const selectWidgetsEntities = createSelector(selectWidgetsState, (state: WidgetsState) => selectEntities(state));

export const selectSelectedId = createSelector(selectWidgetsState, (state: WidgetsState) => state.selectedId);

export const selectEntity = createSelector(selectWidgetsEntities, selectSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const selectWidgetById = createSelector(
  selectWidgetsEntities,
  (entities: { [key: string]: WidgetConfigEntity }, { id }: { id: string }) => {
    if (!entities[id]) {
      return null;
    }

    return entities[id];
  }
);

export const selectWidgetTypes = createSelector(selectWidgetsState, (state: WidgetsState) => state.widgetTypes);

export const selectWidgetTypeById = createSelector(selectWidgetsState, (state: WidgetsState, id: string) =>
  state.widgetTypes.find((type) => type.id === id)
);

export const selectWidgetsById = createSelector(selectWidgetsState, (state: WidgetsState, ids: string[]) =>
  Object.values(selectEntities(state))
    .filter((item) => ids.includes(item.id))
    .sort((a, b) => a.sortOrder - b.sortOrder)
);

export const selectUpdateWidgetsOrderLoading = createSelector(
  selectWidgetsState,
  (state: WidgetsState) => state.updateOrderLoading
);

export const selectRemoveWidgetLoading = createSelector(
  selectWidgetsState,
  (state: WidgetsState) => state.removeWidgetLoading
);

export const selectActionEmitLoading = createSelector(selectWidgetsState, (state: WidgetsState) => {
  return state.actionEmitLoading;
});

export const selectActionAssigneesLoading = createSelector(
  selectWidgetsState,
  (state: WidgetsState) => state.actionAssigneesLoading
);

export const selectActionAssignees = createSelector(selectWidgetsState, (state: WidgetsState) => state.actionAssignees);

export const selectActionAssignedTasks = createSelector(
  selectWidgetsState,
  (state: WidgetsState) => state.actionAssignedTasks
);
