import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  deleteAsyncUserTask,
  fetchAllRolesActive,
  fetchAllRolesActiveFailure,
  fetchAllRolesActiveSuccess,
  fetchAsyncUserTasks,
  fetchAsyncUserTasksFailure,
  fetchAsyncUserTasksSuccess,
  updateAsyncUserTask
} from './userdata.actions';
import { AsyncUserTask } from './userdata.models';

export interface UserDataState extends EntityState<AsyncUserTask> {
  loading: boolean;
  allRolesActive: boolean;
  allRolesActiveLoading: boolean;
}

export const userDataAdapter: EntityAdapter<AsyncUserTask> = createEntityAdapter<AsyncUserTask>({
  selectId: (result: AsyncUserTask) => result.id
});

export const initialState: UserDataState = userDataAdapter.getInitialState({
  // set initial required properties
  loading: false,
  allRolesActive: false,
  allRolesActiveLoading: false
});

const reducer = createReducer(
  initialState,
  on(fetchAsyncUserTasks, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fetchAsyncUserTasksSuccess, (state, { data }) =>
    userDataAdapter.setAll(data, {
      ...state,
      loading: false
    })
  ),
  on(fetchAsyncUserTasksFailure, (state, { error }) => ({ ...state, error })),

  on(updateAsyncUserTask, (state, { task }) => userDataAdapter.upsertOne(task, state)),

  on(deleteAsyncUserTask, (state, { id }) => userDataAdapter.removeOne(id, state)),
  on(fetchAsyncUserTasksFailure, (state, { error }) => ({ ...state, error })),

  on(fetchAllRolesActive, (state) => ({
    ...state,
    allRolesActiveLoading: true,
    error: null
  })),
  on(fetchAllRolesActiveSuccess, (state, { allRolesActive }) => ({
    ...state,
    allRolesActive,
    allRolesActiveLoading: false,
    error: null
  })),
  on(fetchAllRolesActiveFailure, (state, { error }) => ({ ...state, allRolesActiveLoading: false, error }))
);

export function userDataReducer(state: UserDataState | undefined, action: Action) {
  return reducer(state, action);
}
