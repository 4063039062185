import { environment } from '../../../environments/environment';

import { rxStompConfig } from '~/core/websockets/rx-stomp.config';
import { RxStompService } from '~/core/websockets/rx-stomp.service';

export function rxStompServiceFactory() {
  const rxStomp = new RxStompService();

  // try again when we have a BEAERER_TOKEN to identify the user
  if (environment.BEARER_TOKEN == null) return rxStomp;

  rxStompConfig.connectHeaders['login'] = environment.USERNAME;
  rxStompConfig.connectHeaders['passcode'] = environment.BEARER_TOKEN;

  rxStomp.configure(rxStompConfig);
  rxStomp.activate();

  return rxStomp;
}
