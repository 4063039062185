<p-dialog
  [(visible)]="visible"
  header="{{ 'current.meetings' | translate }}"
  [style]="{ 'min-width': '40vw', 'max-width': '80vw' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  contentStyleClass="p-0"
  (onHide)="onClose()">
  <div *ngFor="let meetingPointer of meetingPointers" class="mb-2">
    <div
      class="font-semibold text-base bg-gray-200 cursor-pointer hover:bg-gray-300 transition-duration-200 px-4 py-3"
      (click)="navigateToSuiteView('agenda/view?id=' + meetingPointer.meetingId + '#current-item')">
      {{ meetingPointer.meetingStatusLabel }}
    </div>
    <p-button
      *ngIf="meetingPointer.activeAgendaPageId"
      (click)="navigateToSuiteView('meetingitem/view?id=' + meetingPointer.activeAgendaPageId)"
      class="p-button-link"
      styleClass="p-button-link no-underline px-4 pt-3 pb-2">
      {{ meetingPointer.title }}
    </p-button>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-start">
      <button type="button" pButton label="{{ 'common.button.close' | translate }}" (click)="onClose()"></button>
    </div>
  </ng-template>

  <ng-template pTemplate="closeicon">
    <div
      class="p-dialog-header-icon"
      [title]="
        'dialog.button.close'
          | translate
            : {
                '0': 'current.meetings' | translate
              }
      ">
      <i class="text-lg fa-regular fa-xmark text-gray-800"></i>
    </div>
  </ng-template>
</p-dialog>
