import { isEqual } from 'lodash';
import { TreeNode } from 'primeng/api';

import {
  FILTERITEMTYPE,
  SEARCHTYPE,
  filterBehaviourOptions,
  inLastOptions,
  sinceBeginningOfOptions
} from './search.const';
import { IFilterItem } from './search.types';

import {
  SavedSearchQuery,
  SearchQueryFilter,
  SearchQueryFilterGroup,
  SearchResultEntity
} from '~/repositories/search/store/search.models';

export const getNewFilterItem = (): IFilterItem => ({
  id: `${Date.now()}`,
  type: FILTERITEMTYPE.FILTER
});

export const mapAvailableFilters = (results: SearchResultEntity[], filters: TreeNode[]) => {
  const globalFilter = filters.find((filter) => filter.key === 'global');
  return [
    globalFilter,
    ...results
      .map(({ availableFilters, module }) => {
        if (!availableFilters?.length) {
          return null;
        }

        const filtersForModule = filters.find((filter) => filter.key === module);

        const children = filtersForModule?.children
          .map((filter) => {
            const matchingAvailableFilter = availableFilters.find((item) => item.filterCode === filter.key);

            if (!matchingAvailableFilter) {
              return null;
            }

            if (filter.data.options) {
              const filterItems = (items: any[]): any[] =>
                items.flatMap((item) => {
                  if (item.children) {
                    const children = filterItems(item.children);

                    // If there are filtered children, include the parent too
                    if (children.length > 0) {
                      return [{ ...item, children }];
                    }
                  }

                  // If no children, check for filterValues
                  if (Object.keys(matchingAvailableFilter.filterValues).includes(item.key)) {
                    return item;
                  }

                  return []; // Flatmap ignores empty arrays
                });

              const options = !matchingAvailableFilter.filterValues
                ? filter.data.options
                : filterItems(filter.data.options);

              return {
                ...filter,
                data: {
                  ...filter.data,
                  options: options
                    .map((option: TreeNode) => addFacetCountToFilterItem(option, matchingAvailableFilter.filterValues))
                    .sort((a: { count?: number }, b: { count?: number }) =>
                      b.count === 0 ? 1 : a.count === 0 ? -1 : b.count - a.count
                    )
                }
              };
            }

            return filter;
          })
          .filter((i) => !!i);

        return {
          ...filtersForModule,
          children: children || []
        };
      })
      .filter((i) => !!i)
  ];
};

const addFacetCountToFilterItem = (filterItem: any, filterValues: { [p: string]: number }): any => {
  return {
    ...filterItem,
    children: filterItem.children?.map((i: any) => addFacetCountToFilterItem(i, filterValues)),
    count: filterValues && filterValues[filterItem.key]
  };
};

export const mapSelectedFilters = (
  searchType: string,
  filterData: IFilterItem[],
  searchValue?: string,
  parentOperator?: string
) => {
  if (searchType === SEARCHTYPE.SIMPLE) {
    const filters: any = [];

    if (searchValue) {
      filters.push({
        code: 'global',
        values: [searchValue]
      });
    }

    if (filterData.length) {
      filters.push(
        ...filterData.map((filter) => ({
          code: filter?.selectedFilter?.key,
          operator: filter?.selectedFilterOperator?.key,
          values: mapFilterValues(filter)
        }))
      );
    }
    return filters;
  }

  const filters = filterData
    .filter((itemToFilter) => itemToFilter.type === FILTERITEMTYPE.FILTER)
    .map((itemToMap) => ({
      code: itemToMap?.selectedFilter?.key,
      operator: itemToMap?.selectedFilterOperator?.key,
      values: mapFilterValues(itemToMap)
    }));

  const groups: any = filterData
    .filter((itemToFilter) => itemToFilter.type === FILTERITEMTYPE.GROUP)
    .map((itemToMap) => {
      const oppositeParentOperator = isEqual(parentOperator, filterBehaviourOptions[0].value)
        ? filterBehaviourOptions[1].value
        : filterBehaviourOptions[0].value;
      return {
        operator: oppositeParentOperator,
        ...mapSelectedFilters(SEARCHTYPE.EXTENDED, itemToMap.children, searchValue, oppositeParentOperator)
      };
    });

  return {
    filters: filters.length ? filters : undefined,
    childFilterGroups: groups.length ? groups : undefined
  };
};

export const mapSimpleSavedQueryToFilters = (query: SavedSearchQuery, availableFilters: TreeNode[]): IFilterItem[] => {
  return query.filters.map((filter, index) => {
    const selectedFilter = getSelectedFilterByKey(availableFilters, filter.code);

    const selectedFilterValue = getSelectedFilterValue(selectedFilter, filter.values);

    const selectedFilterOperator = selectedFilter?.data.operators[0];

    return {
      id: index.toString(),
      type: FILTERITEMTYPE.FILTER,
      selectedFilter,
      selectedFilterValue:
        selectedFilterOperator?.key === 'SINCE_BEGINNING_OF' || selectedFilterOperator?.key === 'UNTIL_END_OF'
          ? sinceBeginningOfOptions.find((item) => item.key === filter.values[0])
          : selectedFilterValue || null,
      selectedFilterOperator,
      selectedDateSpan: selectedFilterOperator?.key === 'IS_BETWEEN' && {
        from: filter.values[0],
        till: filter.values[1]
      },
      selectedDateFilterValue:
        (selectedFilterOperator?.key === 'IN_LAST' || selectedFilterOperator?.key === 'IN_COMING') &&
        (filter.values[0] as unknown as number),
      selectedDateOption:
        (selectedFilterOperator?.key === 'IN_LAST' || selectedFilterOperator?.key === 'IN_COMING') &&
        inLastOptions.find((item) => item.key === filter.values[1])
    };
  });
};

export const mapExtendedSavedQueryToFilters = (
  query: SavedSearchQuery,
  availableFilters: TreeNode[]
): IFilterItem[] => {
  const rootFilterGroup = query.filterGroup;
  return [
    ...getExtendedFiltersFromGroup(rootFilterGroup.childFilterGroups, availableFilters, rootFilterGroup.operator),
    ...getExtendedFilters(rootFilterGroup.filters, availableFilters, rootFilterGroup.operator)
  ];
};

const getSelectedFilterByKey = (availableFilters: TreeNode[], key: string): TreeNode => {
  let selectedFilter = null;

  availableFilters.forEach((filter) => {
    if (filter.key === key) {
      selectedFilter = filter;
      return;
    }
    if (filter.children) {
      const foundFilter = getSelectedFilterByKey(filter.children, key);
      if (foundFilter) {
        selectedFilter = foundFilter;
        return;
      }
      return;
    }
    return;
  });

  return selectedFilter;
};

const getFilterValueFromChildren = (options: TreeNode[], value: string): TreeNode[] => {
  return options.reduce((acc: TreeNode[], curr: TreeNode): TreeNode[] => {
    if (curr.key === value) {
      acc.push(curr);
    } else if (curr.children) {
      acc.push(...getFilterValueFromChildren(curr.children, value));
    }
    return acc;
  }, []);
};

const getSelectedFilterValue = (selectedFilter: TreeNode, values: string[]): any => {
  let selectedFilterValue = null;

  if (selectedFilter?.data.options && values.length === 1) {
    selectedFilterValue = getFilterValueFromChildren(selectedFilter.data.options, values[0]);
  } else if (values.length === 2) {
    selectedFilterValue = null;
  } else {
    selectedFilterValue = values;
  }

  return selectedFilterValue ? selectedFilterValue[0] || selectedFilterValue : null;
};

const getSelectedFilterBehaviour = (filter: TreeNode, operator: string) => {
  return filter.data.operators.find((option: { key: string }) => option.key === operator);
};

const getExtendedFiltersFromGroup = (
  groups: SearchQueryFilterGroup[],
  availableFilters: TreeNode[],
  operator: string
): any => {
  return groups.map((group, index) => {
    const selectedFilterOperator = filterBehaviourOptions.find((option) => option.value === operator);

    const oppositeFlterOperator = filterBehaviourOptions.find((option) => option.value !== operator);

    return {
      id: `${FILTERITEMTYPE.GROUP}-${index}`,
      type: FILTERITEMTYPE.GROUP,
      selectedFilterBehaviour: selectedFilterOperator,
      selectedDateSpan: { from: null, till: null },
      children: [
        ...getExtendedFilters(group.filters, availableFilters, oppositeFlterOperator.value),
        ...getExtendedFiltersFromGroup(group.childFilterGroups, availableFilters, selectedFilterOperator.value)
      ]
    };
  });
};

const getExtendedFilters = (filters: SearchQueryFilter[], availableFilters: TreeNode[], groupOperator: string): any => {
  return filters.map((filter, index) => {
    const selectedFilter = getSelectedFilterByKey(availableFilters, filter.code);
    const selectedFilterValue = getSelectedFilterValue(selectedFilter, filter.values);

    const selectedFilterBehaviour = filterBehaviourOptions.find((option) => option.value === groupOperator);

    const selectedFilterOperator = getSelectedFilterBehaviour(selectedFilter, filter.operator);

    return {
      id: `${FILTERITEMTYPE.FILTER}-${index}`,
      type: FILTERITEMTYPE.FILTER,
      selectedFilter,
      selectedFilterValue:
        selectedFilterOperator?.key === 'SINCE_BEGINNING_OF' || selectedFilterOperator?.key === 'UNTIL_END_OF'
          ? sinceBeginningOfOptions.find((item) => item.key === filter.values[0])
          : selectedFilterValue || null,
      selectedFilterOperator,
      selectedFilterBehaviour,
      selectedDateSpan: selectedFilterOperator?.key === 'IS_BETWEEN' && {
        from: filter.values[0],
        till: filter.values[1]
      },
      selectedDateFilterValue:
        (selectedFilterOperator?.key === 'IN_LAST' || selectedFilterOperator?.key === 'IN_COMING') &&
        (filter.values[0] as unknown as number),
      selectedDateOption:
        (selectedFilterOperator?.key === 'IN_LAST' || selectedFilterOperator?.key === 'IN_COMING') &&
        inLastOptions.find((item) => item.key === filter.values[1])
    };
  });
};

export const getAvailableFilters = (filters: TreeNode[], activeFilterTab: string) => {
  const availableFilters = filters.length === 2 ? [filters[0], ...filters[1].children] || [] : filters;

  if (activeFilterTab === SEARCHTYPE.SIMPLE) {
    return availableFilters.filter((filter) => {
      return filter?.key !== 'global';
    });
  }

  return availableFilters.map((filter: TreeNode) => {
    if (filter?.key === 'global') {
      delete filter?.children;
      return filter;
    }
    return filter;
  });
};

export const mapFilterValues = (filter: IFilterItem): unknown => {
  if (filter?.selectedFilter?.type === 'DATE' && filter?.selectedFilterOperator?.key === 'IS_BETWEEN') {
    return [filter?.selectedDateSpan?.from, filter?.selectedDateSpan?.till];
  } else if (
    filter?.selectedFilter?.type === 'DATE' &&
    (filter?.selectedFilterOperator.key === 'IN_LAST' || filter?.selectedFilterOperator?.key === 'IN_COMING')
  ) {
    return [filter?.selectedDateFilterValue, filter?.selectedDateOption?.key];
  } else if (
    filter?.selectedFilterOperator?.key === 'IS_EMPTY' ||
    filter?.selectedFilterOperator?.key === 'IS_NOT_EMPTY'
  ) {
    return null;
  } else {
    return [
      typeof filter?.selectedFilterValue === 'object' ? filter?.selectedFilterValue?.key : filter?.selectedFilterValue
    ];
  }
};

export const visibleIfAmountOfRootOptions = (options: TreeNode[], amount: number): boolean => {
  if (!options) {
    return false;
  }

  if (options?.length > amount) {
    return true;
  } else {
    const itemsWithChildren = options.reduce((acc, curr) => {
      if (curr.children?.length) {
        acc.push(curr);
      }
      return acc;
    }, []);

    return itemsWithChildren.length > 0;
  }
};

export const hasFiltersWithErrors = (filters: IFilterItem[]): boolean => {
  const itemWithError = filters.find((item) => {
    return item.children
      ? hasFiltersWithErrors(item.children)
      : !item.selectedFilter ||
          !item.selectedFilterOperator ||
          (item.selectedFilterOperator.key === 'IN_LAST' || item.selectedFilterOperator.key === 'IN_COMING'
            ? !item.selectedDateOption || !item.selectedDateFilterValue
            : item.selectedFilterOperator.key === 'IS_EMPTY' || item.selectedFilterOperator.key === 'IS_NOT_EMPTY'
            ? false
            : !(item.selectedFilterValue || (item.selectedDateSpan.from && item.selectedDateSpan.till)));
  });

  return !!itemWithError;
};
